.qna_write {
  display: flex;
  flex: 1;
  background-color: white;
}
.write_box {
  padding-top: 20px;
}
.label_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 20px;
  display: inline-block;
}
.init_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #999;
}
.contents_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  line-height: 24px;
}
.contents_text_input {
  min-height: 200px;
  width: 100%;
  resize: none;
  background-color: transparent;
}
.wrap {
  padding: 32px responsiveWidth(4.1);
}
.top_wrap {
  padding-top: 20px;
}
.bar {
  background-color: #f5f5f5;
  height: 6px;
  margin: 32px 0;
}
.qna_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.qna_wrap {
  background-color: #fafafa;
  border-radius: 6px;
  padding: 20px;
  margin: 0 16px;
}
.sub_text {
  color: #999;
}
.info_text {
  margin-top: 6px;
  font-size: 12px;
  color: #999;
  letter-spacing: -0.48px;
  font-weight: normal;
  padding: 0 20px;
  display: inline-block;
}
.close_btn {
  position: absolute;
  top: 6px;
  right: 6px;
}
.sheet {
  padding: 16px 0px;
}
.title_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
  margin-bottom: 16px;
}
.type_list {
  padding: 0px 20px;
  overflow-y: auto;
  height: 280px;
}
.type_item {
  padding: 11px 0px;
  display: flex;
  align-items: center;
  & > button {
    width: 100%;
  }
}
.type_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.type_active_text {
  color: #be69c3;
}

.footer_wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  max-width: 450px;
  height: 56px;
}

.footer {
  display: flex;
  flex-direction: row;
}
.footer_btn {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.footer_active_btn {
  background-color: #222;
}
.footer_in_active_btn {
  background-color: #c4c4c4;
  border-color: #d9d9d9;
}
.footer_btn_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qna_guide {
  margin-top: 6px;
  padding: 0 16px;
}
.qna_guide_txt {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
  display: inline-block;
}

.empty_file_wrap {
  display: flex;
  justify-content: space-between;
}

.spacer {
  height: 56px;
}
