.mall_category_list {
  display: flex;
  column-gap: 16px;
}

.bx_scroll_div {
  padding: 20px 20px 28px;
}

.group_item {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.btn_category {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;
}

.txt_category_name {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  color: #222;

  @media screen and (max-width: 375px) {
    font-size: 10px;
  }
}