#badge_list {
  .my_active_badge {
    background-color: #f7f4fa;

    .wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;

      .my_icon_wrap {
        display: flex;
      }

      .my_icon {
        width: 130px;
        height: 130px;
        object-fit: cover;
      }

      .my_level_text1 {
        margin-top: 12px;
        display: flex;
        align-items: baseline;
        column-gap: 6px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.04em;
        color: #222;

        .my_level_text2 {
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -0.04em;
          color: #be69c3;
        }
      }

      .my_status {
        background-color: #fff;
        margin-top: 4px;
        padding: 8px 14px;
        border-radius: 32px;

        .my_status_text1 {
          display: flex;
          align-items: baseline;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.04em;
          color: #222;
        }

        .my_status_text2 {
          color: #be69c3;
          font-weight: bold;
        }
      }
    }
  }

  .list {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 28px;

    @media screen and (max-width: 375px) {
      padding: 40px 20px;
    }

    .item {
      width: calc(100% / 3 - 56px / 3);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .icon_wrap {
        display: flex;
      }

      .icon {
        width: 100%;
      }

      .label {
        margin-top: 4px;

        &_on_text {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.04em;
          text-align: center;
          color: #222;
        }

        &_text {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.04em;
          text-align: center;
          color: #D9D9D9;
        }
      }
    }
  }
}