.tabsContainerStyle {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;

  .tabWrapperStyle {
    padding: 10px 0px;
    .tabTextContainerActiveStyle {
      .tabTextActiveStyle {
        margin: 0px;
        font-size: 20px;
        color: #222;
        letter-spacing: -0.8px;
        font-weight: bold;
        opacity: 1;
        line-height: 20px;
        padding: 12px 5px 0px 0px;
      }
    }
    .tabTextContainerStyle {
      background-color: transparent;
      .tabTextStyle {
        margin: 0px;
        font-size: 20px;
        color: #222;
        letter-spacing: -0.8px;
        font-weight: bold;
        opacity: 0.4;
        line-height: 20px;
        padding: 12px 16px 0px;
      }
    }
  }
}
.carousel_idx {
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  margin-right: 10px;
  margin-top: 16px;
  background-color: rgba(34, 34, 34, 0.4);
  width: 29px;
  height: 19px;
  z-index: 10;
  top: 6px;
  right: 16px;
}
