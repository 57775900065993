#SearchScreen {
  flex: 1;
  background-color: #fff;
  width: 100%;
  min-height: 100dvh;

  #TodayProduct {
    .bx_title {
      padding: 0 20px;
      .txt_search_title {
        font-weight: bold;
      }
    }
  }

  .search_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 20px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
  }
  .search_back {
    display: flex;
  }
  .search_home {
    display: flex;
    margin-left: 10px;
  }
  .input_wrap {
    position: relative;
    width: calc(100% - 32px);
  }
  .nick_name {
    height: 44px;
    padding-left: 20px;
    padding-right: 40px;
    border-radius: 6px;
    background-color: #fafafa;
    font-size: 14px;
    letter-spacing: -0.56px;
    width: 100%;
  }
  .nick_name_wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    border-radius: 6px;
    padding-right: 12px;
  }
  .search_icon {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      display: flex;
    }
  }
  .close_purple {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    & > div {
      display: flex;
    }
  }
  .auto_complete_list {
    padding-top: 8px;
    // marginBottom Platform.OS === "ios" ? responsiveHeight(40)  0
  }
  .auto_complete_item {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
    align-items: center;
  }
  .auto_complete_text {
    margin-left: 6px;
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: { ...font_weight_500 },
    //   android: { ...font_weight_400 },
    // }),
  }
  .auto_complete_match_text {
    color: #a760b1;
  }
}
