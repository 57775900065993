.action_wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  box-shadow: 0px -4px 30px 0px #0000000f;
  height: 64px;
  padding: 0 20px;
  background-color: #fff;
  button {
    font-family: inherit;
  }
}

.action_wrap_padding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.action_left {
  display: flex;
  column-gap: 10px;
}
.action_text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #222;
  display: flex;
  align-items: center;
}

.number_text {
  font-size: 14px;
  margin-left: 2px;
}

.action_right {
  display: flex;
  column-gap: 10px;
  button {
    display: flex;
  }
}
