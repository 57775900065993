.contents-item {
  margin-top: 24px;

  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #222;
    margin: 0 0 6px;
  }

  .html-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.04em;
  }
}