.close_btn {
  position: absolute;
  right: 16px;
  top: 6px;
  z-index: 1;
  display: flex;
  & > button {
    display: flex;
    cursor: pointer;
  }
}

.image_list {
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 40px;
}

.image_column {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.sample_image_wrap {
  position: relative;
}

.checked_image_position {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked_image_background {
  width: 62px;
  height: 62px;
  border-radius: 90px;
  opacity: 0.8;
  background-color: #222;
}

.selected_image_wrap {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.selected_image {
  height: 160px;
  width: 160px;
  border-radius: 50%;
}

.sample_image {
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.camera_btn {
  background-color: #eee;
}

.save_btn {
  cursor: pointer;
  background-color: #222;
  height: 56px;
  width: 100%;

  &_text {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
  }
}
