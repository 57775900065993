#HomeScreen {
  .main {
    flex: 1;
    background-color: #fff;
  }

  .body {
    flex: 1;
  }

  .bg_popular_post {
    background-color: #f5f5f5;
    padding: 14px 16px 30px 16px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      height: 16px;
      box-shadow: 0px -6px 8px 0px #00000012;
    }
  }

  .img_popular_post {
    display: flex;
  }
}
