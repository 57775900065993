.gradient {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.06) 100%
  );
}
.ab_all_view {
  // padding-top: 234px;
  .sticky_container {
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 5;
    width: 100%;
    max-width: 450px;
  }
}
.ab_detail {
  .ab_detail_wrap {
    padding: 20px 20px 0;
    .ab_detail_header_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ab_detail_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user_img {
          width: 48px;
          height: 48px;
          border-radius: 100px;
        }
        .info_wrap {
          margin: 0 10px;

          .info_text1 {
            text-align: left;

            font-size: 16px;
            letter-spacing: -0.64px;
            color: #222;
            font-weight: bold;
            margin-bottom: 2px;
            margin-top: 0;
          }

          .info_inner_wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            .info_text2 {
              font-size: 12px;
              letter-spacing: -0.48px;
              color: #666666;
              margin: 0;
            }
            .text_bar {
              height: 12px;
              width: 1px;
              background-color: #eeeeee;
              margin: 0 5px;
            }
          }
        }
      }
    }
    .ab_detail_body {
      margin-top: 24px;
      .title_text {
        color: #222;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.8px;
        margin: 0;
      }
      .small_bar {
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background-color: #eee;
      }
      .contents_wrap {
        .contents_text {
          margin: 0;
          color: #222;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: -0.64px;
        }
      }
      .tag_list {
        margin-top: 12px;
        .tag_item {
          background-color: #f7f4fa;
          padding: 8px 12px;
          border-radius: 50px;
          margin-right: 7px;
          margin-bottom: 7px;

          .tag_text {
            margin: 0;
            color: #be69c3;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.36px;
          }
        }
      }
      .select_wrap {
        margin-top: 24px;
        display: flex;
        align-items: center;
        .select_btn {
          width: 100%;
          aspect-ratio: 1;
          &.result_opened {
            &.has_thumbnail {
              &.selected {
                &:before {
                  background-color: #be69c3;
                  opacity: 0.75;
                }
              }
            }
          }
          &:first-child {
            margin-right: 1px;
            &::before {
              border-bottom-left-radius: 6px;
              border-top-left-radius: 6px;
            }
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
          }
          &:last-child {
            margin-left: 1px;
            &::before {
              border-bottom-right-radius: 6px;
              border-top-right-radius: 6px;
            }
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
          }
          position: relative;
          .select_image {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(50, 50);
            width: 100%;
            height: 100%;
            justify-content: center;
            object-fit: cover;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 1;
          }
          .ab_select_text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .ab_select_text1 {
              margin: 0;
              color: #fff;
              font-size: 32px;
              font-weight: 700;
              letter-spacing: -1.28px;
            }
            .ab_select_text2 {
              margin-top: 8px;
              color: #fff;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.56px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
              padding: 0 12px;
            }
          }
        }
        .check_icon {
          width: 40px;
          height: 40px;
        }
        .select_text_btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          aspect-ratio: 1;
          .check_icon {
            margin-top: 60px;
          }
          .not_selected_wrap {
            margin-top: 60px;
            background-color: #c4c4c4;
            padding: 2px 9px 0px;
            height: 40px;
            width: 40px;
            border-radius: 6px;
            .select_text {
              margin: 0;
              color: #fff;
              font-size: 32px;
              line-height: 32px;
              font-weight: 700;
              letter-spacing: -1.28px;
            }
          }
          .select_text_wrap {
            .select_b_text {
              margin-top: 10px;
              color: #999;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.56px;
            }
          }
          .selected_text {
            color: #fff;
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.56px;
          }
          .ab_wrap_container {
            margin-top: 65px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            .ab_wrap {
              width: 40px;
              height: 40px;
              border-radius: 6px;
              padding: 4px;
              p {
                color: #fff;
                font-size: 30px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: -1.2px;
                margin: 0;
              }
            }
            .select_text_wrap {
              margin-top: 10px;
              color: #be69c2;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.56px;
              p {
                margin: 0;
              }
            }
          }
          &:first-child {
            margin-right: 1px;
            background-color: #fcfafc;
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
          }
          &:last-child {
            margin-left: 1px;
            background-color: #fafbfc;
            border-bottom-right-radius: 6px;
            border-top-right-radius: 6px;
          }
        }
      }
      .result_wrap {
        .result_count {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .result_count_left,
          .result_count_right {
            display: flex;
            align-items: center;
            .result_count_win_text {
              color: #be69c3;
              margin: 0 2px;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: -0.48px;
            }
            .result_count_lose_text {
              color: #666;
              margin: 0 2px;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: -0.48px;
            }
          }
        }
      }
      .re_vote_btn_wrap {
        margin-top: 30px;
        .re_vote_btn {
          padding: 13px;
          border: 1px solid #eee;
          border-radius: 6px;
          text-align: center;
          .re_vote_btn_text {
            margin: 0;
            color: #222;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.56px;
          }
        }
      }
    }
  }
  .action_wrap {
    position: fixed;
    width: 100%;
    max-width: 450px;
    bottom: 0;
    box-shadow: 0px -1px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
    background-color: #fff;
    p {
      margin: 0;
    }
    .action_wrap_padding {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .action_left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .like_text {
          display: flex;
          align-items: center;
          color: #222;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.48px;
          margin-right: 10px;
          .number_text {
            font-size: 14px;
            letter-spacing: -0.56px;
            margin-left: 2px;
          }
        }
        .action_text {
          display: flex;
          align-items: center;
          color: #222;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.48px;
          .number_text {
            font-size: 14px;
            letter-spacing: -0.56px;
            margin-left: 2px;
          }
        }
      }
      .action_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .action_icon_btn {
          margin-left: 10px;
        }
      }
    }
  }
}
