.WriteButtonBottomSheet_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 12px;
  height: 32px;
}
.WriteButtonBottomSheet_title_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.64px;
}
.WriteButtonBottomSheet_close_btn {
  position: absolute;
  display: flex;
  top: 20px;
  right: 20px;
}

.WriteButtonBottomSheet_confirm_btn {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: #222;
}

.WriteButtonBottomSheet_sheet {
  padding-bottom: 24px;
}
