.qna_list_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8px;

  .qna_item {
    margin-bottom: 8px;
  }

  .qna_item_wrap_thumbnail {
    & > div:first-child {
      position: relative;
      width: 100%;
      padding-top: 100%;
    }
    .qna_image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .qna_image_overlay {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 100%;
    border-radius: 6px;
  }

  .account_qna_wrap {
    flex: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 20px;
  }

  .qna_top {
    .title_text {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
      text-align: left;
    }
  }

  .qna_bottom {
    align-self: flex-end;

    .qna_bottom_text {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.64px;
      display: flex;
      align-items: center;

      &_number {
        font-size: 14px;
        margin-left: 2px;
      }
    }
  }
}
