$--pink-color: #be69c3;

.like_item {
  // margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.like {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.info_wrap {
  flex: 1;
  min-width: 0;
  min-height: 0;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.id_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
  text-align: left;
  color: #222;
  text-align: start;
}

.tag_text {
  margin-top: 4px;
  font-size: 14px;
  letter-spacing: -0.64px;
  color: #999;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.btn {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 300px;
  margin-left: 10px;
  &_text {
    font-size: 14px;
    letter-spacing: -0.64px;
  }
}

.follower_btn {
  background-color: $--pink-color;
  &_text {
    color: #fff;
  }
}

.following_btn {
  border: 1px solid $--pink-color;
  &_text {
    color: $--pink-color;
  }
}
