#qna_detail_profile {
  .user_qna_detail_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .user_qna_detail_header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .user_img {
    width: 48px;
    height: 48px;
    border-radius: 100px;
  }
  .info_wrap {
    margin: 0 10px;
  
    .info_text1 {
      text-align: left;
  
      font-size: 16px;
      letter-spacing: -0.64px;
      color: #222;
      font-weight: bold;
      margin-bottom: 2px;
      margin-top: 0;
    }
  
    .info_inner_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      .info_text2 {
        font-size: 12px;
        letter-spacing: -0.48px;
        color: #666666;
        margin: 0;
      }
      .text_bar {
        height: 12px;
        width: 1px;
        background-color: #eeeeee;
        margin: 0 5px;
      }
    }
  }
}
