.withdraw_reason {
  display: flex;
  flex: 1;
  background-color: white;
}
.withdraw {
  margin-top: 16px;
}
.withdraw_reason_select_wrap {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
}
.withdraw_reason_select_list {
  margin-top: 16px;
}
.withdraw_reason_select_item {
  display: flex;
  align-items: center;
}
.withdraw_label_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}
.withdraw_info_text {
  margin-top: 16px;
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56px;
}
.reason_wrap {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.waring_text {
  margin-left: 6px;
  font-size: 12px;
  letter-spacing: -0.48px;
  font-weight: 500;
  color: #ea174a;
}
.etc_wrap {
  padding: 16px 20px 0;
}
.etc {
  padding: 20px;
  background-color: #fafafa;
}
.etc_input {
  ::placeholder {
    color: #999;
  }
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #222;
  line-height: 20px;
}

.bar {
  background-color: #f5f5f5;
  margin: 40px 0px;
  height: 6px;
}
.footer {
}
.withdraw_label_text_wrap {
  padding: 0px 20px;
}
.note_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.note_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f4fa;
  border-radius: 4px;
  margin-top: 16px;
  width: 100%;
  height: 53px;
}
.note_btn_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: 500;
  color: #be69c3;
}
.agree_wrap {
  padding: 0px 20px 76px;
  margin-top: 16px;
  align-items: flex-start;
}
.agree_text2 {
  font-size: 12px;
  letter-spacing: -0.48px;
  font-weight: 500;
  color: #ea174a;
  padding-left: 24px;
}
.confirm_btn {
  background-color: #c4c4c4;
  justify-content: center;
  align-items: center;
  height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
}
.active {
  background-color: #222;
}
.confirm_btn_text {
  color: white;
  font-size: 18px;
  letter-spacing: -0.72px;
  font-weight: bold;
}
.alert_modal {
  width: 66.6%;
  background-color: white;
  border-radius: 12px;
  border-color: transparent;
  margin: auto;
  // ...Assets.modal_box_shadow, // TODO
}
.modal_header {
  align-items: center;
  justify-content: center;
  position: relative;
  height: 64px;
}
.modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
}
.icons {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 6px;
  right: 10px;
}
.header_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.modal_footer {
  display: flex;
  flex-direction: row;
  height: 48px;
}
.separate {
  border: 0.5px solid #666;
  width: 1px;
}
.left_btn {
  border-bottom-left-radius: 12px;
  background-color: #eee;
}
.left_btn_text {
  color: #666;
}
.right_btn {
  border-bottom-right-radius: 12px;
}
.button {
  display: flex;
  flex: 1;
  background-color: #222;
  justify-content: center;
  align-items: center;
}
.footer_button_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: white;
}
.contents_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  line-height: 24px;
}
.toast {
  border-radius: 10px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: black;
}
.toast_text {
  font-size: 16px;
  color: white;
  letter-spacing: -0.64px;
}

.note {
  padding-bottom: 20px;
  padding: 0px 16px 20px 16px;
  border-radius: 4px;
}
.note_contents {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}
.dot {
  width: 3px;
  height: 3px;
  background-color: #666;
  border-radius: 4px;
  margin: 6px 5px 0px 0px;
}
.note_contents_text1 {
  flex: 1;
  font-size: 12px;
  color: #666;
  letter-spacing: -0.48px;
}
.note_contents_text2 {
  font-weight: bold;
  color: #222;
}
.note_contents_text3 {
  font-weight: bold;
  color: #be69c3;
}
.modal_wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.modal_page_view {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.txt_page_title {
  font-size: 28px;
  color: #222;
  font-weight: 700;
  letter-spacing: -1.12px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 16px;
}
.txt_page_content {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56px;
  text-align: center;
  line-height: 20px;
}
.btn_retry {
  padding: 12px 40px;
  border: 1px solid #be69c3;
  border-radius: 300px;
}
.txt_btn_retry {
  font-size: 14px;
  color: #be69c3;
}
.btn_service_end {
  margin-top: 26px;
  border-bottom-width: 1px;
  border-color: black;
}
.txt_service_end {
  font-size: 12px;
  color: black;
  letter-spacing: -0.3px;
}
.centered_view {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal_view {
  position: relative;
  width: 87%;
  background-color: white;
  border-radius: 12px;
  align-items: center;
  ::shadow {
    color: black;
    offset {
      width: 0px;
      height: 2px;
    }
    opacity: 0.25;
    // radius: 4px; // ?
    // elevation 5px; // ?
  }
  overflow: hidden;
}
.btn_close {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 9;
}
.modal_header_wrap {
  margin-top: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
}
.txt_header_title {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.modal_content_wrap {
  width: 100%;
  padding: 16px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.txt_content_title {
  margin: 12px 0px;
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
  font-weight: bold;
  text-align: center;
}
.txt_content {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56px;
  text-align: center;
  line-height: 20px;
  white-space: pre-line;
}
.bx_noti_info {
  width: 100%;
  margin: 12px 0px;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 20px 14px;
  align-items: center;
}
.txt_noti_title {
  font-size: 14px;
  color: #666;
  line-height: 24px;
}
.txt_noti_st1 {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.56px;
  line-height: 20px;
}
.txt_noti_st2 {
  font-size: 12px;
  color: #be69c3;
  line-height: 20px;
}
.bx_cont_li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.txt_dot {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  margin: 6px 6px 0px 0px;
  background-color: #666;
}
.bx_noti_li {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}
.modal_footer_wrap {
  display: flex;
  flex-direction: row;
}
.btn_footer_default {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_bg_bk {
  background-color: #222;
}
.txt_btn_footer {
  font-size: 16px;
  font-weight: bold;
  color: #666;
  letter-spacing: -0.64px;
}
.txt_btn_white {
  color: white;
}

//메인팝업
.bg_none {
  width: 73%;
  background-color: rgba(255, 255, 255, 0);
}
.bg_purple {
  background-color: #aa76ba;
}
.bg_orange {
  background-color: #e58673;
}
.bg_blue {
  background-color: #4a9ad6;
}
.bg_pink {
  background-color: #ff9bbd;
}
.re_btn_close {
  top: 10px;
  right: 0px;
}
.reward_content_wrap {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  margin-top: 48px;
  margin-bottom: 10px;
  align-items: center;
}
.txt_reward_title {
  font-size: 20px;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 28.96%;
  letter-spacing: -0.8px;
}
.txt_reward_content {
  margin: 8px 0px;
  font-size: 14px;
  color: white;
  line-height: 20.27px;
  letter-spacing: -0.56px;
  text-align: center;
}
.img_wrap {
  min-height: 20px;
  justify-content: flex-start;
  align-items: center;
}
.in_image {
  width: 160px;
  height: 160px;
  //resizeMode 'contain', // ?
}
