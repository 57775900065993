#post_product {
  .bar {
    background-color: #F5F5F5;
    height: 6px;
  }
  .home_app_wrap {
    padding: 40px 16px 96px;
    .add_home_app_button {
      background-color: #F7F4FA;
      border-radius: 4px;
      margin-top: 10px;
      padding: 15px;
      width: 100%;
      text-align: center;
      .add_home_app_button_text {
        margin-left: 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.17px;
        letter-spacing: -0.04em;
        color: #b57de2;
      }
    }
    .home_app_list_wrap {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      .home_app_item {
        padding: 10px 20px 10px 10px;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FAFAFA;
        width: 100%;
        .info_wrap {
          display: flex;
          max-width: calc(100% - 18px);
          .home_app_image {
            object-fit: cover;
            width: 60px;
            height: 60px;
            background-color: rgb(255, 255, 255);
            border-radius: 6px;
            border: 1px solid rgba(34, 34, 34, 0.1);
          }
          .info {
            margin-left: 10px;
            flex-direction: column;
            display: flex;
            max-width: calc(100% - 70px);
            .info_text_1 {
              font-size: 14px;
              font-weight: 500;
              line-height: 20.27px;
              letter-spacing: -0.04em;
              color: #222;
              white-space:nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .info_text_2 {
              font-size: 12px;
              font-weight: 400;
              line-height: 17.38px;
              letter-spacing: -0.04em;
              color: #999;
            }
            .rep_info {
              margin-top: 3px;
              width: 46px;
              height: 20px;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 500;
              line-height: 17.38px;
              letter-spacing: -0.04em;
              &.rep_info_active {
                color: #222;
                background-color: #f7e237;
              }
              &.rep_info_inactive {
                color: #999;
                background-color: #fff;
              }
            }
            .alert_text {
              margin-top: 5px;
              font-size: 12px;
              font-weight: 400;
              line-height: 17.38px;
              letter-spacing: -0.04em;
              color: #C00055;
            }
          }
        }
      }
    }
  }
}