.followers-main-sheet {
  .close_btn {
    position: absolute;
    right: 16px;
    top: 6px;
    z-index: 1;
    display: flex;

    & > button {
      display: flex;
    }
  }

  .delete_image {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    & > div:first-child {
      display: flex;
    }
  }

  .top_text {
    margin-top: 10px;

    .delete_text_1 {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.64px;
      text-align: center;
      white-space: pre-line;
      color: #222;
    }
  }

  .bottom_text {
    margin-top: 10px;

    .delete_text_2 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.64px;
      text-align: center;
      color: #222;
      white-space: pre-line;
    }
  }

  .delete_btn {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #222;

    .save_btn_text {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.64px;
      text-align: center;
    }
  }
}

.followers-main {
  .tabs {
    position: -webkit-sticky;
    position: sticky;
    top: 54px;
    background-color: #fff;
    max-width: 450px;
    width: 100%;
    z-index: 100;
  }
}
