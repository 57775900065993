.result_wrap {
  .result {
    margin: 10px 0;

    display: flex;
    flex-direction: row;
    height: 40px;
    border-radius: 6px;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -4%;
    border-radius: 6px;
    overflow: hidden;

    .result_left {
      .result_left_text_win {
        color: #be69c3;
      }
      .result_left_text_lose {
        color: #999;
      }
    }

    .result_right {
      .result_right_text_lose {
        color: #999;
      }
      .result_right_text_win {
        color: #be69c3;
      }
    }
  }
  .result_count {
    .result_count_left {
      .result_count_win_text {
      }
      .result_count_right_icon {
      }
      .result_count_right_icon {
      }
      .result_count_lose_text {
      }
    }
    .result_count_right {
      .result_count_lose_text {
      }
      .result_count_left_icon {
      }
      .result_count_win_text {
      }
      .result_count_left_icon {
      }
    }
  }
}
