#tag-main-foreground {
  padding-bottom: 20px;
  .contents_header {
    background-color: #fff;

    .contents_header_text_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    .contents_header_text1 {
      font-size: 14px;
      font-weight: 500;
      color: #666;
      letter-spacing: -0.56px;
    }

    .contents_header_text2 {
      font-weight: 500;
      color: #222;
    }

    .contents_header_text3 {
      font-size: 24px;
      color: #222;
      letter-spacing: -0.96px;
      font-weight: bold;
      margin-top: 4px;
    }
  }

  .tag_btn {
    width: calc(100% - 32px);
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    height: 53px;
    column-gap: 6px;
    color: #BE69C3;
    padding: 0 15px;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: left;

    &.add {
      background-color: #F7F4FA;
    }

    &.delete {
      border: 1px solid #BE69C3;
    }

  }

  .related-product-slide-wrapper {
    margin-top: 32px;
  }
}