.bx_menu_wrap {
  background-color: #672c78;
}
.volt_comm_wrap {
  width: 100%;
  background-color: rgba(34, 34, 34, 0.2);
}
.bx_comm_top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  .bx_in_touch {
    padding: 0 20px;
    display: flex;
    height: 64px;
  }
  .txt_value_volt {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.04em;
    opacity: 0.6;
    text-decoration: unset;
  }
}
.label_volt_comm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.in_label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.txt_label_volt {
  display: inline-block;
  margin-left: 8px;
  color: #fff;
  font-size: 14px;
  opacity: 0.6;
  font-weight: 500;
  letter-spacing: -0.64px;
}
.bx_value {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.txt_value_volt {
  color: #fff;
  letter-spacing: -64px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04em;
  text-decoration: underline;
}
.bx_comm_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
}
.bx_volt_comm {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 0 20px;
  &:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.15);
  }
}
.bx_in {
  width: 100%;
  border-right-width: 1px;
  border-color: rgba(255, 255, 255, 0.15);
}
p {
  margin: 0;
}