.tag-qna-component {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px 0;
  gap: 8px;
  background-color: #fff;

  .qna-item {
    display: flex;
    position: relative;
    flex: 0 0 auto;
    align-content: stretch;
    box-sizing: border-box;

    .qna-image-inner {
      .qna-image {
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        inset: 0px;
      }

        &.purple_wrap_1{
          background-color: "#6c2773"
        }

        &.purple_wrap_2{
          background-color: "#a760b1",
        }
    }
  }

  .qna-item-color {
    border-radius: 8px;
  }

  .qna-content-wrap {
    position: absolute;
    z-index: 2;
    inset: 0px;
    min-width: 0px;
    min-height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    padding: 20px;
    color: #fff;

    &.qna-overlay {
      background-color: #00000099;
      color: #fff;
    }

    .qna-top {
      .nickname {
        font-size: 12px;
        letter-spacing: -0.48px;
        font-weight: 500;
        display: inline-block;
        margin-bottom: 10px;

        white-space:nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .title {
        font-size: 16px;
        letter-spacing: -0.64px;
        font-weight: 500;

        .title-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }

    }

    .qna-bottom {
      align-self: flex-end;
      font-size: 12px;
      letter-spacing: -0.48px;
      font-weight: 500;
    }
  }
}