.alarm {
  flex: 1;
}

.alarm_list {
  background-color: #fff;
}

.user_img {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.alarm_item {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 19px;
  display: flex;
  flex-direction: row;
  border-color: #eee;
  border-bottom-width: 1px;
}

.last_alarm_item {
  padding-top: 40px;
  padding-bottom: 80px;
  border-bottom-width: 0;
}

.alarm_text_2 {
  color: #be69c3;
}

.alarm_text_3 {
  font-size: 37px;
  color: #999;
}

.empty_alarm_wrap {
  position: relative;
  width: 100%;
  background-color: #fff;
}

.empty_alarm {
  position: absolute;
  top: 100px;
  width: 100%;
}

.empty_alarm_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
}

.empty_alarm_txt {
  font-size: 16px;
  color: #666666;
  font-weight: 500;
}

.confirm_all {
  align-items: center;
  display: flex;
  background-color: #fff;
  justify-content: center;
}

.confirm_all_text {
  font-size: 12px;
  color: #999;
}
