body:has(#MainTextBanner) {
  overflow: hidden;
}

#MainTextBanner {
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner_modal {
    width: 350px;
    background-color: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 12px;
  }

  .header {
    display: flex;
    padding: unset;
    height: unset;
    margin-bottom: 20px;
    justify-content: center;

    .header_text {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.64px;
      text-align: center;
      color: #222;
    }
  }

  .body {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.64px;
    color: #222;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .footer_text1 {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #666666;
  }

  .footer_text2 {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: bold;
    color: #a760b1;
  }
}
