.modal_wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.marketing_agree_modal {
  width: responsiveWidth(66.6);
  background-color: white;
  border-radius: 12px;
  border-color: transparent;
  justify-content: center;
  align-items: center;
  ::shadow {
    color: #000;
    offset: {
      width: 4px;
      height: 4px;
      opacity: 0.25;
    }
  }
}
.modal_header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal_body1 {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
}
.icons {
  margin: 14px 0px;
}
.header_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
  margin-bottom: 3px;
}
.text_0 {
  color: #222;
  letter-spacing: -0.48px;
  margin-bottom: 3px;
  font-size: 14px;
  text-align: center;
}
.text_0_bold {
  color: #222;
  letter-spacing: -0.48px;
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 14px;
}
.modal_footer {
  display: flex;
  flex-direction: row;
}
.button {
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 49px;
}
.footer_button_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.later_receive {
  position: absolute;
  align-items: center;
  bottom: -40px;
  left: 0px;
  right: 0px;
}
.later_receive_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: white;
  font-weight: bold;
  text-decoration-line: underline;
  text-decoration-color: white;
  text-decoration-style: solid;
}
.img {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
