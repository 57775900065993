.header {
  margin-top: 40px;
  padding: 0 20px;
}

.page_number_text {
  font-size: 16px;
  color: #999;
  letter-spacing: -0.64px;
  font-weight: 500;
}

.header_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.header_title_wrap {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.header_title {
  font-size: 28px;
  letter-spacing: -1.12px;
  font-weight: bold;
  color: #222;
  white-space: pre-line;
}

.max_text {
  font-size: 14px;
  color: #be69c3;
  letter-spacing: -0.56px;
  font-weight: bold;
  line-height: 40px;
}
