/* styles.css */
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 1s forwards;
  animation-duration: 300;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
