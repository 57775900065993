.qna_filter_wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .tag_option {
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    padding-left: 20px;
    margin-bottom: 1px;

    .tag_label_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .tag_label {
        padding: 8px 0px;
        font-size: 14px;
        letter-spacing: -0.42px;
        font-weight: bold;
        color: #222;
        white-space: nowrap;
      }
    }

    .chip_wrapper {
      padding: 8px 0px 8px 16px;
      overflow-x: hidden;

      .chip_active {
        height: 34px;
        padding: 0 20px;
        border-radius: 300px;
        background-color: #222;

        .chip_text {
          color: #fff;
          font-size: 14px;
          letter-spacing: -0.42px;
          white-space: nowrap;
        }
      }

      .chip_passive {
        height: 34px;
        padding: 0 20px;
        border-radius: 300px;
        background-color: #fff;

        .chip_text {
          color: #666666;
          font-size: 14px;
          letter-spacing: -0.42px;
          white-space: nowrap;
        }
      }
    }
  }

  .header_option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 16px 20px;

    .header_check_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_check_btn_txt {
        font-size: 14px;
        letter-spacing: -0.56px;
        font-weight: 500px;
        color: #222;
        margin-left: 6px;
      }
    }

    .header_sort_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      .header_sort_btn_txt {
        font-size: 14px;
        color: #666666;
        margin-right: 6px;
      }
    }
  }
}

#qnaListFilterSlider {
  .slick-slide {
    margin: 0 6px;
  }
}
