.main_wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
}

.bx_title {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
}
.txt_title {
  font-size: 18px;
  font-weight: 500;
  color: #222;
}
.txt_title_bold {
  font-weight: 700;
}
.bg_title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  background-color: #F7E237;
  height: 8px;
  z-index: -1;
}
.line_thick {
  height: 10px;
  background-color: #f5f5f5;
}
