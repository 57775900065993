#badge_bottom_sheet {
  .badge_sheet_wrapper {
    .close_btn {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
    }

    .sheet_body {
      padding-top: 58px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .sheet_icon_wrapper {
        display: flex;
      }

      .sheet_label {
        margin-top: 6px;

        &_text2 {
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.04em;
          text-align: center;
          color: #be69c3;
        }
      }

      .sheet_info {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.04em;
        text-align: center;
        white-space: pre-line;

        .sheet_info_text1 {
          width: 320px;
          word-break: keep-all;
        }
      }
    }

    .confirm_btn {
      height: 56px;
      width: 100%;
      max-width: 450px;
      background-color: #222;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      &_text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.04em;
        text-align: center;
      }
    }
  }
}
