.line_row_10 {
  height: 10px;
  background-color: #f5f5f5;
}

.bx_sort {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 10px 16px;
}

.txt_sort {
  font-size: 14px;
  color: #999;
}

.txt_sort_num {
  font-weight: 500;
  color: #BE69C3;
}

.bx_pp_item_wrap {
  div[data-testId="virtuoso-item-list"] {
    padding: 0 16px;
  }
}

.bx_pp_item {
  padding: 0 4px;
  width: 50%;
  margin-bottom: 24px;
}

.bx_item_img {
  border: 1px solid #eee;
  background-color: #fff;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.bx_item_txt {
  margin-top: 8px;
}

.txt_company {
  font-size: 11px;
  font-weight: 700;
  color: #222;
}

.txt_pd_name {
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.txt_price_wrap {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  letter-spacing: -0.4px;
  margin-top: 8px;
}

.txt_price_unit {
  font-size: 14px;
  color: #222;
  font-weight: 500;
}

.bx_rediv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.txt_rediv {
  font-size: 11px;
  color: #999;
  margin-left: 4px;
}

.txt_rediv_num {
  font-weight: 500;
}

.banner_ad {
  display: flex;
  height: 84px;
  margin-bottom: 24px;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
}

.banner_ad_title {
  letter-spacing: -0.24px;
  font-size: 16px;
  color: #222;
  font-weight: bold;
}

.banner_ad_sub {
  letter-spacing: -0.24px;
  font-size: 14px;
  color: #666666;
  font-weight: 500;
}

/* sort & sheet */

.header_sort_btn {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header_sort_btn_txt {
  font-size: 14px;
  color: #666666;
  letter-spacing: -0.56px;
  font-weight: 500;
}

.sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0 19px;
}

.close_btn {
  position: absolute;
  top: 6px;
  right: 6px;
}

.sheet_title_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}

.sheet_title {
  color: #222;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.sort_list {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sort_item {
  padding: 15px 0;
  text-align: center;
}

.sort_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: 500;
}

.sort_active_text {
  color: #be69c3;
  font-weight: bold;
}
