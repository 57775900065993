body:has(#MarketingAgreeModal) {
  overflow: hidden;
}

#MarketingAgreeModal {
  $--border-radius: 12px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .marketing_agree_modal {
    width: 260px;
    border-radius: $--border-radius;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 14px;
  }

  .header_text {
    font-size: 18px;
    color: #222;
    font-weight: bold;
    letter-spacing: -0.64px;
    line-height: 26px;
  }

  .modal_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .icons {
      margin: 0 auto;
      margin-bottom: 14px;
    }

    .text_0 {
      font-size: 12px;
      color: #222;
      letter-spacing: -0.64px;
      font-weight: 500;
    }

    .text_0 ~ .text_0 {
      margin-top: 3px;
    }
  }

  .modal_footer {
    height: 48px;
    border-bottom-left-radius: $--border-radius;
    border-bottom-right-radius: $--border-radius;
    width: 100%;
    background-color: #222;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.64px;
    text-align: center;
  }

  .later_receive {
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.64px;
    text-align: center;
    text-decoration: underline;
  }
}
