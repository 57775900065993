.related-product-item {
  width: 175px;
  margin-left: 8px;

  .relation_product_image {
    width: 100%;
    height: 175px;
    border-radius: 8px;
    margin-bottom: 8px;
    border: 1px solid #0000001a;
    object-fit: cover;
  }

  .relation_product_item_text1 {
    padding: 0 4px;
    font-size: 14px;
    color: #222222;
    letter-spacing: -0.56px;
    font-weight: 500;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .relation_product_info {
    display: flex;
    padding: 0 4px;
    align-items: baseline;
  }

  .relation_product_item_text2 {
    font-size: 12px;
    color: #999999;
    letter-spacing: -0.48px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;

    &.model_name {
      flex: 1;
    }
    &.brand_name {
      max-width: calc(50% - 6px);
    }
  }
  .bar {
    height: 10px;
    width: 1px;
    background-color: #999999;
    margin-right: 6px;
    margin-left: 6px;
    margin-top: unset;
  }
}
