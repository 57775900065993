#product_search {
  .home_app_wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 10;
    height: 64px;
    background-color: #FAFAFA;
    border-radius: 6px;
    margin: 20px 16px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    .home_app_input_wrap {
      flex: 1;
    }

    .home_app_input {
      width: 100%;
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: left;
    }

    .icon_wrap {
      display: flex;
    }
  }

  .footer {
    height: 56px;
    width: 100%;
    max-width: 450px;
    position: fixed;
    bottom: 0;

    .save_button {
      height: 100%;
      color: #fff;
      &.active {
        background-color: #222;
      }
      &.inactive {
        background-color: #eee;
      }
    }

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      -moz-column-gap: 4px;
           column-gap: 4px;

      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: center;
      .footer_num_text {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .text_number {
          transform: translateY(-1px);
        }
        .active {
          color: #222;
        }
        .inactive {
          color: #eee;
        }
      }
    }
  }

  .no_search_text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: center;
    color: #666;
    margin-top: 20px;
  }

  .my_product_list_wrap {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .my_product_list_text {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
    }
    .my_product_max_cnt_text {
      font-size: 14px;
      font-weight: normal;
      letter-spacing: -0.04em;
      color: #999;
    }
  }
}