#qna_write_product {
  .home_app_list_wrap {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    padding: 0 16px;
    margin-bottom: 8px;
  }

  .home_app_item {
    position: relative;
    .image_wrap {
      margin-right: 12px;
      width: 60px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      .dim_image {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .info_wrap {
      padding: 10px calc(20px + 28px) 10px 10px;
      background-color: #fafafa;
      margin: unset;
      border-radius: 6px;
      display: flex;
      align-items: center;

      .info {
        flex: 1;
        min-width: 0;

        .info_text_1 {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.04em;
          text-align: left;
          color: #222;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }

        .info_text_2 {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.04em;
          text-align: left;
          color: #999;
        }

        .rep_info {
          border-radius: 4px;
          padding: 2px 0;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 2px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.04em;
          text-align: center;

          &.active {
            background-color: #F7E237;
            color: #222;
          }

          &.inactive {
            background-color: transparent;
            color: #999;
          }
        }

        .alert_text {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.04em;
          color: #c00055;
          text-align: left;
        }
      }

      .home_app_image {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 6px;
        border: 1px solid #2222221a;
      }
    }
    .icon_wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      display: flex;
    }
  }
}