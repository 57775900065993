.empty_comment_list {
  .empty_image {
    svg {
      width: 100%;
    }
  }
  .comment_list_empty_text_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    .comment_list_empty {
      color:  #666;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
    }
    .write_comment_button {
      padding: 12px 20px;
      border: 1px solid #BE69C3;
      color: #BE69C3;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      letter-spacing: -0.56px;
      border-radius: 50px;
    }
    .empty_btn_wrap {
      padding: 12px 20px;
      border: 1px solid #BE69C3;
      color: #BE69C3;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      letter-spacing: -0.56px;
      border-radius: 50px;
    }
  }
}