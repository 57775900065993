.header {
  position: fixed;
  top: 0;
  max-width: 450px;
  width: 100%;
  z-index: 100;
}

.join_email {
  background-color: #fff;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.step_list {
  flex: 1;
  min-width: 0;
  min-height: 0;
  overflow: auto;
}

.space_bottom {
  height: 56px;
}

.footer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  max-width: 450px;
  width: 100%;
}

.btn_1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.txt_active {
  color: #fff;
}

.txt_in_active {
  color: #999;
}

.active {
  background-color: #222;
}

.in_active {
  background-color: #eeeeee;
  border-color: #d9d9d9;
}

.step_item {
  margin-bottom: 16px;
}

.step_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  height: 24px;
}

.step_header_btn {
  font-family: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.step_header_active_text {
  font-size: 16px;
  color: #222;
  font-weight: 700;
  letter-spacing: -0.64px;
}

.step_header_in_active_text {
  font-size: 14px;
  color: #999;
  letter-spacing: -0.56px;
}

.step_body {
  margin-top: 16px;
  padding-bottom: 36px;
}

.step_body_term {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 36px;
  border-color: #f5f5f5;
  border-top-width: 1px;
  border-top-style: solid;
}

.email_wrap {
  display: flex;
  flex-direction: row;
  padding-bottom: 7px;
  margin-right: 20px;
  margin-left: 20px;
  align-items: center;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.email_wrap_active {
  border-color: #6c2773;
}

.email_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22px;
  letter-spacing: -0.88px;
}

.auth_btn {
  border-radius: 10px;
  border-color: #999;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  padding: 0px 4px;
}

.auth_btn_active {
  background-color: #6c2773;
  border-color: #6c2773;
}

.auth_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #999;
}

.auth_btn_text_active {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #fff;
}

.cancel_btn {
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.auto_complete_list {
  background-color: #fff;
  border-color: #eee;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 14px;
  padding-left: 14px;
  position: absolute;
  left: 20px;
  width: calc(100% - 68px);
  top: 180px;
  z-index: 1000;
  border-width: 1px;
  border-style: solid;
  box-shadow: 4px 4px 8px 0px #00000026;
}

.auto_complete_item {
  justify-content: center;
  border-color: #eee;
  height: 34px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.auto_complete_last_item {
  border-bottom-width: 0;
}

.auto_complete_text {
  font-size: 13px;
  letter-spacing: -0.52px;
  color: #222;
}

.alert_wrap {
  margin-top: 6px;
  padding-right: 20px;
  padding-left: 20px;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email_not_valid {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.email_valid {
  font-size: 12px;
  color: #6a0;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.auth_code_wrap {
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.auth_code {
  background-color: #fff;
  border-radius: 10px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  margin-bottom: 6px;
  border-width: 1px;
}

.auth_code_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
}

.auth_code_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}

.timer_text {
  font-size: 16px;
  color: #c00055;
  letter-spacing: -0.64px;
  font-weight: 500;
  margin-right: 12px;
}

.auth_code_btn {
  background-color: #222;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 36px;
}

.auth_code_btn_text {
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.64px;
}

.auth_code_bottom {
  padding-bottom: 16px;
}

.auth_code_bottom_text {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
}

.auth_code_alert {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth_code_alert_text {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.resend {
  margin-left: 3px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.resend_text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.48px;
}

.toast {
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: black;
}

.toast_text {
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.64px;
}

.pw_input_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 7px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.pw2_input_wrap {
  margin-top: 18px;
}

.pw_input_wrap_focus {
  border-color: #6c2773;
}

.pw_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22px;
  letter-spacing: -0.88px;
  margin-right: 10px;
}

.toggle_btn {
  align-items: center;
  justify-content: center;
}

.pw_valid {
  font-size: 12px;
  color: #6c2773;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.pw_not_valid {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.pw_not_active {
  font-size: 12px;
  color: #999;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.pw_secure_header_text {
  flex: 1;
  min-width: 0;
  min-height: 0;
  height: 40px;
  pointer-events: none;
}

.phone_input_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 7px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.phone_input_wrap_focus {
  border-color: #6c2773;
}

.phone_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22px;
  letter-spacing: -0.88px;
  margin-right: 10px;
}

.phone_alert_text {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.bold_text {
  font-weight: bold;
}

.phone_not_valid {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.phone_success_valid {
  font-size: 12px;
  color: #66aa00;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.agree_wrap {
  padding-right: 20px;
  padding-left: 20px;
}

.all_ck_box {
  margin-bottom: 16px;
}

.all_ck {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all_ck_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #222;
}

.ck_txt_mm {
  padding-left: 8px;
}

.ck_txt {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
}

.required {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #6c2773;
}

.ck_box {
  background-color: #f5f5f5;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 14px;
  padding-right: 14px;
}

.ck_touch {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ck_touch_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.view_btn {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #999999;
  margin-left: 4px;
  border-radius: 5px;
}

.view_btn_txt {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #fff;
  padding-right: 5px;
}

.options {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #666666;
}

.bm_spacing {
  margin-top: 10px;
}

.sms_ck {
  margin-left: 24px;
}

.email_ck {
  margin-left: 4px;
}
