#AppInstallTopBanner {
  $AppInstallTopBannerHeight: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 450px;
  background-color: #FDDFF2;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: $AppInstallTopBannerHeight;

  .icon {
    display: flex;
  }

  .description {
    // flex: 1;
    margin-left: 10px;
    // width: 300px;
    display: flex;
    flex-direction: column;
    text-align: left;

    .text-line-1 {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.64px;
      color: #6C2773;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-line-2 {
      font-size: 14px;
      letter-spacing: -0.64px;
      color: #222;
      margin-top: 2px;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .action-wrapper {
    // margin-left: 20px;
    position: absolute;
    right: 16px;

    .btn {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6C2773;
      font-weight: 500;
      letter-spacing: -0.64px;
      border: thin solid #6C2773;
      padding: 0 14px;
      border-radius: 300px;
      background-color: #fff;
      white-space: nowrap;
    }
  }
}