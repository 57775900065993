body.app-install-side-banner {
  #WriteButton {
    .circle {
      right: clamp(16px, calc(50% - 225px - 185px + 16px), 100%);
    }
  }
}

#WriteButton {
  .post_plus {
    display: flex;
    justify-content: center;
    // position: "absolute",
    align-self: flex-end;
    right: 16px;
  }

  .circle {
    position: fixed;
    right: clamp(16px, calc(50% - 225px + 16px), 100%);
    display: flex;
    background-color: #672c78;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    z-index: 2;
    &.fail {
      width: 84px;
      height: 84px;
      background-color: transparent;
      right: clamp(0px, calc(50% - 225px), 100%);
      svg {
        margin-top: 24px;
      }
    }
    // ...Platform.select({
    //   ios: {
    //     shadowColor: "#000",
    //     shadowOffset: { width: 0, height: -5 },
    //     shadowOpacity: 0.05,
    //     shadowRadius: 8,
    //     ...Spacings.margin_top_8,
    //   },
    //   android: { elevation: 4, ...Spacings.margin_top_4 },
    // }),
  }
  // fail: {
  //   marginTop: Platform.OS === "ios" ? -10 : -20,
  // },
}
