.modal_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.notification_modal {
  width: 265px;
}

.modal_container {
  background-color: #fff;
}

.modal_header {
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  height: 64px;
}

.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
}

.icons {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 6px;
  right: 10px;
}

.header_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
  text-align: center;
  line-height: 64px;
}

.text_0 {
  font-size: 16px;
  letter-spacing: -0.64px;
  margin-bottom: 24px;
  color: #222;
  font-weight: 500;
  // white-space: pre-wrap;
  white-space: pre-line;
  align-items: center;
}

.text_1 {
  width: 250px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  text-align: center;
  letter-spacing: -0.56px;
  font-weight: 500;
  color: #222;
  white-space: pre-wrap;
}

.modal_footer {
  height: 48px;
  display: flex;
}

.modal_footer_two {
  display: flex;
  flex-direction: row;
  height: 48px;
}

.button {
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.purple {
  background-color: #6c2773;
}

.black {
  background-color: #222222;
}

.footer_button_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #fff;
}

.button_line_black {
  border-color: #666666;
  width: 1px;
  border-width: 0.5px;
}

.button_left {
  border-color: #666666;
  border-right-width: 0.5px;
  border-right-style: solid;
}

.button_right {
  border-color: #222;
  border-right-width: 0.5px;
  border-right-style: solid;
}

.button_two {
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.gray {
  background-color: #eeeeee;
}

.footer_button_gray_text {
  color: #666666;
}
