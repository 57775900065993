.modal_page_view {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 26px;
}
.txt_page_title {
  font-size: 20px;
  color: #222;
  font-weight: 700;
  letter-spacing: -1.12px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 16px;
}
.txt_page_content {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56;
  text-align: center;
  line-height: 20px;
}
