#qna_detail {
  .qna_wrap {
    background-color: rgb(255, 255, 255);
    .user_qna_detail_wrap {
      margin-bottom: 14px;
    }
    .action_wrap {
      margin-top: 10px;
      padding: 0 20px;
      .action_wrap_padding {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .action_text {
          display: flex;
          align-items: center;
          color: #222;
          font-weight: 500;
          margin: 0;
          .like_text,
          .comment_text {
            margin: 0;
            font-size: 12px;
            letter-spacing: -0.48px;
          }
          .number_text {
            margin: 0 10px 0 2px;
            font-size: 14px;
            letter-spacing: -0.56px;
          }
        }
        .action_right {
          display: flex;
          align-items: center;
          .action_icon_btn {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
