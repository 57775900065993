.info_wrap {
  margin-bottom: 24px;
}

.year_sheet {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.year_title_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 32px;
}

.year_list {
  flex: 1;
  min-height: 0;
  overflow: auto;
  margin: 0 -20px;
}

.year_title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}

.year_item {
  display: flex;
  align-items: center;
  height: 42px;

  button {
    width: 100%;
    height: 100%;
  }
}

.year_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}

.selected_year_text {
  font-weight: bold;
  color: #be69c3;
}

.bg_sheet {
  padding-top: 16px;
  padding-bottom: 16px;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.bg_title_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
  margin-bottom: 16px;
}

.bg_list {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  align-items: center;
}

.bg_list_top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bg_list_bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bg_item {
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  width: 50px;
  height: 50px;
}

.active_item {
  width: 54px;
  height: 54px;
  border-width: 4px;
  border-color: #9450d8;
  border-style: solid;
}

.last_item {
  margin-right: 0;
}

.close_btn {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 999;
  display: flex;
}
