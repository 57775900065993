#MainHeader {
  position: fixed;
  top: 0;
  left: clamp(0px, calc(50% - 225px), 50vw);
  max-width: 450px;
  width: 100%;
  z-index: 100;

  .header_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding-top: Platform.OS === ios ? 40 : 0;
    padding: 14px 0;
    background-color: #682c78;
    height: 60px;
  }

  .header_spacing {
    display: flex;
    flex: 1;
    padding-right: 16px;
    padding-left: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left.bottom_check {
    padding-bottom: 3px;
  }

  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .icons {
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 6px;
  }

  .icons.menu {
    background-image: url("../../assets/icons/menu.svg");
  }

  .icons.search {
    background-image: url("../../assets/icons/search.svg");
  }
  .alert {
    position: relative;
  }
  .alert_text_area {
    position: absolute;
    right: 8px;
    top: 2px;
    width: 12px;
    height: 12px;
    border: 1px solid;
    border-radius: 25px;
    border-color: #d90000;
    background-color: #d90000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert_text {
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    line-height: 11px;
    padding-left: 1px;
  }
  .profile_photo {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .id_text {
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 500;
    color: #fff;
  }
  .profile_image {
    width: 32px;
    height: 32px;
    border-radius: 100px;
  }
  .profile_btn {
    position: absolute;
    margin-left: 4px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    left: 29px;
    top: 33px;
  }
  //   profile_btn1: {
  //     ...Positions.absolute;
  //     ...Positions.left;
  //     top: -1.8;
  //     width: 0;
  //     height: 0;
  //     borderLeftWidth: 3;
  //     borderLeftColor: transparent;
  //     borderRightWidth: 3;
  //     borderRightColor: transparent;
  //     borderBottomWidth: 1.73;
  //     borderBottomColor: #fff;
  //   };
  //   profile_btn2: {
  //     width: 5.9;
  //     height: 3.46;
  //     backgroundColor: #fff;
  //   };
  //   profile_btn3: {
  //     ...Positions.absolute;
  //     ...Positions.left;
  //     bottom: -1.5;
  //     width: 0;
  //     height: 0;
  //     borderLeftWidth: 3;
  //     borderLeftColor: transparent;
  //     borderRightWidth: 3;
  //     borderRightColor: transparent;
  //     borderTopWidth: 1.73;
  //     borderTopColor: #fff;
  //   };
  //   profile_nickname_badge: {
  //     ...FlexBoxs.horizon;
  //   };
  //   profile_badge_area: {
  //     ...FlexBoxs.horizon;
  //   };
  .profile_badge_btn {
    display: flex;
    margin-left: 8px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    align-self: center;
  }
  //   title_txt: {
  //     ...Texts.header_text_0;
  //   };
  //   qna_btn: {
  //     height: 36;
  //     borderWidth: 1;
  //     borderRadius: 18;
  //     ...Colors.border_color_222;
  //     ...Arranges.center_v_h;
  //     ...Spacings.padding_right_left_12;
  //   };
  //   qna_btn_txt: {
  //     ...Texts.contents_text_3;
  //   };

  //   alert_modal: {
  //     width: responsiveWidth(82);
  //     ...Colors.background_fff;
  //     ...Spacings.border_top_left_radius_12;
  //     ...Spacings.border_top_right_radius_12;
  //     ...Spacings.border_bottom_left_radius_12;
  //     ...Spacings.border_bottom_right_radius_12;
  //     ...Colors.border_color_transparent;
  //     ...Arranges.margin_center_v_h;
  //     ...Assets.modal_box_shadow;
  //   };
  //   modal_header: {
  //     ...Arranges.center_v_h;
  //     ...Positions.relative;
  //     height: 64;
  //   };
  //   modal_body: {
  //     ...FlexBoxs.vertical;
  //     ...Arranges.center_v_h;
  //     ...Spacings.padding_bottom_24;
  //   };
  //   header_text: {
  //     ...Texts.modal_text_0;
  //   };
  //   contents_text: {
  //     ...Texts.contents_text_31;
  //     ...Texts.font_weight_500;
  //     ...Colors.font_222;
  //     lineHeight: 24;
  //   };
  //   modal_footer: {
  //     ...FlexBoxs.horizon;
  //     height: 48;
  //   };
  //   separate: {
  //     ...Colors.two_btn_border_color_666666;
  //     borderWidth: 0.5;
  //     width: 1;
  //   };
  //   left_btn: {
  //     ...Spacings.border_bottom_left_radius_12;
  //   };
  //   right_btn: {
  //     ...Spacings.border_bottom_right_radius_12;
  //   };
  //   button: {
  //     ...Colors.background_222;
  //     ...Arranges.center_v_h;
  //     ...FlexBoxs.flex_1;
  //   };
  //   footer_button_text: {
  //     ...Texts.button_text_3;
  //     ...Colors.font_fff;
  //   };
  //   modal_icons: {
  //     ...Icons.icon_32;
  //     ...Positions.absolute;
  //     ...Positions.top_6;
  //     ...Positions.right_10;
  //   };
  //   profile_photo_wrap: {
  //     borderWidth: 1;
  //     ...FlexBoxs.horizon;
  //     ...Arranges.center_v_h;
  //   };
}
