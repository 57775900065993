#volt-info-screen {
  .bx_summary {
    padding: 16px;
    margin: 16px;

    .txt_dis_label {
      margin-left: 4px;
    }

    .bx_info_wrap {
      height: 20px;
      margin-left: 8px;

      .bx_tooltip_wrap {
        .txt_tooltip {
          padding: 4px 0 0;
        }

        .img_arrow {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .banner-info-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
  }

  .bx_tab_wrap {
    .tab {
      &.active {
        border-bottom: 2px solid #222;
      }
    }
  }

  .volt-history-item {
    .txt_value {
      display: flex;
      align-items: center;

      .txt_unit {
        margin-left: 4px;
      }
    }
  }

  .list_item_expired {
    .txt_value_expired {
      display: flex;
      align-items: center;

      .txt_unit {
        margin-left: 4px;
      }
    }
  }

  .volt {
    flex: 1;
    background-color: #fff;
  }

  .bx_summary {
    margin: 10px 20px;
    padding: 23px 20px;
    background-color: #672c78;
    border-radius: 12px;
  }

  .bx_total_2 {
    margin-top: 10px;
  }

  .bx_link_open {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    .txt_link {
      margin-right: 4px;
    }
  }

  .li_list_wrap {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .list_item_align {
    margin-top: 8px;
  }

  .bx_total,
  .in_txt_box,
  .bx_banner_info,
  .list_item_align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .txt_summ_label {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    letter-spacing: -0.72px;
    margin-left: 4px;
  }

  .txt_summ_value {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    letter-spacing: -0.96px;
  }

  .txt_dis_label {
    font-size: 14px;
    color: #d8b8d8;
    letter-spacing: -0.54px;
    margin: 0 4px;
  }

  .txt_dis_value {
    font-size: 16px;
    font-weight: 600;
    color: #d8b8d8;
    letter-spacing: -0.64px;
  }

  .bx_banner_info {
    margin: 12px 20px;
    padding: 0 0 5px 20px;
    background-color: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
  }

  .bx_text {
    flex-shrink: 1;
    flex-grow: 1;
  }

  .txt_info {
    font-size: 12px;
    color: #222;
    letter-spacing: -0.84px;
  }

  .txt_link {
    color: #672c78;
    font-weight: 500;
    margin-top: 2px;
  }

  .bx_img,
  .img_style {
    width: 73px;
    height: 68px;
  }

  .img_style {
    object-fit: contain;
  }

  .bx_tab_wrap {
    padding: 0 26px;
    position: sticky;
    top: 60px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    background-color: #fff;
  }

  .btn_tab {
    padding-top: 12px;
    padding-bottom: 10px;
    margin-right: 26px;
    justify-content: center;
    align-items: center;
    border-bottom-width: 2px;
    border-color: #fff;
  }

  .btn_tab_active {
    border-color: #222;
  }

  .txt_tab {
    font-size: 16px;
    color: #222;
    letter-spacing: -0.64px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .txt_tab_active {
    font-weight: 700;
  }

  .bx_list_wrap {
    padding: 16px;
  }

  .bx_list_info {
    padding: 12px;
    background-color: #f7f4fa;
    border-radius: 4px;
  }

  .li_dot {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #be69c2;
    margin-right: 6px;
    justify-content: center;
  }

  .txt_list_info {
    font-size: 12px;
    color: #be69c2;
  }

  .volt_history_item_wrap {
    &.last {
      .list_item {
        border: none;
      }
    }
  }

  .list_item_expired,
  .list_item {
    padding-top: 16px;
    border-bottom: 1px solid #eee;
  }

  .list_item_expired {
    padding-top: 14px;
    padding-bottom: 28px;
  }

  .list_item {
    padding-bottom: 16px;
  }

  .txt_default {
    color: #222;
  }

  .txt_purple {
    color: #672c78;
  }

  .txt_content {
    font-size: 16px;
    color: #222;
    font-weight: 500;
  }

  .txt_value,
  .txt_value_expired {
    font-size: 20px;
    font-weight: 600;
    align-items: center;
  }

  .txt_value {
    color: #672c78;
  }

  .txt_value_expired {
    color: #222;
  }

  .txt_value_minus {
    color: #ea174a;
  }

  .txt_unit {
    font-size: 14px;
    font-weight: 400;
  }

  .txt_date,
  .txt_date_val {
    font-size: 12px;
    color: #666;
    font-weight: 500;
  }

  .txt_date_val {
    color: #999;
  }

  .bx_info_wrap {
    position: relative;
    justify-content: center;
  }

  .bx_tooltip_wrap {
    position: absolute;
    align-items: center;
    min-width: 120px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 12px;
    padding-top: 14px;
    background-color: #222;
    border-radius: 6px;
    bottom: 36px;
    left: -50px;

    &.show {
      display: flex;
    }

    &.hide {
      display: none;
    }
  }

  .txt_tooltip {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.48px;
  }

  .img_close,
  .img_arrow {
    position: absolute;
  }

  .img_close {
    right: 2px;
    top: 0;
  }

  .img_arrow {
    bottom: -10px;
  }
}
