.withdraw {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
}
.modal {
  display: flex;
  flex: 1;
  width: responsiveWidth(100);
  position: absolute;
  background-color: white;
  border-color: transparent;
  margin: auto;
  ::shadow {
    color: black;
    offset: {
      width: 4px;
      height: 4px;
    }
  }
  padding-top: 10px;
}
.modal_header {
  height: 48px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.icons {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 6px;
  right: 10px;
}
.view_img {
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.img {
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
}
.header_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.title_wrap {
  width: responsiveWidth(5.1);
  height: responsiveWidth(5.1);
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}
.title_text1 {
  font-size: 24px;
  color: #222;
  letter-spacing: -0.96px;
  font-weight: bold;
}
.title_text2 {
  counter-reset: #be69c3;
}
.title_text3 {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-size: 32px;
  font-weight: 700;
  color: #222;
  letter-spacing: -0.72px;
  white-space: pre-line;
  text-align: center;
  span:first-child {
    color: #BE69C3;
  }
  span:nth-child(2) {
    color: #222;
    font-size: 18px;
    font-weight: 500;
  }
}
.remove_info_list {
  margin-top: 25px;
}
.remove_info_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
}
.icon {
  width: 72px;
  height: 72px;
  margin-bottom: 12px;
}
.remove_info_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #222;
  margin-bottom: 7px;
}
.remove_info_text_bold {
  font-weight: bold;
}
.remove_info_text_bold_v2 {
  font-weight: bold;
}
.note_wrap {
  padding: 0 16px;
}
.note {
  background-color: #fafafa;
  padding: 20px 16px;
  border-radius: 4px;
}
.note_title_text {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56px;
  font-weight: bold;
}
.note_contents {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
}
.dot {
  width: 3px;
  height: 3px;
  background-color: #666;
  border-radius: 4px;
  margin: 6px 5px 0px 0px;
}
.note_contents_text1 {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.48px;
  flex: 1;
}
.note_contents_text2 {
  font-weight: bold;
  color: #222;
}
.note_contents_text3 {
  font-weight: bold;
  color: #be69c3;
}
.note_contents_text4 {
  font-size: 12px;
  color: #dc301e;
  letter-spacing: -0.48px;
}
.note_bar {
  // padding: 0px responsiveWidth(5.1);
  margin: 0px 16px;
  background-color: #999;
  height: 0.5px;
  display: flex;
  flex-grow: 0;
}
.bar {
  background-color: #f5f5f5;
  height: 6px;
  margin: 40px 0px 32px 0px;
}
.footer {
  // padding: 0px responsiveWidth(5.1);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}
.withdraw_btn {
  margin-top: 24px;
}
.withdraw_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  text-decoration: underline;
  font-weight: 500;
  color: #999;
}
.title_withdraw_first_view {
  padding: 0px responsiveWidth(5.1);
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}
.title_withdraw_first_text {
  font-size: 25px;
}
.title_withdraw_second_text {
  color: #be69c3;
  font-size: 25px;
  font-weight: bold;
}
.text_underline {
  text-decoration: underline;
}
.no_withdraw_btn {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #222;
  border-radius: 4px;
  margin-top: 20px;
  height: 53px;
}
.no_withdraw_btn_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: white;
}
