#bookmark_feed {
  .header {
    margin-bottom: 10px;
    position: sticky;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    top: 116px;
    .header_left {
      display: flex;
      align-items: center;
      .header_check_btn {
        margin-right: 8px;
        height: 22px;
      }
      .header_text_wrap {
        .header_text {
          display: flex;
          color: #222;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.64px;
          .header_text_count {
            margin-left: 4px;
            color: #BE69C3;
          }
        }
      }
    }
    .header_btn {
      padding: 8px 12px;
      color: #BE69C3;
      background-color: #F6F4FA;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.56px;
      border-radius: 50px;
    }
  } 
  .empty {
    margin-top: 210px;
    text-align: center;
    .empty_ab {
      color: #666;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.64px;
    }
    .empty_ab_btn {
      color: #BE69C3;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.56px;
      padding: 12px 20px;
      border: 1px solid #BE69C3;
      border-radius: 50px;
      margin-top: 14px;
    }
  }
  .post_grid_wrap {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3px;
  }
  .qna_grid_wrap {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 0 16px;
  }
  .ab_list_wrap {
    display: flex;
    flex-direction: column;
  }
  .product_grid_wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
