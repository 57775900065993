.comment_list {
  .comment_item {
    .normal_comment,
    .best_comment {
      padding: 20px;
    }
    .header {
      display: flex;
      align-items: center;
      padding: 0;
      .left {
        display: flex;
        align-items: center;
        .user_image {
          height: 34px;
          .image {
            width: 34px;
            height: 34px;
            border-radius: 50%;
          }
        }
        .info {
          display: flex;
          .id_text {
            margin-left: 6px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.56px;
            color: #222;
          }
          .best_answer_wrap {
            margin-left: 6px;
            border-radius: 21px;
            background: #be69c3;
            padding: 2px 8px;
            display: flex;
            align-items: flex-start;
            width: 65px;
            .best_answer_text {
              color: #fff;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              letter-spacing: -0.48px;
              display: flex;
            }
          }
        }
      }
    }
    .comment_body_wrap {
      margin-left: 40px;
      .body {
        margin: 4px 0 15.5px;
        .comment {
          color: #666;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.56px;
        }
      }
      .comment_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .inner_left {
          display: flex;
          align-items: center;
          .info_text {
            color: #666;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.48px;
          }
          .count_wrap {
            .reply_wrap {
              margin: 0 10px;
              color: #666;
              letter-spacing: -0.48px;
              font-size: 12px;
              display: flex;
              align-items: center;

              .reply_text {
                font-weight: 400;
                margin-right: 2px;
              }

              .reply_count {
                font-weight: 700;
              }
            }
            .count_text {
              color: #666;
              margin-left: 2px;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: -0.48px;
            }
          }
        }
        .action_text {
          color: #be69c3;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.56px;
        }
      }
    }
    .comment_item_product_list {
      margin-bottom: 14px;
      .product_item {
        .home_app_item {
          display: flex;
          padding: 10px;
          .home_app_info {
            text-align: left;
            .home_app_text1 {
              color: #be69c2;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.56px;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .home_app_text2 {
              color: #999;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: -0.48px;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .home_app_img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
  .button_sub_comment_item {
    width: 100%;
  }
}
