#SearchResultLayout {
  flex: 1;
  background-color: #fff;
  width: 100%;
  min-height: 100dvh;

  #TodayProduct {
    .bx_title {
      padding: 0 20px;
      .txt_search_title {
        font-weight: bold;
      }
    }
  }

  .search_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 20px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
  }
  .search_back {
    display: flex;
  }
  .search_home {
    display: flex;
    margin-left: 10px;
  }
  .input_wrap {
    position: relative;
    width: calc(100% - 32px);
  }
  .nick_name {
    height: 44px;
    padding-left: 20px;
    padding-right: 40px;
    border-radius: 6px;
    background-color: #fafafa;
    font-size: 14px;
    letter-spacing: -0.56px;
    width: 100%;
  }
  .nick_name_wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    background-color: #fafafa;
    border-radius: 6px;
    padding-right: 12px;
  }
  .search_icon {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      display: flex;
    }
  }
  .close_purple {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    & > div {
      display: flex;
    }
  }
  .auto_complete_list {
    padding-top: 8px;
    // marginBottom Platform.OS === "ios" ? responsiveHeight(40)  0
  }
  .auto_complete_item {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
    align-items: center;
  }
  .auto_complete_text {
    margin-left: 6px;
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: { ...font_weight_500 },
    //   android: { ...font_weight_400 },
    // }),
  }
  .auto_complete_match_text {
    color: #a760b1;
  }

  #SearchTabBar {
    flex-grow: 0;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 66px;
    z-index: 5;
    padding: unset;

    .search_txt_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &_active {
        &::after {
          content: "";
          height: 2px;
          width: 100%;
          background-color: #222;
          position: absolute;
          bottom: 0;
        }
      }
    }
    .search_txt {
      font-size: 16px;
      letter-spacing: -0.64px;
      font-weight: 500;
      color: #222;
    }
  }

  .search {
    flex: 1;
  }
  .tab_content {
    background-color: #fff;
    flex: 1;
  }

  .title {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .key_word_wrap {
    padding-left: 30px;
    padding-right: 30px;

    margin-top: 32px;
  }

  .recommend_tag_list_wrap {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .recommend_tag_item {
    background-color: #f7f4fa;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 300;
    margin-right: 6px;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;

    padding-top: 10px;
    padding-bottom: 10px;
  }
  .recommend_tag_item_text {
    font-size: 14px;
    letter-spacing: -0.42px;
    color: #a760b1;
  }
  .ranking_keyword_wrap {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
  }
  .ranking {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #222;
  }
  .ranking_date {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #999;
    font-weight: 500;
  }
  .ranking_box {
    padding-left: 30px;
    padding-right: 30px;
    flex-direction: row;
  }
  .first_ranking {
    flex: 1;
  }
  .second_ranking {
    flex: 1;
  }
  .rank {
    flex-direction: row;
    margin-bottom: 26px;
  }
  .rank_txt {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }
  .rank_txt_wrap {
    padding-left: 10px;
  }
  .rank_number {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #a760b1;
    font-weight: bold;
  }
  .search_result_wrap {
    margin-top: 10px;
  }
  .search_tab {
    display: flex;
    column-gap: 30px;
    overflow-x: auto;
    white-space: nowrap;
    height: 56px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
  }
  .search_txt {
    padding: 0 4px;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #999;
  }
  .search_txt_active {
    padding: 0 4px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
  }
  #SearchCountAndSort {
    z-index: 5;
    position: sticky;
    position: -webkit-sticky;
    top: calc(66px + 56px);
    padding: 16px 20px 25px 20px;
    background-color: #fff;
  }
  .search_result_info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .search_info {
    display: flex;
  }
  .info_number {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #a760b1;
  }
  .info_txt {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #999;
  }
  .search_sort {
    display: flex;
  }
  .search_sort_txt {
    font-size: 14px;
    color: #666666;
    letter-spacing: -0.56px;
    font-weight: 500;
    margin-right: 6px;
  }

  .scroll_wrap {
    // margin-bottom:140;
    background-color: #fff;
  }
  // .row {
  //   flex-direction: row;
  //   justify-content: space-between;
  //   margin-bottom: 30px;
  // }
  .last_row {
    margin-bottom: 120px;
  }

  .section_title_wrap {
    display: flex;
    margin-left: 20px;
    margin-top: 40px;
    align-items: center;
    margin-bottom: 14px;
  }
  .section_title_wrap_ex {
    margin-top: 10px;
  }
  .section_title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.72px;
    margin-right: 4px;
    color: #222;
  }
  .section_number {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.72px;
    color: #a760b1;
  }
  .info_txt1 {
    font-size: 12px;
    color: #666666;
    letter-spacing: -0.48px;
    font-weight: 500;
  }
  .info_txt2 {
    font-size: 12px;
    letter-spacing: -0.48px;
    color: #999;
  }
  .qna_bar {
    width: 1px;
    height: 12px;
    margin-left: 7px;
    margin-right: 7px;
    background-color: #eeeeee;
  }
  .qna_item_wrap {
    border-color: #eee;
    padding-bottom: 20px;
  }
  .qna_item_wrap_padding {
    padding-top: 25px;
  }
  .qna_item_wrap_border {
    border-bottom: 1px solid #eee;
  }
  .empty_wrap {
    // ...Positions.fullscreen;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .empty_text {
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.64px;
    font-weight: 500;
  }
  .recommend_user_image {
    width: 64px;
    height: 64px;
    border-radius: 32px;
  }
  .recommend_users_text1 {
    margin-bottom: 10px;
    padding-left: 30px;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    // ...Platform.select({
    //   ios: { font-weight: 500 };
    //   android: { font-weight: 400 };
    // });
    font-weight: 500;
    color: #333;
  }
  .recommend_noting_tag_wrap {
    padding: 0 16px;
  }
  .recommend_noting_tag_list_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .recommend_tag_item_bottom {
    background-color: #f7f4fa;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 300px;
    margin-right: 6px;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .tag_list {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tag_item {
    flex-direction: row;
    margin-bottom: 20px;
  }
  .tag_last_item {
    margin-bottom: 140px;
  }
  .tag_title_text {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    // ...Platform.select({
    //   ios: { font-weight: 500 };
    //   android: { font-weight: 400 };
    // });
    font-weight: 500;
    width: 75%;
    // width: responsiveScreenWidth(75);
  }
  .tag_count_text {
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #999;
  }

  .latest_search {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 8px;
    margin-top: 32px;
  }
  .latest_search_header {
    flex-direction: row;
    justify-content: space-between;
  }
  .delete_all_btn_text {
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 500;
    color: #666666;
  }
  .latest_search_list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .latest_search_item {
    align-items: center;
    flex-direction: row;
    // width: responsiveScreenWidth(44);
    width: 44%;
    height: 42px;
  }
  .latest_search_text {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: { font-weight: 500 };
    //   android: { font-weight: 400 };
    // });
  }

  .auto_complete_list {
    padding-top: 8;
  }
  .auto_complete_item {
    flex-direction: row;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 11px;
    align-items: center;
  }
  .auto_complete_text {
    margin-left: 6px;
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: { font-weight: 500 };
    //   android: { font-weight: 400 };
    // });
  }
  .auto_complete_match_text {
    color: #a760b1;
  }
  .sheet {
    flex: 1;
    padding-top: 16px;
    padding-bottom: 19px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .close_btn {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .sheet_title_wrap {
    justify-content: center;
    align-items: center;
    height: 32px;
  }
  .sheet_title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.72px;
    color: #222;
  }
  .sort_list {
    margin-top: 16px;
    align-items: center;
  }
  .sort_item {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sort_text {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
  }
  .sort_active_text {
    color: #be69c3;
    font-weight: bold;
  }
  .on_sale_tag_wrap {
    background-color: #f0dff2;
    border-radius: 4px;
    display: flex;
    width: fit-content;
    align-self: flex-start;
    height: 17px;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .on_sale_tag_text {
    color: #be69c2;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 14px;
    padding-left: 1px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }
  .mall_tag {
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .comm_item {
    margin-right: 16px;
    align-items: center;
  }
  .bx_brand_logo {
    width: 64px;
    height: 64px;
    border-radius: 100px;
  }
  .txt_brand_name {
    width: 64px;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 7px;
    font-size: 12px;
    color: #666;
    letter-spacing: -0.48px;
  }
  .flat_box {
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .list_wrap {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom-width: 1px;
    border-bottom-color: #eee;
    flex-direction: row;
    width: 100%;
  }
  .bx_list_contents {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .bx_list_title {
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  .list_flag_bx {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 6px;
    height: 22px;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .list_flag_txt {
    color: #be69c2;
  }
  .txt_list_title {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    letter-spacing: -0.64px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .txt_list_cont {
    font-size: 14px;
    color: #222;
    line-height: 20.27px;
    letter-spacing: -0.56px;
    margin-top: 4px;
  }
  .bx_list_info {
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
  }
  .txt_info_name {
    padding-right: 8px;
    font-size: 12px;
    color: #999;
    letter-spacing: -0.48px;
  }
  .bx_list_num {
    flex-direction: row;
    padding-left: 4px;
    padding-right: 4px;
  }
  .bx_list_num_item {
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
  }
  .txt_list_num_item {
    font-size: 12px;
    color: #999;
    letter-spacing: -0.48px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .line_vertical {
    width: 1px;
    height: 14px;
    background-color: #eee;
  }
  .list_img_wrap {
    position: relative;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin-left: 8px;
    overflow: hidden;
  }
  .list_img {
    width: 100%;
    height: 100%;
    // resizeMode: contain;
  }
  .bx_pic_num {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(102, 102, 102, 0.8);
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 99;
  }
  .txt_pic_num {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }
  .notice_badge_back {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 34px;
    align-items: center;
    justify-content: center;
    height: 22px;
    border-radius: 4px;
    background-color: #f7e237;
    margin-bottom: -1px;
  }
  .notice_badge_text {
    font-size: 10px;
    font-weight: 700;
  }
  .typo_correction_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .typo_correction_text_wrap {
    padding: 12px 20px;
    width: 100%;
    background-color: #f7f4fa;
    border-radius: 6px;
  }
  .typo_correction_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
    font-weight: 400;
    word-break: break-all;
  }
  .typo_correction_text_color {
    // padding-left: 10px;
    // padding-right: 10px;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
    color: #a760b1;
    font-weight: 700;
    word-break: break-all;
  }
}
