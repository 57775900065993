#MainBanner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f1f4fa;
  // background-image: url('../../icons/kor/bg_tutorial_bubble.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top left;

  #MainBannerCarousel {
    position: relative;
    .dotContainerStyle {
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    .slick-slide {
      & > :first-child {
        display: flex;
      }
    }

    .text_idx_container {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      background-color: rgb(255, 255, 255);
      border-radius: 28px;
      opacity: 0.8;
      padding: 0 10px;
      height: 38px;
      z-index: 10;
      top: 20px;
      right: 20px;
    }

    .text_idx {
      font-size: 12px;
      color: #666666;
      letter-spacing: -0.48px;
      font-weight: 500;
    }

    .dot_indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0 10px;
      position: absolute;
      left: 0;
      bottom: 10px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      & + .dot {
        margin-left: 6px;
      }
    }

    .dot_active {
      background-color: #fff;
    }

    .dot_inactive {
      background-color: #fff;
      opacity: 0.4;
    }

    .slider {
      button {
        font-family: inherit;
      }

      .swiper {
        overflow: visible;
      }
    }

    #MainBannerImage {
      .image_wrapper {
        position: relative;
        width: 100%;
        padding-top: 100%;
        .image {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        margin-top: 8px;
      }

      .title_text {
        white-space: pre-line;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: -0.64px;
        font-weight: 500;
        color: #fff;
        word-break: keep-all;
      }

      .top_img_text_wrap {
        padding: 0 20px;
        position: absolute;
        bottom: 40px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
