#MainTabNavigator {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px -4px 30px 0px #0000000d;

  // ...Platform.select({
  //   ios: {
  //     shadowColor: "#000",
  //     shadowOffset: { width: 0, height: -1 },
  //     shadowOpacity: 0.05,
  //     shadowRadius: 10,
  //   },
  //   android: { elevation: 6 },
  // }),
  .tab_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 7px 0;
  }

  .ios_bottom {
    padding-bottom: 30px;
  }

  .tab_item {
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tab_img {
    display: flex;
    // margin-bottom: 4px;
  }

  .tab_text {
    font-size: 10px;
    text-align: center;
    &_focus {
      color: #672c78;
      font-weight: 700;
    }
    &_unfocused {
      color: #666;
      font-weight: 400;
    }
  }
}
