.comment_block {
  padding: 20px;
  border-bottom: 1px solid rgba(211, 186, 214, 0.2);
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  .comment_block_label {
    font-size: 14px;
    color: rgb(102, 102, 102);
    letter-spacing: -0.56px;
    font-weight: 500;
  }
}