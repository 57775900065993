#tag-main-section {
  .tag-main-sticky-tab {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    width: 100%;
    max-width: 450px;
    z-index: 10;
    height: 65px;
    .tabsContainerStyle {
      padding: unset;
      .tabTextActiveStyle {
        padding: 12px 8px 0;
      }
      .tabTextStyle {
        padding: 12px 8px 0;
      }
    }
    .tabWrapperStyle {
      padding: 10px 0 14px;
      &:first-child {
        .tabTextActiveStyle, .tabTextStyle {
          padding-left: 20px;
        }
      }
    }
  }
}