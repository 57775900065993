.header {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  height: 54px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;

  // position: fixed;
  // top: 0;
  // height: 55px;

  left: 0px;
  left: 0;
  z-index: 999;

  .left {
    width: 78px;
    display: flex;
    flex-direction: row;
  }

  .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;

    .title_txt {
      font-size: 16px;
      color: #222;
      font-weight: bold;
      letter-spacing: -0.64px;
    }
  }

  .right {
    width: 78px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
