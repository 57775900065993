.auth_code_wrap {
  margin-top: 16px;
  padding: 16px;
  background-color: #f5f5f5;
}

.auth_code_top_text {
  margin-bottom: 6px;
  text-align: left;
}

.auth_code {
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 16px 0 16px;
  margin-bottom: 6px;
  border: 1px solid #222;
}

.auth_code_top {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.auth_code_input {
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}

.auth_code_bottom {
  padding-bottom: 16px;
  text-align: left;
}

.auth_code_bottom_text {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
}

.auth_code_alert {
  display: flex;
  align-items: center;
  column-gap: 3px;
}

.auth_code_alert_text {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
}

.timer_text {
  font-size: 16px;
  color: #c00055;
  letter-spacing: -0.64px;
  font-weight: 500;
  margin-right: 12px;
}

.resend {
  margin-left: 3px;
}

.resend_text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.48px;
  text-decoration: underline;
}
