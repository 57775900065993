.setting {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
}
.option_list {
  margin-bottom: 110px;
}
.option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.option_txt {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.option_box {
  padding: 20px 16px;
}
.option_box_border {
  border-bottom: 1px solid #eee;
}
.line {
  height: 6px;
  background-color: #f5f5f5;
}
.logout_txt {
  color: #999;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.64px;
}
.version_info {
  color: #999;
  font-size: 14px;
  letter-spacing: -0.56px;
}

.centered_div {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal_div {
  position: relative;
  width: 100%;
  background-color: white;
  align-items: center;
  //   shadowColor: '#000';
  //   shadowOffset {
  //     width: 0;
  //     height: 2;
  //   }
  //   shadowOpacity: 0.25;
  //   shadowRadius: 4;
  //   elevation: 5;
  overflow: 'hidden';
}
.btn_close {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 9;
}
.modal_header_wrap {
  display: flex;
  height: 32px;
  justify-content: center;
}
.txt_header_title {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.modal_content_wrap {
  width: 100%;
  padding: 16px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.txt_content_title {
  margin: 12px 0px;
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
  font-weight: bold;
  text-align: center;
  white-space: pre-line;
}
.txt_content {
  font-size: 14;
  color: #666;
  letter-spacing: -0.56px;
  text-align: center;
  line-height: 20px;
  white-space: pre-line;
}
.bx_noti_info {
  width: 100%;
  margin: 12px 0px;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 20px 14px;
  align-items: center;
}
.txt_noti_title {
  font-size: 14px;
  color: #666;
  line-height: 24px;
}
.txt_noti_st1 {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.56px;
  line-height: 20px;
  white-space: pre-line;
}
.txt_noti_st2 {
  font-size: 12px;
  color: '#BE69C2';
  line-height: 20px;
}
.bx_cont_li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.txt_dot {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #666;
  margin-top: 6px;
}
.bx_noti_li {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
}
.modal_footer_wrap {
  display: flex;
  flex-direction: row;
}
.btn_footer_default {
  display: flex;
  width: 50%;
  height: 54px;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}
.btn_bg_bk {
  background-color: #222;
}
.txt_btn_footer {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  letter-spacing: -0.64px;
}
.txt_btn_white {
  color: white;
}
