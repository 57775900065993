#bookmark_post_grid {
  aspect-ratio: 1;
  .post_item {
    position: relative;
    &.selected {
      .post_image {
        filter: brightness(0.5);
      }
    }
    .post_image_wrap {
      height: 100%;
      > div {
        height: 100%;
      }
      .post_image {
        width: 100%;
        height: 100%;
        background-color: grey;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
    .icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
