.dotContainerStyle {
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.text_idx_container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 28px;
  opacity: 0.8;
  width: 44px;
  height: 38px;
  z-index: 10;
  top: 20px;
  right: 20px;
}

.text_idx {
  font-size: 12px;
  color: #666666;
  letter-spacing: -0.48px;
  font-weight: 500;
}

.dot_indicator {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.dot_active {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background-color: rgba(34, 34, 34, 0.4);
}

.dot_inactive {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background-color: rgba(34, 34, 34, 0.08);
}
