#FeedPostProsAndCons {
  .pros_and_cons_outer_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pros_and_cons_wrap {
    background: #f8f8f8;
    border-radius: 6%;
    padding: 18px;
    margin-top: 10px;
  }
  .rating_wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }
  .start {
  }

  .pros_and_cons {
    display: flex;
    flex-direction: row;
  }
  .pros {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
  }
  .pros_and_cons_title {
    background-color: #fff;
    border-radius: 70%;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    height: 25px;
  }
  .pros_and_cons_title_text {
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500px;
    font-weight: 500px;
    color: #a760b1;
  }
  .pros_and_cons_text {
    flex: 1px;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }
  .tag_list {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .tag_item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .tag_text {
    font-size: 14px;
    letter-spacing: -0.42px;
    color: #a760b1;
  }
  .post_detail_btn_wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
  .post_detail_btn {
    align-items: center;
    margin-top: 20px;
    border-radius: 4%;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid;
    border-color: #eee;
  }
  .post_detail_btn_text {
    font-size: 16px;
    letter-spacing: -0.56px;
    font-size: 500px;
    color: #222;
  }
  .more {
    position: absolute;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500px;
    right: 5px;
    // Platform.select({
    // .ios { top: 17 }
    // .android { top: 22 }
    // })px;
  }
  .more_text {
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500px;
    text-decoration-line: underline;
    font-weight: bold;
  }
  .contents_text {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
  }
  .contents_text_show_more {
    height: 44px;
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
  }
  .more_button {
    // position: absolute;
    // bottom: Platform.OS == ios ? 14 : 7px;
    bottom: 7px;
    right: 20px;
  }
  .on_layout_width {
    padding-left: 10px;
    padding-right: 10px;
  }
  .off_follow_btn {
    width: 74px;
    height: 27px;
    justify-content: center;
    align-items: center;
    margin-right: 1px;
    margin-bottom: 9px;
    padding-top: 3px;
    padding-bottom: 4px;
    border-radius: 4%;
    background-color: #f5f5f5;
  }
  .off_follow_text {
    width: 38px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #999;
  }
  .on_follow_btn {
    display: flex;
    width: 74px;
    height: 27px;
    justify-content: center;
    align-items: center;
    margin-right: 1px;
    margin-bottom: 9px;
    padding-top: 3px;
    padding-bottom: 4px;
    border-radius: 4%;
    background-color: #be69c2;
  }
  .on_follow_text {
    display: flex;
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
  .on_sale_tag_wrap {
    display: flex;
    width: auto;
    background-color: #f0dff2;
    border-radius: 4px;
    align-self: flex-start;
    height: 17px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .on_sale_tag_text {
    color: #be69c2;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 14px;
    padding-left: 1px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }
  .mall_tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .main {
    width: 100%;
    height: 100%;
  }
  .body {
    width: 100%;
    height: 100%;
  }
  .my_wrap {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 12px;
  }
  .my {
    display: flex;
    flex-direction: row;
  }
  .profile_img {
    width: 36px;
    height: 36px;
    margin-top: 4px;
    margin-right: 8px;
    border-radius: 32%;
    background-color: #fff;
  }
  .my_text_wrap {
    padding-top: 12px;
  }
  .my_text_wrap1 {
    display: flex;
    flex-direction: row;
  }

  .mimi_kim {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.56px;
    text-align: left;
    color: #ca57db;
  }
  .mimi_kim_support_text {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #000;
  }
  .myhome_text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #682c78;
  }

  .lk_wrap {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 12px;
    padding-bottom: 16px;
    border-bottom-width: 10px;
    border-color: #f5f5f5;
  }
  .lk_item {
    flex-direction: row;
    width: 25%;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
  }
  .in_item {
    display: flex;
    flex-direction: row;
    height: 44px;
    width: 100%;
    align-items: center;
    padding-left: 10px;
    background-color: #f5f5f5;
    border-radius: 6%;
  }
  .txt_lk_title {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    // line-height: 17.38px;
    letter-spacing: -0.4px;
    color: #222;
  }
  .btn_arrow {
    margin-left: 4px;
    margin-top: -2px;
  }
}
