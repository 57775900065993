.post_item {
  width: 100%;
}

.list_img_wrap {
  position: relative;
  > :first-child {
    padding-top: 100%;
    position: relative;
  }
}

.list_img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.badge_img {
  position: absolute;
  top: 8px;
  right: 8px;
}

.description {
  display: flex;
  flex-direction: column;
}

.des_txt {
  font-size: 14px;
  font-weight: 500;
  color: #222;
  margin-top: 8px;
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.des_id {
  font-size: 12px;
  color: #999;
}
