.magazine_text {
  font-size: 12px;
  font-weight: bold;
  color: white;
}
.title {
  margin-top: 8px;
}
.title_text {
  font-size: 26px;
  letter-spacing: -1.04px;
  font-weight: 500;
  color: white;
  display: block;
  // white-space: pre-line;
  width: 100%; 
  overflow: hidden; 
  display: -webkit-box; 
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; 
  white-space: pre-wrap;
}
.gradient_box {
  width: 60%;
  padding-left: 26px;
  padding-bottom: 34px;
}
.top_img_text_wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.carousel_idx_txt {
  font-size: 10px;
  color: white;
  letter-spacing: -0.4px;
  font-weight: 500;
}
.text_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
.text_banner_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  color: '#682C78';
}
