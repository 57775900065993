.account_ab_list_wrapper {
  padding: 0 16px;
  .ab_list {
    width: 100%;
    flex: 1;
    margin-top: 14px;
  }

  .ab_item {
    padding-bottom: 32px;
    // margin-bottom: 35px;
    border-bottom: 6px solid #f5f5f5;

    .ab_item_header {
      padding: 0 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ab_item_header_left {
        display: flex;
        align-items: center;
        justify-content: center;

        .ab_item_header_text {
          font-size: 12px;
          color: #999;
          letter-spacing: -0.64px;
          font-weight: 500;
        }
      }
    }
  }

  .ab_main_text {
    margin-top: 10px;
    padding: 0 4px;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #222;
  }

  .ab_select_wrap {
    padding: 0 0;
    display: flex;
    margin-top: 10px;
    column-gap: 8px;

    .ab {
      width: 50%;
      // background-color: #ce6a6a;
      position: relative;
      padding-top: 80px;
      // padding-top: calc(50% - 4px);

      & > div:first-child {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }
    }

    .inner {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      row-gap: 10px;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #22222250;
      opacity: 0.75;
      &:not(img) {
        background-color: #666;
      }
      border-radius: 6px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
    }

    .selected {
      background-color: #be69c380;
      &:not(img) {
        background-color: #be69c3;
      }
    }

    .ab_text1 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.64px;
      color: #fff;
      margin-right: 12px;
    }

    .ab_text2 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.64px;
      text-align: left;
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    // .ab {
    //   .select_image {
    //     // width: 44.1vw;
    //     // height: 8vh;
    //     padding: 14px;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     justify-content: center;
    //     border-radius: 8px;
    //   }
    // }

    // .ab_text1 {
    //   font-size: 18px;
    //   font-weight: bold;
    //   letter-spacing: -0.72;
    //   color: #fff;
    //   margin-right: 10px;
    // }

    // .ab_text2 {
    //   // width: 31.5vw;
    //   font-size: 14px;
    //   font-weight: 500;
    //   letter-spacing: -0.56;
    //   color: #fff;
    // }
  }

  .ab_item_header_btn {
    padding: 4px 8px;
    width: fit-content;
    border-radius: 4px;

    &_text {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: -0.64px;
    }
  }

  .progress_btn {
    border: 1px solid #be69c3;

    &_text {
      color: #be69c3;
    }
  }

  .terminated_btn {
    background-color: #c4c4c4;

    &_text {
      color: #fff;
    }
  }
}
