$width-item: 175px;

.popular_post_description {
  flex: 1;
}

.popular_post_list .slick-track {
  .slick-slide {
    padding-left: 8px;
    &:nth-child(1) {
      padding-left: 16px;
    }
  }
}

.popular_post_first_item {
  // margin-left: 16px;
  // margin-right: 8px;
  display: inline-block;
  width: $width-item;
}

.popular_post_item {
  // margin-right: 8px;
  display: inline-block;
  width: $width-item;
}
.popular_post_img_wrapper {
  position: relative;
}

.popular_post_img {
  border-radius: 6px;
  width: $width-item;
  height: $width-item;
  object-fit: cover;
}

.badge_img {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.popular_post_text1 {
  font-size: 14px;
  color: #222;
  letter-spacing: -0.56px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 3px;
  white-space: normal;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  // ...Platform.select({
  //   ios: { ...font_weight_500 },
  //   android: { ...font_weight_400 },
  // }),
}

.popular_post_text2 {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
}
