#TodayProduct {
  $width-item: 175px;
  .slick-track {
    .slick-slide {
      padding-left: 8px;
      &:nth-child(1) {
        padding-left: 16px;
      }
    }
  }

  .line_height_10 {
    height: 10px;
    background-color: #f5f5f5;
  }
  .bx_title {
    display: flex;
    padding: 0 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .search {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt_search_title {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: 500;
    padding-right: 10px;
  }
  .txt_title {
    font-size: 18px;
    font-weight: 500;
    color: #222;
    letter-spacing: -0.72px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f7e237;
      height: 8px;
      z-index: -1;
    }
  }
  .bx_title_more {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .txt_title_more {
    color: #999;
    font-size: 14px;
    letter-spacing: -0.56px;
    margin-right: 2px;
  }
  .flat_box {
    margin-top: 16px;
  }
  .flat_box_search {
    margin-top: 16;
    padding-left: 10px;
  }
  .item_list {
    padding-top: 16px;
    margin-bottom: 20px;
  }
  .bx_item_wrap {
    position: relative;
    width: $width-item !important;
  }
  .bx_img {
    width: $width-item;
    height: $width-item;
    position: relative;
    background-color: #fff;
    border: 1px solid;
    border-color: rgba(34, 34, 34, 0.1);
    border-radius: 4px;
  }
  .in_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    border-radius: 4px;
  }
  .bx_info {
    padding-top: 8px;
  }
  .info_pd_manufacturer {
    font-size: 11px;
    color: #222;
    font-weight: 700;
    margin-bottom: 2px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info_pd_name {
    font-size: 14px;
    line-height: 20px;
    color: #424242;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  .bx_btn_mall {
    display: flex;
    width: fit-content;
    background-color: #f0dff2;
    border-radius: 4px;
    align-self: flex-start;
    height: 17px;
    margin-top: 8px;
    margin-bottom: 2px;
    align-items: center;
    justify-content: center;
  }
  .txt_btn_mall {
    color: #be69c2;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.64px;
    line-height: 14px;
    padding-left: 1px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .mall_tag {
    padding-left: 6px;
  }

}
