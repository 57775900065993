#related-product-list-wrapper {
  min-width: 0;
  min-height: 0;
  background-color: #fff;

  .title-header-wrapper {
    padding: 20px 20px 0;

    .modal_body_title {
      margin-bottom: 40px;

      .modal_tag_text {
        color: #a760b1;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.64px;
      }

      .modal_body_text {
        color: #222222;
        font-size: 20px;
        letter-spacing: -0.64px;
        font-weight: bold;
      }
    }
  }

  .related_products_wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 8px;
  }

  .related-product-item {
    width: calc(50% - 4px);
    margin-left: 0;

    .relation_product_image_wrapper {
      width: 100%;
      position: relative;
      padding-top: 100%;
    }

    .relation_product_image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid #0000001a;
      position: absolute;
      top: 0;
      object-fit: cover;
    }

    .relation_product_item_text1 {
      margin-top: 8px;
      padding: 0 4px;
      font-size: 14px;
      color: #222222;
      letter-spacing: -0.56px;
      font-weight: 500;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .relation_product_info {
      display: flex;
      padding: 0 4px;
      align-items: baseline;
    }

    .relation_product_item_text2 {
      font-size: 12px;
      color: #999999;
      letter-spacing: -0.48px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;

      &.left {
        max-width: calc(50% - 6px);
      }

      &.right {
        flex: 1;
      }

      &.mode_name {
        flex: 1;
      }

      &.brand_name {
        max-width: calc(50% - 6px);
      }
    }

    .bar {
      height: 10px;
      width: 1px;
      background-color: #999999;
      margin-right: 6px;
      margin-left: 6px;
      margin-top: unset;
    }
  }

  .on_sale_tag_wrap {
    margin-top: 4px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    padding: 0 6px;
    width: fit-content;
    background-color: #f0dff2;
    border-radius: 4px;

    .on_sale_tag_text {
      color: #be69c3;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: -0.4px;
      line-height: 14px;
      padding-left: 1px;
    }
  }
}