#bookmark_main {
  .header_tab {
    display: flex;
    flex-direction: row;
    height: 56px;
    border-bottom: 1px solid #eee;
    padding-left: 16px;
    position: sticky;
    top: 60px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
  }
  .tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 5px 10px;
    margin-right: 30px;
    border-bottom-width: 2px;
    border-bottom: solid;
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;
    border-color: transparent;
    &.active {
      font-weight: 700;
      border-color: #222;
    }
  }
  .footer_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 26.06px;
    text-align: center;
    padding: 16px;
    letter-spacing: -0.04em;
    background-color: #222;
    color: #fff;
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 450px;
  }
}
