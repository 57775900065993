#QnaMain {
  margin-bottom: 60px;

  .contents {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .qna_home_title_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px 0;

      .qna_home_title_text {
        font-size: 32px;
        letter-spacing: -1.28px;
        font-weight: bold;
        color: #222;
        margin: 0;
      }

      .qna_home_header_ab_list_btn {
        display: flex;
        flex-direction: row;
        align-items: center;

        .qna_home_header_ab_list_btn_text {
          font-size: 16px;
          letter-spacing: -0.64px;
          font-weight: 500;
          font-weight: bold;
          color: #666666;
          margin: 0;
        }
      }
    }

    .qna_home_header_info_text {
      padding: 0 16px;
      margin: 0;
      font-size: 14px;
      color: #666666;
      letter-spacing: -0.56px;
      font-weight: 500;
    }
  }

  .interesting_question {
    margin-top: 22px;
    background-color: #f7f4fa;

    .interesting_question_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px;

      .interesting_question_header_title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.72px;
        color: #222;
        margin: 16px 0;
      }
    }

    .interesting_question_list {
    }
  }

  .recent_qna_wrapper {
    margin-top: 32px;
  }

  .wrap {
    margin-top: 32px;
    &.ab {
      .horizontal_list_title {
        .title_background {
          width: 88px;
        }
      }
    }
    .horizontal_list_title {
      position: relative;
      margin-left: 20px;
      .title_background {
        position: absolute;
        left: -3px;
        bottom: 0;
        background-color: #f7e237;
        height: 14px;
        width: 80px;
        border-radius: 7px;
        z-index: 1;
      }
      .title {
        position: relative;
        z-index: 2;
        font-size: 18px;
        letter-spacing: -0.72px;
        font-weight: 500;
        color: #222;
      }
    }

    .qna_list {
      width: 100%;
      padding: 0 20px;
      margin-top: 16px;
      white-space: nowrap;
      overflow-x: scroll;
    }

    .popular_qna_list {
      margin-top: 16px;

      .slider-container{
        padding-left: 16px;
      }
    }

    .btn_wrap {
      margin-top: 20px;
      padding-left: 16px;
      padding-right: 16px;
      &.last {
        margin-bottom: 32px;
      }

      .all_view_btn {
        border: 1px solid #eee;
        width: 100%;
        height: 53px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        .all_view_btn_text {
          font-size: 16px;
          letter-spacing: -0.64px;
          font-weight: bold;
          color: #222;
        }
      }
    }
  }
}
