.notice {
  background-color: white;
}

.scroll {
  margin: 0 16px;
}

.top_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.txt_date {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
}
.icon {
  flex-basis: 24px;
}
.option_txt {
  flex: 1;
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.option_box {
  padding: 24px 0 24px;
}

.option_box_border {
  border-bottom: 1px solid #eee;
}
.option {
  display: inline-block;
  width: 100%;
  text-align: left;

}
.logout_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #999;
  font-weight: 500;
}
