.container {
  position: relative;
  padding: 16px 20px;
  display: flex;
  &::before {
    content: "";
    height: 1px;
    background-color: #eee;
    position: absolute;
    bottom: 0;
    left: 16px;
    width: calc(100% - 32px);
  }

  &.background_fff {
    background-color: #fff;
  }
  &.background_fff6ff {
    background-color: #fff6ff;
  }
}

.alarm {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.alarm_list {
  background-color: #fff;
}

.user_img {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.alarm_item_1,
.alarm_item_2 {
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 19px;
  flex-direction: row;
  border-bottom-width: 1px;
  border-color: #eee;
}

.alarm_item_1 {
  background-color: #fff;
}

.alarm_item_2 {
  background-color: #fff6ff;
}

.alarm_info {
  margin-left: 10px;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.alarm_text_1 {
  font-size: 14px;
  color: #222;
  letter-spacing: -0.56px;
  font-weight: 500;
  margin-bottom: 6px;
  white-space: pre-wrap;
}

.alarm_text_2 {
  color: #be69c3;
}

.alarm_text_3 {
  font-size: 12px;
  color: #999;
}

.btn {
  border-radius: 300px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 20px;
  text-align: center;
}

.btn_text {
  font-size: 14px;
}

.touchable_with_async_task {
  padding: 8px 20px;
}
.touchable_with_async_task_2 {
  border: 1px solid #be69c3;
  border-radius: 300px;
  padding: 8px 20px;
}

.follow_btn {
  &.big {
    width: 87%;
    height: 7%;
    border-radius: 100px;
  }

  &.big_1 {
    height: 52px;
    border-radius: 56px;
  }

  &.border_radius_100 {
    border-radius: 100px;
  }

  &.border_radius_5 {
    border-radius: 5px;
  }

  &.border_radius_56 {
    border-radius: 56px;
  }

  &.border_width_default {
    border-width: 1px;
  }

  &.btn_h_28 {
    height: 28px;
  }

  &.btn_h_39 {
    height: 39px;
  }

  &.middle_0 {
    border-width: 1px;
    border-radius: 100px;
    border-color: #d8b8d8;
    width: 33%;
    height: 39px;
  }

  &.small_0 {
    border-width: 1px;
    border-radius: 5px;
    border-color: #f0dff2;
    width: 10%;
    height: 28px;
  }

  border-width: 1px;
  border-color: #be69c3;
}

.follow_btn_text {
  color: #be69c3;
}

.following_btn {
  &.big {
    width: 87%;
    height: 7%;
    border-radius: 100px;
  }

  &.big_1 {
    height: 52px;
    border-radius: 56px;
  }

  &.border_radius_100 {
    border-radius: 100px;
  }

  &.border_radius_5 {
    border-radius: 5px;
  }

  &.border_radius_56 {
    border-radius: 56px;
  }

  &.border_width_default {
    border-width: 1px;
  }

  &.btn_h_28 {
    height: 28px;
  }

  &.btn_h_39 {
    height: 39px;
  }

  &.middle_0 {
    border-width: 1px;
    border-radius: 100px;
    border-color: #d8b8d8;
    width: 33%;
    height: 39px;
  }

  &.small_0 {
    border-width: 1px;
    border-radius: 5px;
    border-color: #f0dff2;
    width: 10%;
    height: 28px;
  }

  background-color: #be69c3;
}

.following_btn_text {
  color: #fff;
}
