.bx_filter_wrap {
  position: fixed;
  top: 60px;
  background-color: #fff;
  max-width: 450px;
  width: 100%;
  z-index: 10;
}

.bx_folded_bg {
  width: 100%;
  position: relative;
  align-items: center;
  border-top: 1px solid #6c2773;
}

.bx_gradient {
  position: absolute;
  top: 0;
  height: 6px;
  width: 100%;
}

.btn_folded {
  position: absolute;
  top: -1px;
  width: 44px;
  height: 12px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  align-items: center;
  background-color: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #6c2773;
  border-style: solid;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  svg {
    margin-bottom: 5px;
  }
}

.bx_select_wrap {
  display: flex;
  white-space: nowrap;
  column-gap: 6px;
  padding: 8px 16px;
  overflow-x: scroll;
  -webkit-user-select: none;
  user-select: none;
  flex: 1;
}

.bx_filter_title {
  display: flex;
  padding: 14px 16px 10px 16px;
  flex-direction: row;
  align-items: center;
}

.txt_filter_title {
  font-size: 16px;
  color: #222;
  font-weight: 500;
  margin-left: 8px;
}

.bx_filter_select {
  height: 48px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.bx_filter_label {
  padding-left: 16px;
  padding-right: 10px;
  min-width: 90px;
  color: #222;
  font-weight: 700;
  letter-spacing: -0.4px;
}

.btn_toggle {
  margin-right: 20px;
  align-items: center;
  display: flex;
}

.txt_toggle {
  color: #222;
  font-weight: 400;
}

.txt_toggle_on {
  color: #BE69C2;
}

.bx_select_list {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  border-color: #eee;
}

.bx_filter_list {
  height: 100%;
  width: 100%;
  padding-left: 16px;
}

.btn_select_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px 5px 14px;
  margin-right: 10px;
  background-color: #F7F4FA;
  border-radius: 100px;
}

.txt_select_list {
  color: #BE69C2;
  font-weight: bold;
}

.icon_find_filter {
  margin-left: 16px;
}

.bx_refresh {
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  display: flex;
  cursor: pointer;
}
