.ab_all_view {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .go_write_btn_wrap {
    width: calc(100% - 32px);
    max-width: 420px;
    height: 53px;
    border-radius: 6px;
    margin: 20px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: rgba(255, 255, 255, 0.2);
    border-width: 1px;
    border-color: #be69c3;
    border-style: solid;

    .go_write_btn_wrap_txt1 {
      font-size: 16px;
      letter-spacing: -0.64px;
      font-weight: 500;
      color: #be69c3;
      font-weight: 500;
    }
    .go_write_btn_wrap_txt_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .go_write_btn_wrap_txt2 {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.48px;
        color: #be69c3;
        font-weight: 500;
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .tab_active_btn {
    height: 55px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-color: #222;

    .tab_active_btn_text {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.64px;
      color: #222;
    }
  }
  .tab_btn {
    height: 55px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-color: #eee;

    .tab_text {
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.64px;
      color: #999;
    }
  }
}
