#QnaList,
#QnaListScreen {
  .qna_list {
    width: 100%;
    padding: 0 16px;
    > div {
      position: unset !important;
    }
    overflow-y: scroll;

    .qna_item {
      display: flex;
      width: 100%;
      margin: 20px 0 0;
      border-bottom: 1px solid #eee;
      .qna_wrap {
        margin-left: 10px;
        width: 100%;

        .qna_top {
          display: flex;
          justify-content: space-between;

          .qna {
            text-align: left;

            .qna_item_text1 {
              margin: 0;
              color: #222222;
              font-weight: 500;
              font-size: 16px;
              letter-spacing: -0.64px;
            }

            .qna_item_text2 {
              margin-top: 6px;
              color: #666;
            }
          }

          .qna_image {
            margin-left: 10px;
            width: 72px;
            height: 72px;
          }
        }

        .qna_info_wrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 16px;
          margin-bottom: 20px;

          .qna_item_bar {
            width: 1px;
            height: 14px;
            margin: 0 8px;
            background-color: #eee;
          }

          .qna_info {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .qna_item_text3 {
              font-size: 12px;
              color: #222222;
              font-weight: 500;
              letter-spacing: -0.48px;
              margin: 0;
            }

            .qna_comment {
              display: flex;
              align-items: center;

              p {
                margin: 0 0 0 2px;
                color: #be69c3;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: -0.48px;
              }
            }

            .like {
              display: flex;
              align-items: center;

              p {
                margin: 0 0 0 2px;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: -0.48px;
                color: #999;
              }
            }

            .qna_item_text5 {
              font-size: 12px;
              font-weight: 400;
              letter-spacing: -0.48px;
              color: #999;
              margin: 0;
            }
          }

          .reply_btn_text {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.48px;
            color: #be69c3;
            margin: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
