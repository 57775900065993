.modal_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1;
  background-color: #fff;
  overflow: auto;
}

.web_view {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.top_btn {
  position: fixed;
  right: 24px;
  bottom: 5%;
}
.header_wrapper {
  position: fixed;
  max-width: 450px;
  width: 100%;
}