.empty-follower-wrapper {
  position: relative;

  .empty-text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .empty-text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    text-align: center;
    color: #666;
  }
}