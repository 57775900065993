#bookmark_qna_grid {
  .qna_item_container {
    height: 100%;
    position: relative;
    .qna_item {
      .qna_image {
        border-radius: 8px;
        filter: brightness(0.5);
        object-fit: cover;
      }
    }
    .qna_light_purple_wrap,
    .qna_purple_wrap {
      background-color: #672c78;
    }
    .qna_light_purple_wrap,
    .qna_purple_wrap,
    .qna_wrap {
      border-radius: 8px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .check_btn {
        position: absolute;
        right: 14px;
        top: 14px;
      }
      .qna_top {
        padding: 20px;
        color: #fff;
        text-align: left;
        .qna_top_text {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.48px;
        }
        .title_wrap {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.64px;
          margin-top: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
      .qna_bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 20px;
        text-align: right;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.56px;
      }
    }
  }
}
