#tag-post-component {
  padding-top: 10px;
  .tag-post-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    width: 100%;

    .tag-post-list-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3px;

      .data_item_wrap_left.data_item_6 {
        grid-column: 1/3;
        grid-row: 2/4;
      }

      .data_item_wrap_right.data_item_5.group_full {
        grid-column: 2/3;
        grid-row: 2/3;
      }

      .data_item_wrap_right.data_item_6 {
        grid-column: 2/4;
        grid-row: 2/4;
      }

      .data_item_wrap_right.data_item_5.group_full {
        grid-column: 1/2;
        grid-row: 2/3;
      }

      .data_item_wrap {
        position: relative;

        .image_wrap_inner {
          z-index: 0;
          position: relative;
          width: 100%;
          padding-top: 100%;

          img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .image_icon {
          display: flex;
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }
  }
}