.bx_flat_wrap {
  display: flex;
  white-space: nowrap;
  column-gap: 6px;
  padding: 8px 16px;
  overflow-x: scroll;
  position: sticky;
  top: 60px;
  background-color: #fff;
  z-index: 10;
  user-select: none;
}

.btn_item_wrap {
  height: 34px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 300px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  cursor: pointer;
}

.btn_active {
  border: 1px solid #BE69C2;
  background-color: #BE69C2;
}

.btn_item_text {
  font-size: 14px;
  color: #222;
}

.btn_text_active {
  color: #fff;
  font-weight: 700;
}

// export const styles = StyleSheet.create<Styles>({
//     bx_flat_wrap: {
//       paddingVertical: 10,
//       backgroundColor: '#fff',
//     },
//     sticky_shadow: {
//       borderBottomWidth: 1,
//       borderColor: 'rgba(0,0,0,0.1)',
//       ...Platform.select({
//         ios: {
//           shadowColor: 'rgb(0,0,0)',
//           shadowOpacity: 0.1,
//           shadowRadius: 10,
//           shadowOffset: {
//             height: 2,
//             width: 0,
//           },
//         },
//         android: {
//           elevation: 3,
//         },
//       }),
//     },
//     btn_item_wrap: {
//       height: 34,
//       paddingHorizontal: 20,
//       alignItems: 'center',
//       justifyContent: 'center',
//       borderRadius: 300,
//       borderWidth: 1,
//       borderColor: '#d9d9d9',
//       backgroundColor: '#fff',
//       marginRight: 6,
//     },
//     btn_active: {
//       borderColor: '#BE69C2',
//       backgroundColor: '#BE69C2',
//     },
//     btn_item_text: {
//       fontSize: 14,
//       color: '#222',
//     },
//     btn_text_active: {
//       color: '#fff',
//       fontWeight: '700',
//     },
//   })
