.info_add_agree {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding-right: 19px;
  padding-left: 19px;
  justify-content: space-between;
}

.email_box_wrap {
  height: 58px;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 20px;
}

.email_box {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.all_ck_box {
  margin-bottom: 16px;
  background-color: #f5f5f5;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 10px;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.all_ck {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all_ck_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #222;
}

.ck_txt_mm {
  padding-left: 8px;
  font-weight: 500;
}

.ck_txt {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  font-weight: 500;
}

.required {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #6c2773;
}

.ck_box {
  padding-left: 14px;
  padding-right: 14px;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.ck_touch {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  min-height: 0;
  align-items: center;
}

.ck_touch_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.view_btn {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 4px;
  border-radius: 5px;
}

.view_btn_txt {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #fff;
  padding-right: 5px;
}

.options {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #666666;
}

.bm_spacing {
  margin-top: 10px;
}

.sms_ck {
  margin-left: 24px;
}

.email_ck {
  margin-left: 4px;
}

.join_btn {
  align-items: center;
  justify-content: center;
  height: 52px;
  border-radius: 56px;
  background-color: #eeeeee;
  border-width: 1px;
  border-color: #d9d9d9;
  border-style: solid;
}

.join_btn_txt {
  color: #999;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.btn_wrap {
  /* Any additional CSS styles for btn_wrap would go here */
}
