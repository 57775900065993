#AddModalItem {
  width: 100%;

  .item_wrap {
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-bottom: 1px solid rgba(97, 17, 104, 0.12);
  }

  .last_item_wrap {
    border-bottom: unset;
  }

  .item {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .action_text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: #222;
    margin-bottom: 2px;
  }

  .info_text {
    font-size: 14px;
    color: #999;
    letter-spacing: -0.64px;
  }

  .write_icon {
    margin-right: 8px;
    margin-left: 10px;
  }
}