.tag-ab-component {
  padding: 10px 16px 0;

  .tag_ab {
    padding-top: 10px;
    background-color: rgb(255, 255, 255);
  }

  .empty_image {
    width: 100%;
  }

  .empty_alert {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }

  .empty_text {
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.64px;
    font-weight: 500;
  }

  .empty_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    border-radius: 300px;
    border-color: #a760b1;
    border-width: 1px;
    width: 157px;
    height: 44px;
  }

  .empty_btn_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    font-weight: 500;
    color: #a760b1;
  }

  .ab_item {
    padding-bottom: 32px;
    margin-bottom: 35px;
    border-bottom: 6px solid #f5f5f5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .last_item {
    border-bottom-width: 0;
  }

  .ab_item_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ab_item_header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ab_item_header_text {
    font-size: 12px;
    color: #999;
    letter-spacing: -0.48px;
    font-weight: 500;
  }

  .text_bar {
    width: 1px;
    height: 12px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #eeeeee;
  }

  .ab_item_header_btn {
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 22px;
  }

  .progress_btn {
    background-color: #f7e237;
  }

  .progress_btn_text {
    color: #222;
  }

  .terminated_btn {
    background-color: #c4c4c4;
  }

  .terminated_btn_text {
    color: #fff;
  }

  .ab_item_header_btn_text {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: -0.4px;
  }

  .ab_main_text {
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #222;
  }

  .ab_select_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    column-gap: 3px;
  }

  .ab {
    position: relative;
    width: 50%;
  }

  .select_image_wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }

  .select_image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }

  .inner_wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #21212180;

    &.left {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &.right {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .inner {
    &.left {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &.right {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .ab_text1 {
    font-size: 32px;
    letter-spacing: -1.28px;
    color: #fff;
    font-weight: bold;
  }

  .ab_text2 {
    font-size: 14px;
    letter-spacing: -0.56px;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
    padding: 0 10px;

    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .selected {
    background-color: #a860b180;
  }

  .not_selected {
    background-color: #666;
  }

  .select_right_inner {
    background-color: rgba(34, 34, 34, 0.4);
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .select_left_inner {
    background-color: rgba(34, 34, 34, 0.4);
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  .select_left_text_btn {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  .select_right_text_btn {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}
