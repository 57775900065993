.date_wrap {
  height: 25px;
  padding: 0px 6px;
  border-radius: 4px;
  background-color: #a760b1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expire {
  @extend .date_wrap;
  background-color: #999999;
}
.date_wrap_text {
  font-size: 12px;
  letter-spacing: -0.48;
  font-weight: bold;
  color: #fff;
  margin: 0;
}
