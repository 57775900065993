.wrap {
  display: flex;
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.image_stage_wrap {
}

.image_stage {
  margin: 50px auto 0;
  max-width: 100%;
  width: fit-content;
}

.filter_list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 20px;
}

.filter_item_wrap {
  display: inline-flex;
  flex-direction: column;
  margin-right: 16px;
  cursor: pointer;

  &:first-child {
    margin-left: 16px;
  }
}

.filter_title {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  color: #222;
  user-select: none;
}

.adjust_list {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 20px;
}

.adjust_item {
  &:first-child {
    margin-left: 16px;
  }

  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.adjust_item_text {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  user-select: none;
}

.adjust_panel_wrap {
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.adjust_slider_wrap {
  margin: 0 30px 90px;
}

.slider_root {
  color: #999 !important;
  border-radius: 100px !important;
}

.slider_rail {
  opacity: unset !important;
}

.slider_thumb {
  width: 24px !important;
  height: 24px !important;
  background-color: #fff !important;
  box-shadow: 0px 2px 4px 1px #00000040 !important;

  &:hover,
  &:active,
  &:visited {
    box-shadow: 0px 2px 4px 1px #00000040 !important;
  }
}

.slider_mark {
  display: none;
  &[data-index='1'] {
    display: block !important;
    transform: translate(-3px, 5px) !important;
    background-color: #999 !important;
    width: 6px !important;
    height: 6px !important;
    border-radius: 100px !important;
  }
}

.slider_mark_label {
  color: #666 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: -0.04em !important;
  text-align: center !important;
  font-family: 'Noto Sans KR' !important;
  position: static !important;
  transform: translateY(10px) !important;
  display: inline-block !important;
  width: 50%;
  &[data-index='1'] {
    display: none !important;
  }
  &[data-index='2'] {
    text-align: right !important;
  }
  &[data-index='0'] {
    text-align: left !important;
  }
}

.slider_mark_without_label {
  &[data-index='1'] {
    display: none !important;
  }
}

.adjust_panel_footer {
  display: flex;
  flex-direction: row;
}

.adjust_btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.cancel_btn {
  background-color: #eee;
  color: #222;
}

.apply_btn {
  background-color: #222;
  color: #fff;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  padding-top: 20px;
  background-color: #fff;
}

.btn_mode_wrap {
  display: flex;
  padding: 12px 0;
  box-shadow: 0px -6px 8px 0px #00000012;
}

.btn_mode {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  text-align: center;
  color: #999;
}

.active_mode {
  color: #222;
}
