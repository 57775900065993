
#ab_list_view {
  .bar {
    height: 6px;
    background-color: #f5f5f5;
    margin: 40px 0 14px;
  }
  .nothing_search_img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  
    .empty_wrap {
      position: "absolute";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
  
      .empty_text {
        font-size: 16px;
        color: #666666;
        letter-spacing: -0.64px;
        font-weight: 500;
      }
    }
  }
  .ab_horizontal_list_title {
    margin-left: 20px;
    margin-top: 20px;
    div {
      position: relative;
      align-self: baseline;
      .ab_horizontal_list_title_text {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.72px;
        color: #000;
        position: relative;
        z-index: 2;
      }
    }
  }
}
