.sheet_container {
  display: flex !important;
  flex-direction: column;
  height: 390px;
}

.sheet_title_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 60px;
}

.back_btn {
  min-width: 32px;

  .back_btn_icon {
    display: flex;
  }
}

.sheet_title {
  flex: 1;
  text-align: center;
  padding: 0 8px;
}

.sheet_title_txt {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.64px;
}

.text_max_cnt {
  font-size: 16px;
  color: #999;
}

.btn_text_reset {
  font-size: 14px;
  font-weight: 600;
  color: #222;
}

.close_btn {
  .close_btn_icon {
    display: flex;
  }
}

.sheet_contents1 {
  padding: 0 16px;
  overflow-y: auto;
}

.contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.contents_item1 {
  display: flex;
  flex-direction: row;
}

.contents_text {
  font-size: 16px;
  color: #222;
  font-weight: 400;
}

.contents_item2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .contents_text {
    font-weight: 700;
    color: #BE69C3;
    margin-right: 10px;
  }
}

.next_btn {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  height: 56px;
  text-align: center;
}

.button_reset {
  background-color: #eeeeee;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-color: #666666;
  height: 56px;
  display: flex;
}

.button_reset_text {
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}

.button_save {
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2.5;
}

.button_save_text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.sheet_contents2 {
  overflow-y: auto;
  flex: 1;
}

.sheet_contents2_wrapper {
  overflow-y: auto;
  flex: 1;
}

.sort_item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort_active_text {
  font-size: 16px;
  font-weight: 700;
  color: #BE69C3;

}

.sort_text {
  font-size: 16px;
  font-weight: 400;
  color: #222;

}
