.login {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  
  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  max-width: 450px;
  width: 100%;
  z-index: 100;
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding-top: 50px;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  margin-bottom: 64px;
}

.title_text {
  font-size: 28px;
  letter-spacing: -1.12px;
  color: #222;
  font-weight: bold;
}

.input_box {
  margin-bottom: 78px;
}

.input_wrap {
  display: flex;
  flex-direction: row;
  background-color: #fafafa;
  position: relative;
  height: 61px;
  border-radius: 6px;
  padding-left: 20px;
}

.email_input_wrap {
  margin-bottom: 24px;
}

.auto_complete_list {
  background-color: #fff;
  border-color: #eee;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 14px;
  padding-left: 14px;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 1000;
  border-width: 1px;
  border-style: solid;
  box-shadow: 4px 4px 8px 0px #00000026;
}

.auto_complete_item {
  justify-content: center;
  border-color: #eee;
  height: 34px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.auto_complete_last_item {
  border-bottom-width: 0;
}

.auto_complete_text {
  font-size: 13px;
  letter-spacing: -0.52px;
  color: #222;
  line-height: 34px;
}

.input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  background-color: transparent;
}

.pw_input_wrap {
  padding-right: 8px;
  align-items: center;
}

.btn_wrap {
  display: flex;
  flex-direction: row;
}

.btn_1 {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  min-height: 0;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  margin-bottom: 32px;
  margin-left: 14px;
  margin-right: 14px;
  height: 60px;
  border-radius: 56px;
}

.btn_1_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
}

.txt_active {
  color: #fff;
}

.txt_in_active {
  color: #999;
}

.in_active {
  background-color: #eeeeee;
  border-color: #d9d9d9;
}

.active {
  background-color: #be69c3;
  border-color: #be69c3;
}

.etc_area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.etc_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
}

.etc_area_line {
  width: 1px;
  height: 12px;
  background-color: #c4c4c4;
  margin-right: 13px;
  margin-left: 13px;
}

.sns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;
}

.sns_img {
  margin-right: 10px;
}

.alert_wrap {
  margin-top: 6px;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.not_authorized {
  font-size: 12px;
  letter-spacing: -0.24px;
  color: #ea174a;
  font-weight: 500;
}

.input_title {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
  margin-bottom: 10px;
}
