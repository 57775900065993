.interesting_question {
  .slick-dots {
    max-width: 450px;
    bottom: 10px;
    .slick-active button:before {
      color: #be69c3;
    }
    li {
      button:before {
        font-size: 7px;
        color: #999;
      }
    }
  }
  .interesting_question_card {
    background-color: #fff;
    padding: 20px;
    margin: 0 18px 40px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 156px;
    .interesting_question_card_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .answer_btn {
        border-radius: 4px;
        background: #222;
        display: inline-flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        .answer_btn_text {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          letter-spacing: -0.56px;
        }
      }
      .interesting_question_card_header_left {
        display: flex;
        .profile_image {
          width: 24px;
          height: 24px;
        }
        .interesting_question_card_info {
          display: flex;
          align-items: center;
          margin-left: 6px;
          .interesting_question_card_text1 {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.48px;
            color: #222;
          }
          .text_bar {
            margin: 0 6px;
            background-color: #eee;
            height: 12px;
            width: 1px;
          }
          .interesting_question_card_text2 {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.48px;
            color: #999;
          }
        }
      }
    }
    .interesting_question_card_content_wrapper {
      width: 100%;
      .interesting_question_card_content {
        display: flex;
        margin-top: 6px;
        .interesting_question_card_content_right {
          text-align: left;
          margin-left: 6px;
          width: calc(100% - 60px);
          .interesting_question_card_text2 {
            color: #222;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.64px;
            margin: 0 0 6px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .interesting_question_card_text3 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            color: #222;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.56px;
            margin: 0;
          }
        }
      }
    }
  }
}
