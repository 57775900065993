#LikeScreen {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10000;
  max-width: 450px;
  background-color: white;
}
