.close_btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.confirm_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
}
.confirm_btn_disable {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}
.confirm_btn_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: white;
}
.confirm_btn_disable_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #666666;
}
