.sort_btn_wrap {
  padding: 26px 20px 0px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(0deg, white 90%, rgba(255, 255, 255, 0) 100%);

  .sort_btn {
    width: 78px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-right: 6px;
    border: 1px solid #d9d9d9;

    .sort_btn_text {
      font-size: 14px;
      letter-spacing: -0.42px;
      color: #222;
    }
  }

  .sort_active_btn {
    @extend .sort_btn;
    background-color: #222;
    border: solid;
    border-color: #222;
    border-width: 1px;
    margin-right: 6px;

    .sort_btn_active_text {
      @extend .sort_btn_text;
      color: #fff;
    }
  }
}
