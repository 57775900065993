.mall_recommend_wrap {
  background-color: #f5f5f5;
  padding: 20px 0;
  .bx_title {
    padding: 0 16px;
    position: relative;
    width: fit-content;
  }

  .bg_title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7e237;
    height: 8px;
    z-index: -1;
  }

  .txt_title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #222;
  }

  .txt_title_bold {
    font-weight: 700;
  }

  .btn_bookmark {
    position: absolute;
    right: calc(8px + 8px);
    top: 8px;
    z-index: 3;
  }

  .bx_item_wrap {
    margin-right: 8px;
    position: relative;
    .bx_img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid #2222221a;
      filter: brightness(0.8);
    }
  }

  .slider-wrapper {
    margin-top: 16px;

    .slick-list {
      padding-left: 16px;
    }
  }

  .bx_info {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    .bg_info_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      min-width: 0;
      padding: 12px 16px;
    }
    .info_brand_name {
      font-size: 11px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 2px;
    }
    .info_pd_name {
      font-size: 14px;
      font-weight: 400;
      color: #fff;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bx_info_price {
      display: flex;
      align-items: center;
      column-gap: 4px;
      margin-top: 4px;
    }
    .bx_sale_per {
      margin-right: 2px;
    }
    .txt_percent {
      font-size: 16px;
      font-weight: 500;
      color: #f7e237;
    }
    .txt_price_unit {
      font-size: 14px;
      font-weight: 500;
    }
    .txt_info_price {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }
  }
  .bg_img_blur {
    position: absolute;
    left: 0;
    right: 8px;
    bottom: 0;
    height: 40%;
    border-radius: 4px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 2.21%,
      rgba(34, 34, 34, 0.6) 100%
    );
  }
}
// export const styles = StyleSheet.create<Styles>({
//     mall_recommend_wrap: {
//       backgroundColor: '#f5f5f5',
//     },
//     bx_title: {
//       paddingHorizontal: 16,
//       flexWrap: 'wrap',
//       marginTop: 30,
//     },
//     txt_title: {
//       ...Texts.font_size_18,
//       ...Texts.font_weight_500,
//       ...Colors.font_222,
//     },
//     txt_title_bold: {
//       fontWeight: '700',
//     },
//     bg_title: {
//       position: 'absolute',
//       left: -2,
//       right: -2,
//       bottom: 0,
//       backgroundColor: '#F7E237',
//       height: 8,
//     },
//     flat_box: {
//       marginTop: 16,
//       marginBottom: 20,
//     },
//     bx_item_wrap: {
//       position: 'relative',
//       width: 160,
//       borderRadius: 8,
//       borderWidth: 1,
//       borderColor: 'rgba(34,34,34,0.1)',
//       marginRight: 8,
//       overflow: 'hidden',
//     },
//     bx_img: {
//       // height: responsiveWidth(56),
//       height: 220,
//       borderRadius: 8,
//       backgroundColor: '#f1f1f1',
//       overflow: 'hidden',
//     },
//     bg_img_blur: {
//       position: 'absolute',
//       left: 0,
//       right: 0,
//       top: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(34,34,34,0.2)',
//       borderRadius: 8,
//       zIndex: 99,
//     },
//     bx_info: {
//       position: 'absolute',
//       left: 0,
//       right: 0,
//       bottom: 0,
//     },
//     bg_info_wrap: {
//       justifyContent: 'flex-end',
//       paddingHorizontal: 16,
//       paddingVertical: 12,
//     },
//     info_brand_name: {
//       color: '#fff',
//       fontSize: 11,
//       letterSpacing: -0.44,
//       fontWeight: '700',
//       lineHeight: 15.93,
//       marginBottom: 2,
//     },
//     info_pd_name: {
//       fontSize: 14,
//       color: '#fff',
//       fontWeight: '400',
//       letterSpacing: -0.56,
//       lineHeight: 20.27,
//     },
//     bx_info_price: {
//       flexDirection: 'row',
//       alignItems: 'center',
//       flexWrap: 'wrap',
//       marginTop: 4,
//       // marginBottom: 12,
//     },
//     txt_info_price: {
//       fontSize: 16,
//       color: '#fff',
//       fontWeight: '700',
//       letterSpacing: -0.64,
//     },
//     txt_price_unit: {
//       fontSize: 14,
//       fontWeight: '500',
//       letterSpacing: -0.56,
//     },
//     bx_sale_per: {
//       marginRight: 2,
//     },
//     txt_percent: {
//       fontSize: 16,
//       color: '#F7E237',
//       fontWeight: '500',
//       letterSpacing: -0.64,
//     },
//     txt_sale_price: {
//       color: '#999',
//       fontSize: 14,
//       letterSpacing: -0.56,
//       marginLeft: 6,
//       textDecorationLine: 'line-through',
//     },
//     btn_bookmark: {
//       position: 'absolute',
//       top: 8,
//       right: 3,
//       zIndex: 99,
//     },
//   })
