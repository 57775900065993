#my_appliance_item_for_product {
  .product_checked {
    .product_item {
      background-color: #F7F4FA;
    }

    .product_text1 {
      color: #BE69C2;
    }
  }

  .product_item {
    padding: 10px 20px 10px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .product_image_wrap {
      margin-right: 12px;
      position: relative;

      .empty_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        border: 1px solid #2222221A;
        white-space: pre-line;
        color: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.04em;
        text-align: center;

      }
    }

    .product_image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 6px;
      border: 1px solid #2222221A;
    }

    .product_info {
      flex: 1;
      min-width: 0;

      .product_text1 {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.04em;
        text-align: left;

        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      .product_info_bottom {
        display: flex;
        margin-top: 4px;

        .product_text2,
        .product_text3 {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.04em;
          text-align: left;
          color: #999;
        }

        .product_text3 {
          padding: 0 6px;
          color: #d9d9d9;
        }
      }

    }

    .icon_wrap {
      display: flex;
      margin-left: 10px;
    }
  }
}