.modal_after_open {
  z-index: 100;
  .close_icon {
    display: block;
  }
}

.modal_before_close {
  .close_icon {
    display: none;
  }
}

.close_icon {
  position: absolute;
  top: 0;
  left: -40px;
  cursor: pointer;
  display: none;
}

.right_nav {
  position: relative;
  background-color: rgb(103, 44, 120);
  height: 100%;
}

.right_nav {
  flex: 1;
  min-width: 0;
  min-height: 0;
  background-color: #672c78;
  display: flex;
  flex-direction: column;
}

.container {
  padding-top: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  z-index: 9;
  &.shadow {
    box-shadow: 0px 15px 26px 0px #00000033;
  }
}

.bx_gradient {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  left: 0;
}

.header_icons_wrap {
  margin-bottom: 23px;
  padding-left: 24px;
  padding-right: 16px;
  height: 48px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bx_profile {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.header_icons_left {
  flex-direction: row;
}

.header_icons_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.icons {
  width: 32px;
  height: 32px;
}

.main_text_wrap {
}

.main_text {
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
}

.welcome_text_wrap {
  padding-right: 24px;
  padding-left: 24px;
  margin-top: 10px;
}

.welcome_text {
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
  font-weight: bold;
}

.intro_text_wrap {
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  height: 35px;
}

.intro_text {
  font-size: 20px;
  color: #fff;
  letter-spacing: -0.96px;
}

.user_action_wrap {
  flex-direction: row;
  margin-top: 23px;
}

.slash {
  font-size: 12px;
  color: #6c2773;
  letter-spacing: -0.48px;
  margin-right: 2px;
  margin-left: 2px;
}

.user_action_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  font-weight: 500;
  color: #672c78;
}

.draw_list {
  margin-top: 30px;
  padding-left: 24px;
  background-color: #672c78;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.alert {
  position: relative;
  display: flex;
  align-items: center;
}

.alert_text_area {
  position: absolute;
  right: 8px;
  top: 2px;
  align-items: center;
  justify-content: center;
  background-color: #f7e237;
}

.alert_text {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  line-height: 11px;
  padding-left: 1px;
}

.image_wrap {
  padding-left: 24px;
}

.image {
  border-radius: 100px;
  width: 64px;
  height: 64px;
}

.info_wrap {
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.at_text {
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
  font-weight: bold;
  margin-right: 2px;
}

.id_text {
  font-size: 20px;
  color: #f7e237;
  letter-spacing: -0.96px;
  font-weight: bold;
  margin-right: 2px;
}

.sir_text {
  font-size: 20px;
  color: #fff;
  letter-spacing: -0.96px;
  margin-right: 2px;
}

.bar {
  margin-top: 30px;
  background-color: #7b448a;
  height: 2px;
}

.bar2 {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 21px;
  background-color: #7b448a;
  height: 2px;
}

.recommend_friend_view_btn {
  width: 100%;
}

.recommend_friend_view {
  height: 48px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 24px;
}

.recommend_friend_txt1 {
  font-size: 18px;
  font-style: normal;
  line-height: 19px;
  letter-spacing: -0.72px;
  text-align: left;
  color: #fff;
}

.recommend_friend_txt2 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 19px;
  letter-spacing: -0.72px;
  text-align: left;
  color: #f7e237;
}

.bottom_info_wrap {
  margin-top: 30px;
  padding-right: 24px;
  padding-left: 24px;
}

.bottom_info_wrap_text {
  height: 26px;
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #b38bbe;
}

.bottom_info_wrap_text_bold {
  height: 26px;
  font-size: 12px;
  letter-spacing: -0.48px;
  font-weight: bold;
  color: #d3bad6;
}

.sns_wrap {
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 59px;
}

.sns_btn {
  margin-right: 12px;
}

.before_login_id_wrap {
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;
}

.id_text2 {
  font-size: 20px;
  color: #fff;
  letter-spacing: -0.8px;
  font-weight: bold;
  margin-right: 2px;
}

.display_block {
  display: block;
}

.before_login_text {
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.64px;
  line-height: 26px;
  opacity: 0.6;
}

.signup_btn_wrap {
  padding-right: 24px;
  padding-left: 24px;
}

.signup_btn {
  height: 44px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: 24px;
  width: 100%;
  border-radius: 60px;
  display: flex;
}

.arrow_icon {
  margin-left: 4px;
}

.bx_link_wrap {
  flex-direction: row;
  padding: 0 24px;
  margin-bottom: 28px;
  display: flex;
}

.bx_link_item {
  width: 33.333%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.txt_link_label {
  padding-top: 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.48px;
}

.touch_link {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.bx_banner_pop {
  padding-horizontal: 6%;
}

.bx_banner_link_wrap {
  flex-direction: row;
  padding-vertical: 10px;
  padding-left: 16px;
  padding-right: 13px;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.2);
  border-radius: 6px;
}

.txt_banner {
  color: #fff;
  font-size: 14px;
  padding-right: 27px;
}

.txt_banner_bold {
  color: #f7e237;
  font-weight: 700;
}

.label_wrap {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.label {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.72px;
  color: #fff;
  margin-left: 9px;
}
