.member_editor {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.wrap {
  padding: 32px 16px 20px;
}
.wrap_p {
  padding: 0 16px 20px;
}
.top_wrap {
  padding-top: 20px;
}
.edit_wrap {
  margin-top: 20px;
}
.top_edit_wrap {
  margin-top: 24px;
}
.sub_label_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #999;
  margin-bottom: 6px;
}
.email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.contents_text_wrap {
  flex: 1;
}
.contents_text {
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #222;
  padding-right: 50px;
}
.password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.phone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.btns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.clear_btn {
  margin-right: 14px;
}
.modify_btn {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid rgba(34, 34, 34, 0.1);
}
.modify_btn_text {
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #be69c3;
  white-space: nowrap;
}
.disable_modify_btn_text {
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #999;
  white-space: nowrap;
}
.withdraw_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  text-decoration: underline;
  font-weight: 500;
  color: #999;
}
.bar {
  background-color: #f5f5f5;
  height: 6px;
}
.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alert_wrap {
  margin-top: 6px;
}
.phone_not_valid {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: bold;
  color: #c00055;
  margin-left: 3px;
}
.phone_success_valid {
  font-size: 12px;
  letter-spacing: -0.48px;
  font-weight: bold;
  color: #66aa00;
  margin-left: 3px;
}
.pw_input_wrap {
  display: flex;
  align-items: center;
  margin: 20px 20px 0px 20px;
  // background-color: #fafafa;
  height: 60px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: #6C2773;
    width: 100%;
  }
}
.pw_input {
  display: flex;
  flex: 1;
  width: 100%;
  padding-right: 30px;
  font-size: 22px;
  letter-spacing: -0.88px;
  background-color: transparent;
}
.toggle_btn {
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.info_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  margin-top: 60px;
  padding: 0 16px;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
}
.footer {
  position: fixed;
  bottom: 0px;
  height: 56px;
  width: 100%;
  max-width: 450px;
}
.btn_ok {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn_ok_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.active {
  background-color: #222;
}
.in_active {
  background-color: #eee;
}
.txt_active {
  color: white;
}
.txt_in_active {
  color: #999;
}
