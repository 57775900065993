.find_email {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.header {
  padding-right: 16px;
  padding-left: 16px;
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.step_list {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.footer {
  display: flex;
  flex-direction: row;
}

.btn_1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.txt_in_active {
  color: #999;
}

.in_active {
  background-color: #eeeeee;
  border-color: #d9d9d9;
}

.txt_active {
  color: #fff;
}

.active {
  background-color: #222;
}

.step_item {
  margin-bottom: 16px;
}

.step_header {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
}

.step_header_text {
  font-size: 16px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.64px;
}

.step_header_in_active_text {
  font-size: 14px;
  color: #999;
  letter-spacing: -0.56px;
}

.step_body {
  margin-top: 16px;
  padding-bottom: 36px;
}

.search_done_body {
  padding-right: 20px;
  padding-left: 20px;
}

.search_done {
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 58px;
}

.email_text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  text-align: center;
  line-height: 58px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 7px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22px;
  letter-spacing: -0.88px;
  margin-right: 10px;
}

.toggle_btn {
  height: 30px;
}

.alert_wrap {
  margin-top: 6px;
  padding-right: 20px;
  padding-left: 20px;
}

.alert {
  display: flex;
  flex-direction: row;
}

.not_valid {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.valid {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.other_email_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.other_email_btn {
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 100px;
  border-color: #222;
  border-width: 1px;
  height: 36px;
}

.other_email_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
}
