.attract {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #672c78;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: auto;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.service {
  text-align: left;
  padding-right: 20px;
  padding-left: 20px;
  height: 56px;
}

.service_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: rgba(255, 255, 255, 0.2);
}

.logo {
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding-top: 30px;
}

.text_wrap {
  align-items: center;
  justify-content: center;
}

.txt_0 {
  font-size: 20px;
  color: #222;
  letter-spacing: -1.6px;
  line-height: 28px;
}

.txt_point {
  color: #6c2773;
}

.txt_line {
  width: 42px;
  height: 2px;
  background-color: #6c2773;
  margin-bottom: 30px;
  margin-top: 10px;
}

.kakao {
  display: flex;
  flex-direction: row;
  height: 52px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #fce74e;
  border-width: 1px;
  border-color: #ffc700;
}

.apple {
  display: flex;
  flex-direction: row;
  height: 52px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #222;
  border-color: #000;
  color: #fff;
  border-width: 1px;
}

.txt_btn {
  margin-left: 6px;
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #222;
}
.txt_btn_white {
  margin-left: 10px;
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #fff;
}
.middle_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 19px;
}
.middle_line {
  flex: 1;
  min-width: 0;
  min-height: 0;
  background-color: #d9d9d9;
  height: 1px;
}
.middle_txt {
  width: 44px;
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
}
.sns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}
.sns_img {
  margin-left: 13px;
  margin-right: 12px;
}
.bottom_area {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: center;
}
.bottom_left {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
  justify-content: flex-end;
}
.bottom_right {
  flex: 1;
  min-width: 0;
  min-height: 0;
  text-align: left;
}
.txt_1 {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: rgba(255, 255, 255, 0.4);
}
.bottom_area_line {
  width: 1px;
  background-color: #c4c4c4;
  opacity: 0.3;
  margin-right: 13px;
  margin-left: 13px;
}

.indicator {
  flex: 1;
  min-width: 0;
  min-height: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.last_login_info {
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 9px;
}

.last_login_info_txt {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #fff;
  opacity: 0.6;
  font-weight: 500;
}

.join_subscription {
  height: 32px;
  align-items: center;
  justify-content: center;
}

.join_subscription_txt {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #fff;
  font-weight: 500;
}

.main {
  justify-content: space-between;
}

.logo_wrap {
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.login_wrap {
  padding-left: 55px;
  padding-right: 55px;
}

.problem_wrap {
  flex: 1;
  min-width: 0;
  min-height: 84px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.problem_btn {
  padding-bottom: 32px;
}

.problem_btn_text {
  font-size: 16px;
  letter-spacing: -0.56px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.2);
}

.kakao_join_popover {
  display: flex;
  position: relative;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
}

.kakao_join_popover_inner {
  background-color: #762e8a;
  border-color: #be69c3;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 12px;
  border-width: 1px;
  width: 136px;
  height: 30px;
}

.kakao_join_popover_text1 {
  font-size: 12px;
  color: #fff;
  letter-spacing: -0.48px;
  font-weight: 500;
  line-height: 16px;
}

.kakao_join_popover_text2 {
  font-size: 12px;
  color: #f7e237;
  letter-spacing: -0.48px;
  font-weight: bold;
  line-height: 16px;
}

.triangle {
  position: absolute;
  background-color: #762e8a;
  transform: rotate(45deg);
  left: 58px;
  bottom: -5px;
  width: 8px;
  height: 8px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: #be69c3;
}

.logo_text1 {
  font-size: 22px;
  letter-spacing: -0.44px;
  color: #fff;
  line-height: 34px;
}

.logo_text2 {
  font-weight: bold;
}

.signupStyle_kakao_join_popover_inner {
  position: relative;
  display: flex;
  background-color: #fff;
  border-color: #be69c3;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  width: 136px;
  height: 30px;
}

.signupStyle_kakao_join_popover_text1 {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  line-height: 16px;
}

.signupStyle_kakao_join_popover_text2 {
  font-size: 12px;
  color: #d073d5;
  letter-spacing: -0.48px;
  font-weight: bold;
  line-height: 16px;
}

.signupStyle_triangle {
  position: absolute;
  background-color: #fff;
  transform: rotate(45deg);
  left: 58px;
  bottom: -5px;
  width: 8px;
  height: 8px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: "#be69c3";
  border-right-style: "solid";
  border-bottom-style: "solid";
}

.signupStyle_logo_text1 {
  font-size: 22px;
  letter-spacing: -0.44px;
  color: #fff;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
}

.signupStyle_logo_text2 {
  font-size: 14px;
  letter-spacing: -0.44px;
  color: #fff;
  line-height: 20px;
}

.signupStyle_logo_wrap {
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
