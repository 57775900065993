.notification-screen {
  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 54px;
    z-index: 1;
    background: white;
    max-width: 450px;
    width: 100%;
    height: 55px;

    .tab_active_btn {
      height: 55px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-color: #222;
      .tab_active_btn_text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.64px;
        color: #222;
      }
    }
    .tab_btn {
      height: 55px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: solid;
      border-bottom-width: 1px;
      border-color: #eee;
      .tab_text {
        font-size: 16px;
        font-weight: normal;
        letter-spacing: -0.64px;
        color: #999;
      }
    }
  }
  .header_right_btn {
    padding: 8px 12px 8px 12px;
    gap: 10px;
    border-radius: 40px 0px 0px 0px;
    border: 1px solid #999999;
    height: 28px;
    border-width: 1px;
    display: flex;
    align-items: center;
  }
  .header_wrap {
    position: fixed;
    top: 0;
    max-width: 450px;
    width: 100%;
    background-color: white;
    z-index: 1;
  }
}
