.post_main_header {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 24px 16px 0 16px;
}

.post_main_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 25px;
  padding: 0px 18px;
}

.post_main_item {
  width: calc(50% - 4px);
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;
}

.title {
  font-size: 32px;
  font-weight: 700;
}

.title2 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: #666;
}

.post_item_list {
  flex: 1;
  padding: 0 16px;
}