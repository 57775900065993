.notice_detail {
  background-color: white;
}

.top_wrap {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.txt_date {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
}
.option_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.option_box {
  padding: 24px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  background-color: #fff;
}
.option {
  padding-bottom: 24px;
  margin: 0 20px;
  border-bottom: 1px solid #eee;
}

.logout_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #999;
  font-weight: 500;
}
.web_view {
  padding: 0 20px 20px 20px;
  overflow-x: auto;
}
