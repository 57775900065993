.post-user-info-wrapper {
  display: flex;
  padding-left: 16px;
  margin-top: 20px;
  margin-bottom: 24px;

  .post-user-info-left {
    display: flex;
    align-items: center;
  }

  .info-wrap {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }

  .user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  .info-text1 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.04em;
    text-align: left;
    color: #222;
  }

  .info-text2 {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.04em;
    text-align: left;
    color: #666;
  }
}
