#SearchTag {
  .tag_wrap_list {
    // margin-top: 16px;
    padding: 0 16px 40px 16px;
  }
  .tag_wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-start;
    .tag_title_text {
      font-size: 16px;
      letter-spacing: -0.64px;
      color: #222;
      font-weight: 500;
      width: 100%;
      text-align: left;

      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    .tag_count_text {
      margin-top: 6px;
      font-size: 14px;
      letter-spacing: -0.56px;
      color: #999;
    }
  }
  .tag_item {
    display: flex;
    align-items: center;
    font-family: inherit;
  }
}
