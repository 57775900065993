.wrapper {
  margin-top: 20px;
  display: flex;
  height: 54px;
  border-radius: 6px;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
}
.text {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
.choice_text {
  color: #be69c3;
  font-weight: 700;
  font-size: 18px;
}
