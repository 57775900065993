.preference_main {
  // display: flex;
  // flex-direction: column;
  background-color: #fff;
}

.footer_wrapper {
  flex-direction: row;
  position: fixed;
  bottom: 0;
  max-width: 450px;
  width: 100%;
}
.preference_contents {

  padding-bottom: 80px;
}

.footer {
  justify-content: space-between;
  padding: 14px 24px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
}

.footer_btn {
  display: flex;
  border-color: #eee;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  width: 50px;
  height: 36px;
}

.footer_active_btn {
  border-color: #222;
}

.footer_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #999;
}

.footer_active_btn_text {
  color: #222;
}

.pagination {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.page {
  border-radius: 5px;
  margin-right: 6px;
  width: 8px;
  height: 8px;
}

.active_page {
  background-color: #a760b1;
}

.inactive_page {
  background-color: #c4c4c4;
}


.footer_1 {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  max-width: 450px;
  width: 100%;
}
.btn_1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.active {
  background-color: #BE69C2;
}

.in_active {
  background-color: #c4c4c4;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.txt_active {
  color: #fff;
}

.txt_in_active {
  color: #fff;
}