.item_ab_bottom {
  margin-top: 32px;
  padding: 0 20px;
  p {
    margin: 0;
  }
  .item_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item_header_left {
      display: flex;
      align-items: center;
      .date_wrap {
        background-color: #be69c3;
        padding: 4px 8px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.48px;
      }
      .item_header_text1 {
        margin-left: 10px;
        color: #999;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.48px;
      }
    }
    .item_header_right {
      display: flex;
      align-items: center;
      .item_header_text2 {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.48px;
      }
      .item_header_text1 {
        color: #999;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.48px;
      }
      .item_text_bar {
        width: 1px;
        height: 12px;
        background-color: #eee;
        margin: 0 6px;
      }
    }
  }
  .item_body {
    margin-top: 12px;
    .item_body_text1 {
      color: #222;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.72px;
      margin-bottom: 8px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item_body_text2 {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.56px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .select_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .left_horizontal_image,
      .right_horizontal_image {
        position: relative;
        overflow: hidden;
        width: 100%;
        // padding-bottom: 100%;
        &.selected {
          background-color: #be69c3;
          .selected_text {
            color: #fff;
          }
        }
        &.result_not_opened {
          &.not_selected {
            &.has_no_thumbnail {
              &.right_horizontal_image {
                background-color: #fafbfc;
              }
              &.left_horizontal_image {
                background-color: #fcfafc;
              }
            }
          }
        }
        &.result_opened {
          &.not_selected {
            background-color: #f5f5f5;
            &.has_no_thumbnail {
              .select_text {
                background-color: #c4c4c4;
              }
              .selected_text {
                color: #999;
              }
            }
          }
          &.has_no_thumbnail {
            .ab_image_wrap {
              display: none;
            }
          }
        }
        &.has_thumbnail {
          &.selected {
            &:before {
              background-color: #be69c3;
              opacity: 0.5;
            }
            .selected_text {
              margin-top: 10px;
            }
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
            max-height: 204px;
          }
          .select_text {
            font-size: 32px;
          }
          .selected_text {
            color: #fff;
          }
        }
        &.has_no_thumbnail {
          &.selected {
            .selected_text {
              margin-top: 10px;
              color: #fff;
            }
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(50, 50);
          width: 100%;
          height: 100%;
          justify-content: center;
          object-fit: cover;
        }
      }
      .left_horizontal_image {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        margin-right: 1px;
        &.has_thumbnail {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          &:before {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
          & > div:has(.horizontal_image) {
            height: 100%;
          }
          .horizontal_image {
            width: 100%;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
        &.has_no_thumbnail {
          .select_text {
            background-color: #be69c3;
          }
          .selected_text {
            color: #be69c3;
          }
        }
      }

      .right_horizontal_image {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-left: 1px;
        &.has_thumbnail {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          &:before {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          & > div:has(.horizontal_image) {
            height: 100%;
          }
          .horizontal_image {
            width: 100%;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
        &.has_no_thumbnail {
          .select_text {
            background-color: #7094bd;
          }
          .selected_text {
            color: #7094bd;
          }
        }
      }
      .select_text_wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        z-index: 2;
        .select_text {
          padding: 4px;
          border-radius: 6px;
          width: 40px;
          height: 40px;
          margin: 0 0 10px;
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: -1.2px;
        }
        .selected_text {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.56px;
          padding: 0 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
      .ab_image_wrap {
        width: 100%;
      }
    }
    .result_wrap {
      padding: 0;

      .result_count {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .result_count_left,
        .result_count_right {
          display: flex;
          align-items: center;

          .result_count_win_text {
            color: #be69c3;
            margin: 0 2px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.48px;
          }

          .result_count_lose_text {
            color: #666;
            margin: 0 2px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.48px;
          }
        }
      }
    }
  }
}
