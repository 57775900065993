.download_app_drive_bottom_sheet {
  background-color: white;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
}

.message {
  margin-top: 16px;
  width: 280px;
  font-size: 16px;
  text-align: center;
  color: #222222;
  white-space: pre-wrap;
}

.message1 {
  color: #6c2773;
}

.download_button {
  margin-top: 32px;
  height: 54px;
  width: 280px;
  background: #6c2773;
  color: white;
  border-radius: 27px;
  font-size: 16px;
  font-weight: 700;
}

.cancel_button {
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
}
