.bx_title {
  margin-top: 24px;
  padding: 0 16px;
  position: relative;
  width: fit-content;
}

.bg_title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f7e237;
  height: 8px;
  z-index: -1;
}

.txt_title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: #222;
}

.flat_box {
  margin-top: 16px;
  padding: 0 16px;
}

.bx_btn_more {
  margin-bottom: 15px;
  padding: 0 16px;
}

.btn_more {
  height: 48px;
  border: 1px solid #eee;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.txt_btn_more {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
