#QnaCardItem {
  .popular_qna_item {
    border-radius: 6px;

    width: 220px;
    height: 220px;

    position: relative;
    &.has_thumbnail {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        border-radius: 6px;
      }
    }
    .popular_qna_image {
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;

      position: absolute;
      top: 0px;
      left: 0px;
    }

    .qna_purple_wrap1 {
      background-color: #854788;
    }

    .qna_purple_wrap2 {
      background-color: #ab5eaf;
    }

    .qna_purple_wrap3 {
      background-color: #be69c3;
    }

    .popular_qna_contents_wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 16px;
      z-index: 2;

      position: absolute;
      top: 0px;
      left: 0px;

      .popular_qna_contents_text {
        margin: 0;
        padding: 4px;
        font-size: 16px;
        letter-spacing: -0.64px;
        font-weight: 500;
        color: #fff;
        text-align: left;
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
      .popular_qna_info {
        display: flex;
        justify-content: space-between;

        .popular_qna_profile {
          display: flex;
          align-items: center;

          .popular_qna_profile_image {
            width: 24px;
            height: 24px;
            border-radius: 25px;
            margin-right: 6px;
          }
          .id_text {
            margin: 0;
            font-size: 12px;
            color: #fff;
            letter-spacing: -0.48;
            font-weight: bold;
            white-space:nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .comment {
          display: flex;
          color: #fff;
          align-items: center;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}
#QnaCardItem:nth-last-child(1) {
  .popular_qna_item {
    margin-right: 16px;
  }
}
