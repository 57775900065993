body:has(#MainTutorial) {
  overflow: hidden;
}

#MainTutorial {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  max-width: 450px;
  background-color: rgba(0, 0, 0, 0.8);

  .close_icon_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    margin-top: 16px;
    & > :first-child {
      display: flex;
    }
  }
  .touch_icon_container {
    display: flex;
    margin-top: 30%;
    align-items: center;
    justify-content: center;
  }
  .touch_icon {
    align-items: center;
    width: 78px;
    height: 96px;
  }
  .welcome_icon_container {
    margin-top: 105px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .text_area_1_container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    font-size: 24px;
    letter-spacing: -0.96px;
    font-weight: 500;
    line-height: 32px;
    color: #fff;
  }
  .swipeUpWrap {
    margin-top: 16px;
    align-items: center;
    text-align: center;
  }
  .swipeUpText {
    font-size: 24px;
    color: #f7de2a;
    font-weight: 500;
    letter-spacing: -0.96px;
    margin-bottom: 10px;
    text-align: center;
  }
  .aiText {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.64px;
    line-height: 24px;
    text-align: center;
    white-space: pre-line;
  }
}
