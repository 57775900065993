.wrap {
  position: fixed;
  inset: 0px;

  z-index: 100000;
}
.indicator {
  position: absolute;
  inset: 50% auto auto 50%;
  border: none;
  overflow: hidden;
  border-radius: 12px;
  outline: none;
  padding: 0px;
  transform: translate(-50%, -50%);
}
