.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  max-width: 450px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.inner_header {
  width: 100%;
  z-index: 99;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background-color: #fff;
  height: 60px;
  padding: 14px 16px;
}

.left,
.right {
  width: 78px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.right {
  justify-content: flex-end;
}

.icons {
  width: 32px;
  height: 32px;
  margin-right: 6px;
}

.title_txt {
  font-size: 16px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.64px;
}

.count {
  font-size: 16px;
  color: #6c2773;
  font-weight: bold;
  letter-spacing: -0.64px;
}

.button_wrap,
.buttons_hor_wrap,
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons_hor_wrap {
  flex: 1;
  flex-direction: row;
}

.cart_btn {
  height: 32px;
}

.button,
.buttons_hor {
  border: 1px solid #f0dff2;
  border-radius: 5px;
  width: 10%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons_hor {
  width: 10.76vw;
  height: 28px;
  flex: 1;
}

.button_text {
  font-size: 14px;
  letter-spacing: -0.64px;
  color: #6c2773;
}

.title_border {
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.between_header {
  justify-content: space-between;
}

.skip_btn,
.skip_btn_off {
  border-radius: 100px;
  padding: 8px 12px;
  border: 1px solid;
}

.skip_btn_off {
  border-color: #eee;
}

.skip_text {
  color: #222;
}

.skip_text_off {
  color: #999;
}

.register_btn,
.register_btn_off {
  padding: 8px 4px;
}

.register_text,
.register_text_off {
  color: #6c2773;
  font-size: 16px;
}

.register_text_off {
  color: #999;
}
