$padding-header: 16px;

.header {
  display: flex;
}

.inner_header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 $padding-header;
  padding: 14px 75px;
}

.title {
  text-align: center;
}

.title_txt {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.04em;
}

.left {
  position: absolute;
  left: $padding-header;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

.icon {
  display: flex;
}
