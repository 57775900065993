.setting {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
}
.option_list {
  margin-bottom: 110px;
}
.option {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #222;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
}
.option_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.option_box {
  padding: 20px 4.1%;
  display: flex;
}
.option_box_border {
  border-bottom: 1px solid #f5f5f5;
}
.line {
  height: 6px;
  background-color: #f5f5f5;
}
.logout_txt {
  color: #999;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.64px;
}
