.user_qna_detail_body {
  .title_text {
    padding: 0 20px;
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 20px;
    letter-spacing: -0.8px;
    color: #222;
    font-weight: 500;
  }
  .small_bar {
    height: 1px;
    margin: 16px 20px 0px;
    background-color: #eeeeee;
  }
  .contents_wrap {
    margin-top: 20px;
    margin-bottom: 6px;
    padding: 0 20px;

    .contents_text {
      font-size: 16px;
      letter-spacing: -0.64px;
      color: #222;
      font-weight: 400;
      line-height: 26px;
      white-space: pre-line;
      margin: 0;
    }
  }
  .qna_detail_slider_wrap {
    margin-top: 24px;
    .qna_detail_slider_item {
      .video-player {
        video {
          width: 100%;
          object-fit: cover;
          max-height: 600px;
        }
      }
      .image_item {
        align-items: flex-end;
        width: 100%;
        height: 600px;
        object-fit: cover;
      }
    }
    .slick-dots {
      bottom: 10px;
      li {
        margin: 0;
        button:before {
          font-size: 8px;
          color: #fff;
        }
      }
    }
  }
  .tag_list {
    padding: 0 20px;
    .tag_item {
      background-color: #f7f4fa;
      padding: 8px 12px;
      border-radius: 50px;
      margin-right: 7px;
      margin-bottom: 7px;
      .tag_text {
        margin: 0;
        color: #be69c3;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.36px;
      }
    }
  }
  .home_app_list {
    margin-top: 14px;
    padding: 0 16px;
    margin-bottom: 40px;
    align-items: center;
    overflow-x: scroll;
    .home_app_item {
      display: flex !important;
      align-items: center !important;
      max-width: 280px !important;
      margin-right: 10px !important;
      .home_app_img {
        margin-right: 12px;
        border: 1px solid #be69c3;
        border-radius: 6px;
        width: 60px;
        height: 60px;
      }
      .home_app_text1 {
        color: #222;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
        line-height: 16px;
        margin: 0 0 3px;
      }
      .home_app_text2 {
        margin: 0;
        color: #999;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.48px;
      }
      .on_sale_tag_wrap {
        width: fit-content !important;
        padding: 0 6px;
        display: flex;
        width: auto;
        background-color: #f0dff2;
        border-radius: 4px;
        align-self: flex-start;
        height: 17px;
        margin-top: 4px;
        margin-bottom: 2px;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .on_sale_tag_text {
          margin: 0;
          color: #be69c3;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.56px;
        }
      }
    }
  }
}
