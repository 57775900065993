.toast_alert {
  position: fixed;
  width: 100%;
  bottom: 40px;
  left: 0px;
}

.toast_div {
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  max-width: 350px;
  background-color: #222;
  margin: 0px auto;
  border-radius: 20px;
  padding: 10px 0;
}


@keyframes slideIn {
  from {
    transform: translateY(200%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(200%);
  }
}

.openAnimation {
  animation: slideIn 0.5s ease-in-out 0s 1 normal forwards;
}

.closeAnimation {
  animation: slideOut 0.5s ease-in-out 0s 1 normal forwards;
}