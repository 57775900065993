.write_btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding-bottom: 21px;
  border-bottom: solid;
  border-color: #eee;
  border-bottom-width: 1px;
  padding-left: 16px;
  padding-right: 16px;
}
.write_btn {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border: solid;
  border-radius: 6px;
  border-width: 1px;
  border-color: #be69c2;
  font-family: inherit;
}

.write_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #be69c2;
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin: 0;
}
