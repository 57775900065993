.nothing_search_img {
  align-items: center;
  display: flex;
  position: relative;
  > svg {
    width: 100%;
  }
}

.empty_wrap {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 30;
  right: 0;
  left: 0;
}

.empty_txt {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding: 0 24px;
  white-space: pre-wrap;
}

.empty_txt1 {
  margin-bottom: 8px;
  font-size: 16px;
  letter-spacing: -0.64;
  font-weight: 600;
  width: 218;
  height: 23;
  text-align: center;
  color: #222222;
}
.empty_txt2 {
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: -0.64;
  font-weight: 600;
  width: 218;
  height: 17;
  text-align: center;
  color: #666666;
}

.empty_txt3 {
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: -0.64;
  font-weight: 500;
  width: 218;
  height: 21;
  text-align: center;
  color: #666666;
}

.empty_search {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  margin-bottom: 15px;
}

.empty_btn_wrap {
  display: flex;
  border: 1px solid #a760b1;
  padding: 12px 24px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 300px;
}

.empty_btn_text {
  color: #be69c3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}
