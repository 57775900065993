.bx_category {
  display: flex;
  padding: 10px 16px;
  flex-direction: row;
  align-items: center;
}
.bx_filter {
  height: 32px;
}
.txt_category {
  font-size: 14px;
  color: #222;
  font-weight: 400;
  margin: 0px 6px;
  cursor: pointer;
}
.txt_category_bold {
  font-weight: bold;
  font-size: 14px;
  color: #222;
  margin: 0px 6px;
  cursor: pointer;
}
