.following-list-wrapper {
  overflow-y: auto;
  padding: 0 16px;
  height: 100vh;

  .item-wrapper {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .item-left-wrapper {
    display: flex;
    column-gap: 10px;
    flex: 1;
    width: calc(100% - 90px);
  }

  .profile-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  .info-user-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    width: calc(100% - 58px);
  }

  .info-user-top {
    display: flex;
    align-items: center;
  }

  .info-user-bottom {
    overflow-x: hidden;

    .tags-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.64px;
      text-align: left;
      color: #999;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item-right-wrapper {
    margin-left: 10px;
  }

  .un-follow-btn {
    height: 36px;
    border-radius: 300px;
    padding: 0 20px;
    border: 1px solid #be69c3;
    color: #be69c3;
    font-size: 14px;
    white-space: nowrap;
    letter-spacing: -0.64px;
  }

  .follow-btn {
    height: 36px;
    border-radius: 300px;
    padding: 0 20px;
    background-color: #be69c3;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    letter-spacing: -0.64px;
  }

  .sub-follow-btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.64px;
    color: #be69c3;
    margin-left: 6px;
  }
}
