#post_write_wrap {
  .content_wrap_inside {
    .title {
      .title_input_text {
        width: calc(100% - 40px);
        height: 35px;
        margin: 0 20px;
        font-size: 24px;
        font-weight: 400;
        line-height: 34.75px;
        letter-spacing: -0.04em;
      }
    }
    border-bottom: 1px solid #999999;
    padding-bottom: 14px;
  }
  .add_contents_wrap {
    padding: 20px 16px;
    .add_home_app_button {
      display: block;
      margin: 0 0 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 23.17px;
      letter-spacing: -0.04em;
      color: #BE69C3;
      padding: 15px;
      width: 100%;
      text-align: center;
      background-color: #F7F4FA;
      border-radius: 4px;
      .add_home_app_button_text {
        margin-left: 6px;
      }
    }
    .add_contents_text2 {
      font-size: 12px;
      font-weight: 400;
      line-height: 17.38px;
      letter-spacing: -0.04em;
    }
  }
  .footer {
    .btn_1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 26.06px;
      letter-spacing: -0.04em;
      padding: 15px;
      width: 100%;
      max-width: 450px;
      position: fixed;
      bottom: 0;
      &.in_active {
        background-color: #eee;
        color: #666666;
      }
      &.active {
        background-color: #222;
        color: #fff;
      }
    }
  }
}