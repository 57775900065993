.product_search_result_list {
  .product_search_result_list_inner {
    padding: 0 16px;
  }
  .naverDiv {
    height: 6px;
    background-color: #f5f5f5;
    margin-top: 20px;
  }

  .empty_wrap,
  .naver_wrap {
    margin-top: 20px;
    background-color: #fff;

    .empty_text1 {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.04em;
      text-align: center;
      color: #666;
    }

    .empty_info {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: center;
      color: #999;
    }

    .add_home_app_button {
      width: fit-content;
      margin: auto;
      margin-top: 32px;
      padding: 12px 20px;
      border-radius: 300px;
      border: 1px solid #be69c3;
      color: #be69c3;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: center;
      cursor: pointer;
    }
  }

  .naver_image_wrap {
    margin: 20px 16px 0;
    display: flex;
    justify-content: flex-end;

    .naver_image {
      width: 50%;
    }
  }
}
