.centered_view {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal_view {
  position: relative;
  width: responsiveWidth(87);
  background-color: #fff;
  border-radius: 12;
  align-items: center;
  // shadowcolor: #000;
  // shadowOffset {
  //   width: 0;
  //   height: 2;
  // }
  // shadowopacity: 0.25;
  // shadowradius: 4;
  // elevation: 5;
  overflow: hidden;
}
.modal_content_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-bottom: 10px;
  align-items: center;
}
.txt_content_title {
  margin: 12px 0;
  font-size: 20px;
  color: #222;
  letter-spacing: -0.8px;
  font-weight: bold;
  text-align: center;
}
.txt_content {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.56px;
  text-align: center;
  line-height: 20px;
}
