#QnaMain {
  .qna_item {
    display: flex;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
      margin-bottom: 24px;
    }
    .qna_wrap {
      margin-right: 9px;
      margin-left: 10px;
      width: calc(100% - 43px);

      .qna_top {
        display: flex;
        justify-content: space-between;
        &.has_thumbnail {
          .qna {
            max-width: calc(100% - 82px);
          }
        }
        .qna {
          text-align: left;
          max-width: 100%;

          .qna_item_text1 {
            margin: 0;
            font-weight: 500;
            color: #222222;
            font-size: 16px;
            letter-spacing: -0.64px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .qna_item_text2 {
            margin-top: 6px;
            color: #666;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .qna_image {
          margin-left: 10px;
          color: #222222;
          width: 72px;
          height: 72px;
        }
      }

      .qna_info_wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 20px;

        .qna_item_bar {
          width: 1px;
          height: 14px;
          margin: 0 8px;
          background-color: #eee;
        }

        .qna_info {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .qna_item_text3 {
            font-size: 12px;
            color: #222222;
            font-weight: 500;
            letter-spacing: -0.48px;
            margin: 0;
          }

          .small_wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;

            &_inner {
              display: flex;
              align-items: center;

              .qna_comment {
                display: flex;
                align-items: center;

                p {
                  margin: 2px 0 0 2px;
                  color: #be69c3;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: -0.48px;
                }
              }

              .like {
                display: flex;
                align-items: center;

                p {
                  margin: 2px 0 0 2px;
                  font-size: 12px;
                  font-weight: 400;
                  letter-spacing: -0.48px;
                  color: #999;
                }
              }

              .qna_item_text5 {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: -0.48px;
                color: #999;
                margin: 0;
              }
            }

            .reply_btn_text {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: -0.48px;
              color: #be69c3;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
