#relation_product_slide {
  background-color: #fff;

  .relation_product_header {
    padding: 0 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .relation_product_header_text1 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.72px;
      color: #222222;
    }

    .relation_product_header_text2 {
      color: #a760b1;
    }

    .relation_product_header_text3 {
      font-size: 12px;
      letter-spacing: -0.48px;
      font-weight: 500;
      color: #666666;
    }
  }

  .slick-track {
    .slick-slide {
      &:first-child {
        .related-product-item {
          margin-left: 16px;
        }
      }

      &:last-child {
        .related-product-item {
          margin-right: 16px;
        }
      }
    }
  }
}