.header {
  position: fixed;
  top: 0;
  max-width: 450px;
  width: 100%;
  z-index: 100;
}

.wrap {
  background-color: #fff;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 19px;
  padding-right: 19px;
  white-space: pre-line;
}

.footer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  max-width: 450px;
  width: 100%;
}

.btn_1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.txt_active {
  color: #fff;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.active {
  background-color: #222;
}


