.basic_info {
  flex: 1;
  min-width: 0;
  min-height: 0;
  background-color: #fff;
}

.info_list {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.info_item {
  margin-bottom: 24px;
}

.info_item2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.label {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}

.input_wrap {
  height: 60px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 14px;
  padding-left: 20px;
  border-radius: 6px;
  background-color: #fafafa;
  margin-top: 16px;
}

.input {
  height: 44px;
  flex-grow: 1;
  text-align: left;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.56px;
  font-weight: 400;
  background-color: inherit;
}

.alert_wrap {
  margin-top: 6px;
  padding-left: 4px;
}

.alert_text {
  font-size: 12px;
  letter-spacing: -0.24px;
  font-weight: 500;
}

.alert_text1 {
  color: #be69c3;
}

.alert_text2 {
  color: #ea174a;
}

.alert_text3 {
  margin-left: 4px;
  font-size: 12px;
  letter-spacing: -0.24px;
  font-weight: 500;
  color: #be69c3;
  padding-left: 4px;
}

.recommend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #fcfafc;
  border-radius: 4px;
}

.recommend_text_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.recommend_text1 {
  font-size: 12px;
  letter-spacing: -0.24px;
  color: #666666;
}

.recommend_text2 {
  font-weight: bold;
  color: #be69c3;
}

.use_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(34, 34, 34, 0.1);
  border-radius: 6px;
  background-color: #fff;
  padding: 8px;
  white-space: nowrap;
}

.use_btn_text {
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #be69c3;
}

.btn_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.gender_btn {
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-color: #ebebeb;
  width: calc(50% - 3px);
  height: 53px;
  border-width: 1px;
  border-style: solid;
}

.active_btn {
  border-color: #be69c3;
  border-style: solid;
  border-width: 1px;
}

.gender_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #666666;
}

.active_btn_text {
  color: #be69c3;
  font-weight: 500;
}

.year_btn {
  margin-top: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px;
}

.year_btn_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
}

.close_btn {
  position: absolute;
  top: 6px;
  right: 6px;
}

.genderYearGuide {
  width: 331px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #999999;
  margin-top: 20px;
  display: inline-block;
}
