#MainProfile {
  main {
    width: 100%;
    height: 100%;
  }
  body {
    width: 100%;
    height: 100%;
  }
  .my_wrap {
    padding: 0 16px;
    margin-top: 16px;
  }
  .my {
    display: flex;
    align-items: center;
  }
  .profile_img {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 32px;
    background-color: #fff;
  }
  .my_text_wrap1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mimi_kim {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.56px;
    text-align: left;
    color: #ca57db;
  }
  .mimi_kim_support_text {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #000;
  }
  .mimi_kim_inner_text {
    padding-left: 2px;
  }
  .myhome_text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #682c78;
  }

  .lk_wrap {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding: 0 16px 16px 16px;
    margin-top: 12px;
    border-bottom: 10px solid #f5f5f5;
  }
  .lk_item {
    display: flex;
    flex-direction: row;
    width: 25%;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
  }
  .in_item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 44px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 6px;
  }
  .txt_lk_title {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: #222;
  }
  .btn_arrow {
    margin-left: 4px;
  }
}
