#profile_editor {
  p {
    margin: 0;
  }

  button {
    font-family: inherit;
  }

  .photo_editor_box {
    height: 266px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo_wrap {
    position: relative;
    display: flex;
  }

  .profile_image {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    object-fit: cover;
  }

  .ellipse_wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0px 4px 4px 0px #0000001A;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .ellipse {
      display: inline-flex;
    }
  }

  .profile_button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .ellipse2 {
      display: inline-flex;
    }
  }

  .profile_info_box {
    background-color: #fff;
    padding: 24px 0 100px 0;
  }

  .info_wrap {
    margin: 0;
    margin-bottom: 24px;
    padding: 0 16px;
  }

  .title {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #222;
    margin-left: 4px;
  }

  .nik_name_box {
    position: relative;
  }

  .nickname_guide {
    margin-left: 4px;
    font-weight: 500;
    color: #BE69C3;
    font-size: 12px;
    margin-bottom: 7px;
  }

  .input {
    font-family: inherit;
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 6px;
    line-height: 24px;
    padding: 20px 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.64px;
    text-align: left;
    resize: none;
    display: block;
    max-height: 120px;

  }

  .introduce_txt_input {
    margin-bottom: 4px;
  }

  .profile_x {
    display: flex;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .nik_name {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.64px;
    text-align: left;
    margin-top: 7px;
    margin-left: 4px;

    &_fail {
      color: #EA174A;
    }

    &_success {
      color: #BE69C3;
    }

  }

  .sns_fail {
    margin-top: 7px;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.64px;
    text-align: left;
    color: #EA174A;
  }

  .gender_box {
    display: flex;
    column-gap: 6px;

    .gender_btn {
      height: 53px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.64px;

      &_selected {
        border: 1px solid #BE69C3;
        color: #BE69C3;
      }

      &_not_selected {
        border: 1px solid #EBEBEB;
        color: #666;
      }
    }
  }

  .birth_year_box {
    height: 61px;
    padding: 0 20px;
    line-height: 24px;
    border-radius: 6px;
    background-color: #FAFAFA;
    position: relative;
    display: flex;
    align-items: center;

    .born_txt {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;

    }

    .area_icon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .option-list-box {
    border-top: 6px solid #f5f5f5;
    margin-top: 36px;

    .option-box {
      padding: 20px 0px;
      border-bottom: 1px solid #eee;
    }

    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      width: 100%;
    }

    .option_txt {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
      margin-left: 4px;
    }

    .icon_wrap {
      display: flex;
    }
  }

  .footer_btn_wrap {
    position: fixed;
    bottom: 0;
    max-width: 450px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn_1 {
      background-color: #C4C4C4;
      width: 100%;
      height: 100%;

      &_active {
        background-color: #222;
      }
    }

    .btn_1_txt {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.64px;
      color: #fff;
    }
  }
}

#change-bgc-bottom-sheet {
  .close_btn {
    position: absolute;
    right: 16px;
    top: 6px;
    z-index: 1;
    display: flex;

    &>button {
      display: flex;
      cursor: pointer;
    }
  }

  .bg_title_wrap {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bg_title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.64px;
    text-align: center;
    color: #222;
  }

  .bg_list {
    padding: 20px 0 40px;
    display: flex;
    row-gap: 16px;
    justify-content: center;
    flex-direction: column;
  }

  .bg_list_top,
  .bg_list_bottom {
    margin: 0 auto;
    display: flex;
    column-gap: 16px;

    .bg_item {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      position: relative;
      z-index: 2;
    }

    .active_item.bg_item {
      box-shadow: 0 0 0 4px #9450D8;
    }
  }

  .bg_save_btn {
    height: 56px;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;

    &_text {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.64px;
      color: #fff;
    }
  }
}