// Dimensions.get(window)
// const percentageCalculation = (Dimensions.get(window): number, w: number) => Dimensions.get(window) * (w / 100)px;
#FeedPost {
  .slick-dots {
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 180px);
  }

  .slick-slide {
    padding: 0 10px !important;
  }

  .slick-dots li.slick-active {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: #fff;
    opacity: 1;
  }

  .slick-dots li {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: #fff;
    opacity: 0.4;
    margin: 0 3px;
  }

  .slick-dots li button {
    display: none;
  }

  .popular_post {
    flex: 1px;
    background-color: #fff;
  }
  .post_item {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .post_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    padding: 0 16px;
  }
  .post_header_left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .post_header_right {
    display: flex;
    flex-direction: row;
  }
  .user_img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
  .info_wrap {
    margin: 0 10px;
  }
  .info_text1 {
    font-size: 16px;
    letter-spacing: -0.04em;
    color: #222;
    font-weight: 700;
    margin-bottom: 2px;
    text-align: left; 
  }
  .info_text2 {
    font-size: 12px;
    color: #666666;
    letter-spacing: -0.48px;
    text-align: left;
  }
  .post_body {
    padding: 0 6px;
  }
  .slick-slide {
    & > div {
      display: flex;
    }
  }
  .video-player {
    width: 100%;
    position: relative;
    video {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  img.image_item {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .slick-slide {
    video {
      border-radius: 10px;
    }
  }
  .video_item {
    align-items: flex-end;
    padding-left: 4px;
    padding-right: 14px;
    padding-top: 14px;
    border-radius: 6%;
  }
  .magazine_image_item {
    align-items: flex-end;
    padding-bottom: 30px;
  }
  .carousel_idx {
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 28%;
    opacity: 0.8;
    width: 44px;
    height: 38px;
    z-index: 10px;
    top: 14px;
    right: 14px;
  }
  .carousel_idx_text {
    font-size: 12px;
    color: #666666;
    letter-spacing: -0.48px;
    font-weight: 500;
  }
  .carousel_pagination_container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-bottom: 16px;
  }
  .carousel_pagination_active {
    border-radius: 5%;
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.92);
  }
  .carousel_pagination_inactive {
    border-radius: 5%;
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.92);
    opacity: 0.4;
  }
  .magazine_btn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #642e6f;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    width: 144px;
    opacity: 0.8;
  }
  .magazine_btn_text {
    margin-left: 6px;
    font-size: 14px;
    color: #fff;
    letter-spacing: -0.56px;
    font-weight: 500;
  }
  .action_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 0 16px;
  }
  .action_left {
    display: flex;
    flex-direction: row;
  }
  .action_right {
    display: flex;
    flex-direction: row;
  }
  .action_icon_btn {
    margin-right: 10px;
    display: inline-flex;
    &:last-child {
      margin-right: 0;
    }
  }
  .action_text {
    display: flex;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.56px;
    font-weight: 500;
    align-items: center;
    column-gap: 2px;
  }
  .number_text {
    font-weight: 500;
    font-size: 14px;
  }
  .like_text {
    display: flex;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.56px;
    font-weight: 500;
    margin-right: 10px;
  }
  .feed_post_title_text {
    margin-top: 12px;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
  }
  .contents_wrap {
    margin-top: 6px;
    padding: 0 20px;
    position: relative;
  }
  .home_app_list {
    margin-top: 12px;

    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;

    .slick-track {
      .slick-slide {
        padding: unset !important;
      }
    }
  }
  .home_app_first_item {
    // padding: left_16_responsive;
    padding-left: 16px;
  }
  .home_app_item_wrapper {
    margin-right: 4px;
    width: 80%;
  }
  .home_app_item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .home_app_img {
    margin-right: 8px;
    border-radius: 6px;
    width: 60px;
    height: 60px;
    border: 1px solid;
    border-color: rgba(34, 34, 34, 0.1);
  }
  .home_app_rep_image {
    border-color: #be69c3;
  }
  .home_app_info {
    width: 280px;
  }
  .home_app_text_wrap {
    width: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .home_app_text1 {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .home_app_text2 {
    font-size: 12px;
    // color: #222;
    letter-spacing: -0.48px;
    font-weight: 500;
    color: #999;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bx_product_item {
    flex-direction: row;
    align-items: center;
    padding-right: 18px;
  }
  .pros_and_cons_outer_wrap {
    padding: 0 16px;
  }
  .pros_and_cons_wrap {
    background: #f8f8f8;
    border-radius: 6px;
    padding: 18px;
    margin-top: 6px;
  }
  .rating_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    div {
      display: inline-flex;
    }
  }

  .rating_text {
    font-size: 18px;
    letter-spacing: -0.36px;
    font-weight: 500;
    color: #be69c3;
  }
  .pros_and_cons {
    display: flex;
    flex-direction: row;
  }
  .pros {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
  }
  .pros_and_cons_title {
    display: flex;
    background-color: #fff;
    border-radius: 70px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    height: 25px;
  }
  .pros_and_cons_title_text {
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500;
    color: #be69c3;
  }
  .pros_and_cons_text {
    flex: 1px;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }
  .tag_list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin-top: 12px;
    padding: 0 20px;
  }
  .tag_item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .tag_text {
    font-size: 14px;
    letter-spacing: -0.42px;
    color: #be69c3;
  }
  .post_detail_btn_wrap {
    padding: 0 16px;
  }
  .post_detail_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-radius: 4px;
    border: 1px solid #eee;
    height: 53px;
  }
  .post_detail_btn_text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #222;
  }
  .more {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #222;
    position: absolute;
    bottom: 0;
    right: 22px;
    // Platform.select({
    // .ios { top: 17 }
    // .android { top: 22 }
    // })px;
  }
  .more_text {
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 700;
    text-decoration-line: underline;
    font-weight: bold;
  }
  .contents_text {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    text-align: left;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
  }
  .entire_contents_text {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    white-space: pre-wrap;
    text-align: left;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
  }
  .contents_text_show_more {
    height: 44px;
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: {    font-weight: 500 }px;
    //   android: {    font-weight: 400 }px;
    // })px;
  }
  .more_button {
    // position: absolute;
    // bottom: Platform.OS == ios ? 14 : 7px;
    bottom: 7px;
    right: 20px;
  }
  .on_layout_width {
    padding-left: 10px;
    padding-right: 10px;
  }
  .off_follow_btn {
    display: flex;
    width: 74px;
    height: 27px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #f5f5f5;
  }
  .off_follow_text {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #fff;
    justify-content: center;
    align-items: center;
    color: #999;
    justify-content: center;
    align-items: center;
  }
  .on_follow_btn {
    display: flex;
    width: 74px;
    height: 27px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #be69c3;
  }
  .on_follow_text {
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
  .on_sale_tag_wrap {
    width: fit-content !important;
    padding: 0 6px;
    display: flex;
    width: auto;
    background-color: #f0dff2;
    border-radius: 4px;
    align-self: flex-start;
    height: 17px;
    margin-top: 4px;
    margin-bottom: 2px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .on_sale_tag_text {
    color: #be69c3;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 14px;
    padding-left: 1px;
  }
  .mall_tag {
    display: flex;
    align-items: center;
  }
  .main {
    width: 100%;
    height: 100%;
  }
  .body {
    width: 100%;
    height: 100%;
  }
  .my_wrap {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 12px;
  }
  .my {
    display: flex;
    flex-direction: row;
  }
  .profile_img {
    width: 36px;
    height: 36px;
    margin-top: 4px;
    margin-right: 8px;
    border-radius: 32%;
    background-color: #fff;
  }
  .my_text_wrap {
    padding-top: 12px;
  }
  .my_text_wrap1 {
    display: flex;
    flex-direction: row;
  }

  .mimi_kim {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.56px;
    text-align: left;
    color: #ca57db;
  }
  .mimi_kim_support_text {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #000;
  }
  .myhome_text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    text-align: left;
    color: #682c78;
  }

  .lk_wrap {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 12px;
    padding-bottom: 16px;
    border-bottom-width: 10px;
    border-color: #f5f5f5;
  }
  .lk_item {
    flex-direction: row;
    width: 25%;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
  }
  .in_item {
    display: flex;
    flex-direction: row;
    height: 44px;
    width: 100%;
    align-items: center;
    padding-left: 10px;
    background-color: #f5f5f5;
    border-radius: 6%;
  }
  .txt_lk_title {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    // line-height: 17.38px;
    letter-spacing: -0.4px;
    color: #222;
  }
  .btn_arrow {
    margin-left: 4px;
    margin-top: -2px;
  }
}
