#bookmark_product {
  padding: 0 16px;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  .product_item {
    display: flex;
    align-items: center;
    .product_check_btn {
      margin-right: 18px ;
    }
    .product_image {
      width: 60px;
      height: 60px;
      margin-right: 12px;
      border: 1px solid #e9e9e9;
      border-radius: 6px;
      object-fit: cover;
    }
    .product_info {
      .product_text1 {
        text-align: left;
        margin-bottom: 4px;
        color: #222;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.56px;
      }
      .product_info_bottom {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.48px;
        .product_text3 {
          margin: 0 6px;
        }
      }
    }
  }
}
