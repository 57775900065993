#product_search_item_section {
  padding: 10px 20px 10px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;

  &.product_checked {
    background-color: #F7F4FA;
  }

  .product_image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #2222221A;
    margin-right: 12px;
  }
  .product_info {
    flex: 1;
    min-width: 0;

    .product_image_wrapper {
      height: auto;
    }

    .product_text1 {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: left;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    .product_info_bottom {
      display: flex;
      margin-top: 4px;

      .product_text2,
      .product_text3 {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.04em;
        text-align: left;
        color: #999;
      }

      .product_text3 {
        padding: 0 6px;
        color: #d9d9d9;
      }
    }

  }
}