#product_add {
  .header {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.04em;
    text-align: center;
    color: #666;
    white-space: pre-line;
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }

  .line_section {
    height: 6px;
    background-color: #f5f5f5;
    margin: 40px 0;
  }

  .contents_title {
    padding: 0 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.04em;
    text-align: left;
    color: #666;
  }

  .contents_title_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #be69c3;
    padding: 0 16px;
    margin-bottom: 16px;
  }

  .input_wrap {
    & + .input_wrap {
      margin-top: 8px;
    }

    height: 60px;
    border-radius: 6px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    padding: 0 20px;
    -moz-column-gap: 6px;
    column-gap: 6px;
    margin: 0 16px;

    .unit_text {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
    }

    .label_text {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: left;
    }

    .input_text {
      flex: 1;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;

      &::-webkit-input-placeholder {
        color: #999;
      }

      &::-moz-placeholder {
        color: #999;
      }

      &:-ms-input-placeholder {
        color: #999;
      }

      &::-ms-input-placeholder {
        color: #999;
      }

      &::placeholder {
        color: #999;
      }
    }

    &.input_country_wrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      -moz-column-gap: 6px;
      column-gap: 6px;

      .country_chk_btn_view_item {
        flex: 1;
        height: 40px;
        border-width: 1px;
        border-style: solid;
        border-radius: 300px;
        border-color: #eee;
        overflow: hidden;
      }

      .country_chk_btn_view {
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.04em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
      }
    }
  }

  .middle_view {
    padding: 9px 16px;
    margin: 40px 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20.27px;
    letter-spacing: -0.04em;
    text-align: center;
    color: #fff;
    background-color: #be69c2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 450px;
    height: 56px;

    .regi_button {
      height: 100%;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      &_active {
        background-color: #222;
        color: #fff;
      }
      &_inactive {
        background-color: #eee;
        color: #666;
      }
    }
  }

  .img_input_wrap {
    margin: 8px 16px 0;
    background-color: #fafafa;
    // padding: 20px;
    padding: 20px 0;

    .upload {
      flex-shrink: 0;
      background-color: #fff;
      border: 1px solid #eeeeee;
      border-radius: 6px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.04em;
        text-align: center;
        color: #c4c4c4;
      }

      .qna_plus_icon {
        margin-bottom: 10px;
      }

      .qna_photo_icon_wrap {
        display: flex;
        margin-top: 4px;
      }
    }

    .img_input_inner {
      padding: 0 20px 0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
      margin-bottom: 20px;
    }

    .img_guide_text {
      padding: 0 20px;
      margin-top: 12px;
      color: #666;
      letter-spacing: -0.64px;
      white-space: pre-line;
    }
  }

  .product_image_list_wrap {
    margin-left: 20px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .img_wrap + .upload {
      margin-left: 8px;
    }
  }

  .img_wrap {
    & + .img_wrap {
      margin-left: 8px;
    }

    aspect-ratio: 1;
    flex-shrink: 0;
    position: relative;

    .images {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 6px;
      border: 1px solid #eeeeee;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }

    .icon_wrap {
      display: flex;
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}
