$padding: 16px;

body {
  margin: 0;
  line-height: 1.444444;
  font-family: "Noto Sans KR";
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.magazine-content {
  padding-bottom: 1.25rem;
  img {
    height: auto !important;
  }
}

.magazine-detail {
  overflow-x: hidden;

  .divider {
    margin: 0 -1rem 2.5rem -1rem;
    height: 0.375rem;
    background: #f5f5f5;
  }

  .section {
    margin-bottom: 2.5rem;
  }

  .section-title {
    font-size: 1.125rem;
    letter-spacing: -0.04em;
    color: #222;
    font-weight: 500;
    margin: 0 0 1rem;
    padding-left: 20px;
  }

  .tags {
    display: flex;
    gap: 0.375rem;
    flex-wrap: wrap;
    padding: 0 $padding;

    .tag {
      background-color: #f7f4fa;
      color: #be69c3;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.48px;
      padding: 8px 12px;
      border-radius: 32px;
    }
  }

  .slider {
    button {
      font-family: inherit;
    }

    .swiper {
      overflow: visible;
    }
  }

  .related-magazine-wrapper,
  .related-post-wrapper,
  .related-product-wrapper {
    .slider {
      .slick-slide {
        .swiper-slide {
          .item {
            width: 100%;
          }
        }
      }
    }
  }

  .related-magazine-wrapper #CustomCarousel {
    position: relative;
    padding-bottom: 22px;

    .dot_indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      right: $padding;
      display: flex;
      column-gap: 6px;
      justify-content: center;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        gap: 0px;
        background-color: #22222233;

        &_active {
          background-color: #22222266;
        }
      }
    }
  }

  .related-magazine-wrapper {
    .slick-list {
      padding-left: 16px;
    }
    .slick-slide {
      padding: 0 4px;
    }
  }

  .related-post-wrapper,
  .related-product-wrapper {
    .slick-list {
      padding-left: 12px;
    }

    .slick-slide {
      padding: 0 4px;
    }
  }

  .item {
    .img {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .title {
      font-size: 16px;
      letter-spacing: -0.04rem;
      color: #222;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 0.75rem;
      text-align: left;
    }

    .title.max-line-2 {
      font-size: 14px;
    }

    .max-line-2 {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      line-height: 1.4285;
      max-height: 2.857em;
      margin-top: 0.5rem;
    }

    .writer {
      font-size: 12px;
      letter-spacing: -0.64px;
      color: #999;
      margin-top: 4px;
      text-align: left;
    }

    .brand {
      font-size: 0.75rem;
      letter-spacing: -0.03rem;
      color: #999;
      margin-top: 4px;
      text-align: left;
    }
  }
}

.magazine-detail .item .brand span + span::before {
  content: "";
  width: 0.0625rem;
  height: 0.625rem;
  background: #d9d9d9;
  display: inline-block;
  margin: 0 0.375rem;
  transform: translateY(0.5px);
}

.magazine-detail .item .brand span:last-child::after {
  display: none;
}
