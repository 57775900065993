#benefitPop {
  .bx_content_container {
    height: 100%;
    overflow-y: scroll;
    white-space: pre-wrap;
  }

  .bx_content_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 36px;
  }

  .bx_notice {
    padding: 20px;
  }

  .bx_invite {
    height: 100%;
    width: 100%;
    background-color: #fff;
  }

  .line_bar {
    height: 6px;
    background-color: #f5f5f5;
    margin: 40px 0;
  }

  .bx_content_wrap {
    padding: 0 20px;
    align-items: center;
  }

  .txt_st1 {
    font-size: 18px;
    font-weight: 500;
    color: #222;
    text-align: center;
    letter-spacing: -0.4px;
    line-height: 2;
    padding-bottom: 32px;
  }

  .txt_st1_bold {
    font-weight: 700;
  }

  .txt_title_strong {
    font-size: 20px;
    color: #222;
    font-weight: 700;
    line-height: 2;
    letter-spacing: -0.4px;
    padding: 20px 0 16px;
  }

  .bx_info_benefit {
    padding: 16px;
    margin-top: 20px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 12px;
  }

  .txt_info_title {
    font-size: 16px;
    color: #222;
    font-weight: 700;
    line-height: 2;
    margin-top: 16px;
    letter-spacing: -0.64px;
  }

  .txt_info_cont {
    font-size: 14px;
    color: #222;
    line-height: 2;
    letter-spacing: -0.56px;
  }

  .txt_info_cont_bold {
    color: #BE69C2;
    font-weight: 700;
  }

  .txt_info_cont_strong {
    color: #6C2773;
    font-weight: 700;
  }

  .txt_title_st2 {
    font-size: 24px;
    color: #222;
    font-weight: 700;
    letter-spacing: -0.96px;
    margin-top: 52px;
  }

  .txt_cont_st2 {
    font-size: 14px;
    color: #222;
    font-weight: 500;
    text-align: center;
    line-height: 2;
    letter-spacing: -0.56px;
    margin-top: 12px;
  }

  .in_notice {
    border-radius: 4px;
    background-color: #fafafa;
    padding: 16px;
    margin-bottom: 40px;
  }

  .txt_noti_title {
    font-size: 14px;
    color: #666;
    font-weight: 700;
    line-height: 2;
    letter-spacing: -0.56px;
  }

  .txt_noti_cont {
    font-size: 12px;
    line-height: 2;
    letter-spacing: -0.48px;
    color: #222;
    font-weight: 400;
  }

  .txt_bold {
    font-weight: 700;
  }
}

