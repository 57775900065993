#reportSheet {
  
  &.reportSheet {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  .close_btn {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 999;
  }
  
  .warning_wrap {
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 16px;
  }
  
  .warning_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #999;
  }
  
  .title_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    height: 32px;
  }
  
  .title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.72px;
    color: #222;
  }
  
  .reason_list {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .reason_item {
    margin-bottom: 18px;
  }
  
  .reason_text {
    font-size: 14px;
    color: rgb(34, 34, 34);
    letter-spacing: -0.56px;
    font-weight: 500;
    margin-left: 12px;
  }
  
  .reason_btn {
    display: flex;
    justify-content: center;
  }
  
  .confirm_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4;
    height: 70px;
  }
  
  .confirm_active_btn {
    background-color: #222;
  }
  
  .confirm_btn_text {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.72px;
    color: #fff;
  }
}