#qna_write {
  textarea,
  input {
    color: #222;

    &::placeholder {
      color: #999;
    }
  }

  .qna_title {
    resize: none;
    padding: 20px;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: -0.04em;
    text-align: left;
  }

  .qna_content {
    resize: none;
    padding: 20px;
    width: 100%;
    min-height: 300px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.04em;
    text-align: left;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
  }

  .tooltip_bg {
    width: fit-content;
    color: #fff;
    position: relative;

    .tooltip_arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
    }

    .tooltip_bx {
      background-color: #222;
      width: 137px;
      border-radius: 6px;
      padding: 12px 20px;
      text-align: center;
    }

    .btn_tooltip_close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .container {
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 6px;
    column-gap: 16px;
    display: flex;
    width: 100%;
    overflow-x: auto;
  }

  .upload_wrap {
    position: relative;
    width: fit-content;

    .tooltip_info_wrap {
      z-index: 10;
      position: absolute;
      top: 50%;
      left: calc(100% + 10px);
      transform: translateY(-50%);
    }

    .upload {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      border: 1px solid #eeeeee;
      background-color: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .qna_photo_wrap {
        display: flex;
      }

      .upload_txt {
        font-size: 12px;
        color: #999;
      }
    }
  }

  .warning_private {
    display: inline-block;
    margin-top: 6px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.04em;
    text-align: left;
    color: #999;
  }

  .line_thick {
    height: 6px;
    margin: 20px 0 30px;
    background-color: #f5f5f5;
  }

  .add_home_app_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    height: 53px;
    background-color: #f7f4fa;
    color: #be69c3;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: center;
    cursor: pointer;
    column-gap: 3px;
    margin: 0 16px;
    border-radius: 4px;
  }

  .spacer {
    height: calc(56px + 20px);
  }

  .btn_register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 450px;
    position: fixed;
    bottom: 0;
    height: 56px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: center;
  }

  .register_button_inactive {
    background-color: #eeeeee;
    color: #666;
  }

  .register_button_active {
    background-color: #222;
    color: #fff;
  }

  .img_wrap {
    position: relative;
    width: fit-content;
    .images {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 6px;
      border: 1px solid #eeeeee;
    }
    .delete_btn {
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }
}
