.user_style_item_wrapper {
  margin-bottom: 24px;
}

.user_style_item_container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.post_header {
  height: 48px;
}

.post_header_left {
  display: flex;
  align-items: center;
}

.user_img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.info_wrap {
  margin: 0 10px;
  width: calc(100% - 144px);
  
}

.info_wrap_overview {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.info_text1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20.27px;
  letter-spacing: -0.64px;
  text-align: left;
}

.info_text2 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.64px;
  color: #be69c3;
}

.info_text3 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  margin-top: 2px;
  display: inline-block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.64px;
  color: #666666;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.follow_btn_wrap {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.follow_btn_on {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 62px;
  border-radius: 4px;
  background-color: #be69c2;


}
.follow_btn_off{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 62px;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.follow_text_on{
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.64px;
}
.follow_text_off {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.64px;
}

.follow_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image_wrapper {
  margin-top: 12px;
  display: flex;

  .feed_item_wrapper {
    width: calc(25% - 4.5px);
    position: relative;

    & + .feed_item_wrapper {
      margin-left: 6px;
    }

    & > div:first-child {
      width: 100%;
      position: relative;
      padding-top: 100%;
    }
  }
}
