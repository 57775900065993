.nothing_search_img {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: unset;

  &.list {
    // justify-content: flex-start;
    padding-left: 10px;
    & > svg {
      width: unset;
    }
  }
  &.grid {
    & > svg {
      width: 100%;
      rect {
        width: calc(50% - 10px);
      }
    }
  }
  .empty_wrap {
    position: absolute;
    top: 100px;
    display: flex;
    height: fit-content;
  }
}
