$padding: 16px;

.magazine {
  flex: 1;
  padding: 0px $padding 56px;
}

.magazine_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  button {
    font-family: inherit;
  }
}

.header_wrapper {
  max-height: 54px;
  min-height: 54px;
  position: fixed;
  max-width: 450px;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
}

.magazine_header {
  margin-top: 24px;
  padding: 0 $padding 0;
  display: flex;
  flex-direction: column;
}

.magazine_header_text1 {
  font-size: 32px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #222;
}

.magazine_header_text2 {
  margin-top: 8px;
  font-size: 14px;
  color: #666666;
  letter-spacing: -0.64px;
}

.sticky_tabs {
  padding: 0px $padding;
  position: -webkit-sticky;
  position: sticky;
  top: 54px;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  max-width: 450px;
}

.sticky_tabs_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 18px;
       column-gap: 18px;
  margin-top: 24px;
  margin-bottom: 16px;

  .sticky_tab_text {
    font-size: 20px;
    font-weight: 500;
    line-height: 28.96px;
    letter-spacing: -0.04em;
    color: #aaaaaa;

    &_active {
      font-weight: bold;
      color: #222;
    }
  }
}

.magazine_list {
  flex: 1;
}

.magazine_item_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.user_img {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.info_wrap {
  margin: 0 10px;
  text-align: left;
}

.info_text1 {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: bold;
  margin-bottom: 2px;
}

.info_text2 {
  font-size: 12px;
  color: #666666;
  letter-spacing: -0.48px;
}

.magazine_image_container {
  width: 100%;

  > :first-child {
    position: relative;
    padding-top: 100%;
  }
}

.magazine_image_wrapper {
  display: flex;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.magazine_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 6px;
}

.magazine_btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
}

.magazine_btn_text {
  font-size: 14px;
  letter-spacing: -0.64px;
  color: #fff;
}

.action_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.action_left {
  display: flex;
  flex-direction: row;
}

.action_right {
  display: flex;
  flex-direction: row;
}

.action_icon_btn {
  margin-right: 10px;
  display: flex;
}

.action_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.64px;
  font-weight: 500;
}

.number_text {
  font-size: 14px;
  margin-left: 2px;
}

.like_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.64px;
  font-weight: 500;
  margin-right: 10px;
}

.magazine_review {
  text-align: left;
}

.title_text {
  margin-top: 12px;
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: bold;
}

.contents_text {
  margin-top: 6px;
  font-size: 14px;
  color: #222;
  letter-spacing: -0.64px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
