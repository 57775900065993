.share_bottom_sheet {
}

.share_bottom_sheet_content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.share_bottom_sheet_header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.share_bottom_sheet_title {
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 16px;
}

.share_bottom_sheet_close_button {
  position: absolute;
  right: 0;
  margin-right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
}

.share_bottom_sheet_body {
  display: flex;
  flex-direction: column;
}

.share_bottom_sheet_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 13px 16px 13px 16px;
}

.text {
  font-size: 16px;
  color: #222;
}
