.filter-item-wrapper {

  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
  }

  .stage {
    border-radius: 6px;
    overflow: hidden;
  }
}