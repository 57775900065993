.setting {
  background-color: white;
}
.option {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.option_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}
.option_box {
  display: flex;
  flex: 1;
  padding: 20px 20px;
}
.option_box_border {
  border-bottom: 1px solid #eee;
}
.line {
  height: 6px;
  background-color: #f5f5f5;
}
.logout_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: 500;
  color: #999;
}
.version_info {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #6c2773;
}