.bx_item_wrap_common {
  position: relative;
}

.btn_bookmark {
  position: absolute;
  top: 8px;
  right: 8px;
}

.bx_img {
  object-fit: cover;
  border: 1px solid #2222221a;
  border-radius: 4px;
}
.bx_info {
  padding: 8px 4px 20px;
  display: flex;
  flex-direction: column;
}
.info_pd_manufacturer {
  font-size: 11px;
  font-weight: 700;
  color: #222;
  margin-bottom: 2px;
}
.info_pd_name {
  font-size: 14px;
  font-weight: 400;
  color: #424242;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.bx_info_price {
  display: flex;
  column-gap: 4px;
  margin-top: 8px;
}
.txt_info_percent {
  font-size: 16px;
  font-weight: 600;
  color: #ea174a;
}
.txt_info_price {
  font-size: 16px;
  font-weight: 700;
  color: #222;
}
.txt_info_before_price {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  text-decoration: line-through;
}
.bx_star_icon {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.txt_review {
  margin-left: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #999;
  display: flex;
  align-items: center;
}
.txt_review_num {
  font-weight: 500;
  padding-left: 2px;
}
