body {
  margin: 0;
  font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ReactModal__Overlay {
    inset: 0px clamp(0px, calc(50% - 225px), 50vw) 0px
      clamp(0px, calc(50% - 225px), 50vw) !important;
    .ReactModal__Content {
      position: absolute !important;
    }
  }
  background-color: rgb(245, 245, 245);
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

input,
textarea {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: unset;
}

.mobile-max-width {
  max-width: 450px;
  width: 100%;
  margin: auto;
  /* border: 1px solid #e0e0e0; */
}

#root {
  order: 2;
  max-width: 450px;
  width: 100%;
  margin: auto;
  position: relative;
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 8px;
}
