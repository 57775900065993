$padding: 16px;
@mixin center_vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header {
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  position: relative;
}

.center {
  text-align: center;
  height: 100%;
}

.left {
  @include center_vertically;
  left: $padding;
  &>button {
    display: flex;
  }
}

.right {
  @include center_vertically;
  right: $padding;
}

.title_txt {
  font-size: 16px;
  font-weight: 700;
  line-height: 23.17px;
  letter-spacing: -0.04em;
  display: inline-block;
  padding: 0 32px;
}

.disabled_btn {
  border-radius: 100px;
  padding: 8px 12px;
  background-color: #eeeeee;
  border: 1px solid #d9d9d9;
}

.skip_btn {
  border-radius: 100px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
}

.skip_text {
  color: #222;
}
