.MainPopupBanner_main_full_popup {
  flex: 1;
}
.MainPopupBanner_bottom_sheet_view {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.MainPopupBanner_carousel_list_wrap {
}
.MainPopupBanner_carousel_item {
}
.MainPopupBanner_image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.MainPopupBanner_carousel_idx {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #255;
  border-radius: 28px;
  opacity: 0.8;
  width: 44px;
  height: 38px;
  z-index: 10;
  top: 20;
  right: 20;
}
.MainPopupBanner_carousel_idx_text {
  font-size: 12px;
  color: #666666;
  letter-spacing: -0.48px;
  font-weight: 500;
}
.MainPopupBanner_carousel_pagination_container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  padding-bottom: 0;
}
.MainPopupBanner_carousel_pagination_active {
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.92);
}
.MainPopupBanner_carousel_pagination_inactive {
  border-radius: 5px;
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.4;
}

.MainPopupBanner_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding-right: 20px;
  padding-left: 20px;
}
.MainPopupBanner_footer_btns {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.MainPopupBanner_footer_text1 {
  font-size: 16px;
  letter-spacing: -0.64;
  font-size: 500;
  color: #666666;
}
.MainPopupBanner_footer_text2 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.MainPopupBanner_join_wrap {
  position: absolute;
  left: 0;
  right: 0;
}
.MainPopupBanner_join {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.MainPopupBanner_join_text1 {
  font-size: 16px;
  letter-spacing: -0.56px;
  font-weight: 500;
  color: #222;
}
.MainPopupBanner_join_btn {
  border-radius: 300px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: #a760b1;
  border-width: 1px;
}
.MainPopupBanner_join_text2 {
  font-size: 14px;
  letter-spacing: -0.56px;
  font-weight: 500;
  color: #a760b1;
}

.MainPopupBanner_dot_indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  position: absolute;
  left: 0;
  bottom: 80px;
}

.MainPopupBanner_dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  & + .MainPopupBanner_dot {
    margin-left: 6px;
  }
}

.MainPopupBanner_dot_active {
  background-color: #fff;
}

.MainPopupBanner_dot_inactive {
  background-color: #fff;
  opacity: 0.4;
}
