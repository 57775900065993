.video-player-with-fallback {
  position: relative;
  .video-player-with-fallback-info {
    // width: -webkit-fit-content;
    // width: -moz-fit-content;
    // width: fit-content;
    width: 74px;
    display: flex;
    background-color: rgba(34, 34, 34, 0.4);
    border-radius: 32px;
    position: absolute;
    align-items: center;
    gap: 6px;
    padding: 0px 10px;
    bottom: 24px;
    right: 14px;
    height: 24px;
    flex-direction: row;
  }
}