body #root {
  overflow-x: unset;
}

#account-main-wrapper {
  .account-header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 450px;
    left: clamp(0px, 50%, calc(50% - 225px));
    z-index: 10;
  }

  // OVERRIDES STICKY TABS
  .list-sticky-tabs-my-home-wrapper {
    position: sticky;
    top: 60px;
    z-index: 1;
    background-color: #fff;
    width: 100%;
    max-width: 450px;

    .tabsContainerStyle {
      padding: 0 16px;
      background-color: #fff;
      z-index: 1;
    }

    .tabTextStyle,
    .tabTextActiveStyle {
      padding: 6px 18px 6px 0;
    }
  }

  .empty_data_wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    .empty_text {
      position: absolute;
      top: 120px;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: center;
      color: #666;
    }
  }
  .empty_cor {
    .empty_text {
      position: absolute;
      height: 100%;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.04em;
      text-align: center;
      color: #666;
    }
  }
}
