#post_write_tag {
  .bar {
    background-color: rgb(245, 245, 245);
    height: 6px;
  }
  .tag_wrap {
    padding: 40px 16px;
    .tag_input_wrap {
      padding: 20px;
      border-radius: 6px;
      background-color: rgb(250, 250, 250);
      margin-bottom: 6px;
      width: 100%;
      .tag_input_inner {
        text-align: left;
        display: flex;
        align-items: center;
        .tag_title_text_2 {
          color: #222;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.04em;
          white-space: nowrap;

        }
        .tag_input {
          margin-left: 10px;
          background-color: rgb(250, 250, 250);
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.04em;
          color: #222;
          width: calc(100% - 30px);
          &::placeholder {
            color: #999;
          }
        }
      }
    }
    .auto_complete_list {
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 4px;
      padding: 8px 14px;
      box-shadow: 4px 4px 8px 0px #00000026;
      height: 100%;
      max-height: 210px;
      overflow-y: scroll;
      .post-tag-list {
        &:last-child {
          .auto_complete_item {
            border-bottom: none;
          }
        }
        .auto_complete_item {
          padding: 8px 0;
          justify-content: center;
          border-bottom: 1px solid #eee;
          .keyword-text-wrapper {
            display: flex;
            font-size: 13px;
            letter-spacing: -0.52px;
            color: #222;
          }
        } 
      }
    }
    .tag_list_wrap {
      margin-top: 14px;
      margin-bottom: 14px;
      padding-right: 4px;
      padding-left: 4px;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      .tag_item {
        border: 1px solid #BE69C3;
        border-radius: 300px;
        height: 34px;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        .tag_item_text {
          margin-right: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.03em;
          color: #BE69C3;
        }
      }
    }
    .recommend_tag_wrap {
      .recommend_tag_list_wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        .recommend_tag_item {
          background-color: rgb(247, 244, 250);
          border-radius: 300px;
          padding: 10px 20px;
          .recommend_tag_item_text {
            font-size: 14px;
            color: #BE69C3;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.03em;
          }
        }
      }
    }
  }
}