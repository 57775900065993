.ab_horizontal_item {
  justify-content: space-between;
  margin-left: 16px !important;
  margin-bottom: 10px;
  border-radius: 6px;
  width: 80vw;
  background-color: #ffffff;
  box-shadow: 5px 0px 8px 0px rgba(0, 0, 0, 0.08);
  width: 320px;
  & > button {
    width: 100%;
  }
  .ab_horizontal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0;

    .ab_horizontal_left {
      display: flex;
      flex-direction: row;

      .profile_image {
        width: 24px;
        height: 24px;
        border-radius: 32px;
        margin-right: 6px;
      }

      .ab_horizontal_info {
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          .ab_horizontal_header_text {
            color: #666;
          }
        }

        .ab_horizontal_header_text {
          font-size: 12px;
          letter-spacing: -0.48;
          font-weight: 500;
          color: #999;
        }

        .text_bar {
          width: 1px;
          height: 12px;
          margin: 0 6px;
          background-color: #999;
        }
      }
    }
  }

  .ab_horizontal_contents {
    margin: 12px 0 10px;
    padding: 0 20px;
    text-align: left;

    .ab_horizontal_contents_text {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      letter-spacing: -0.64px;
      font-weight: 500;
      color: #222;
      margin: 0;
    }
  }

  .ab_horizontal_select {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    width: 320px;
    height: 159px;
    display: flex;

    .ab_horizontal_image {
      width: 159px;
      height: 159px;
      position: absolute;
      top: 0;
      width: 159px;
      z-index: 1;
      height: 160px;

      .container_ab_wrap {
        position: absolute;
        top: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .ab_wrap {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          padding: 4px;

          p {
            color: #fff;
            font-size: 30px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: -1.2px;
            margin: 0;
          }
        }

        .select_text_wrap {
          margin-top: 10px;

          p {
            margin: 0;
            color: #be69c2;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.56px;
          }
        }
      }
    }

    .select_A,
    .select_B {
      height: 159px;
      position: relative;
      overflow: hidden;
      width: 159px;
      height: 159px;

      &.has_thumbnail {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 2;
          width: 159px;
          height: 159px;
        }
      }

      &.selected {
        &:after {
          background-color: #be69c3;
          opacity: 0.5;
        }
      }

      &.result_opened {
        position: relative;
        background-color: #999999;
        &.selected {
          background-color: #be69c3;
        }
        &.has_no_thumbnail {
          .ab_horizontal_image {
            display: none;
          }
        }
      }
      &.result_not_opened {
        &.has_no_thumbnail {
          .left_inner,
          .right_inner {
            width: 100%;
            display: none;
          }
        }
      }
    }

    .select_B {
      margin-left: 1px;
      border-bottom-right-radius: 6px;
      &:after {
        border-bottom-right-radius: 6px;
      }
    }

    .select_A {
      margin-right: 1px;
      border-bottom-left-radius: 6px;
      &:after {
        border-bottom-left-radius: 6px;
      }
    }

    .left_horizontal_image,
    .right_horizontal_image {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(50, 50);
      width: 100%;
      height: 100%;
      justify-content: center;
      object-fit: cover;
    }

    .left_horizontal_image {
      border-bottom-left-radius: 6px;
    }

    .right_horizontal_image {
      border-bottom-right-radius: 6px;
    }

    .left_inner,
    .right_inner {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    .left_inner {
      border-bottom-left-radius: 6px;
    }

    .right_inner {
      border-bottom-right-radius: 6px;
    }

    .check_icon {
      height: 40px;
    }

    .ab_horizontal_select_text1 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -1.28px;
      font-weight: bold;
      color: #fff;
      margin: 0;
      padding: 0 12px;
    }

    .ab_horizontal_select_text2 {
      margin-top: 7px;
      font-size: 14px;
      padding: 0 12px;
      letter-spacing: -0.56px;
      font-weight: 500;
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .ab_horizontal_select_text4 {
      margin-top: 16px;
      font-size: 22px;
      letter-spacing: -0.88px;
      font-weight: 700;
      color: #fff;
    }
  }
}
