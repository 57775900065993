#empty-feed-account-screen {
  position: relative;

  svg {
    width: 100% !important;
    height: auto;
  }

  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .my_empty_text_wrap {
    text-align: center;
    white-space: nowrap;
  }

  .empty_text_wrap {
    text-align: center;
    white-space: nowrap;
  }

  .my_empty_text {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: #666;
    display: inline-block;

    &_bold {
      font-weight: bold;
      display: inline-block;
    }
  }

  .empty_btn {
    padding: 12px 20px;
    border-radius: 300px;
    border: 1px solid #be69c3;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    &_text {
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.64px;
      color: #be69c3;
    }
  }
}
