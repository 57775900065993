.comment_footer_wrap {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: 450px;
  .comment_search_button {
    padding-right: 10px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .comment_input_wrap {
    width: 100%;
    height: 40px;
    margin-right: 10px;
    .comment_input {
      height: 40px;
      width: 100%;
      padding: 0 12px;
    }
  }
  .comment_enter_wrap {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .comment_enter_wrap {
    height: 40px;
    width: 72px;
  }
}
.bottom_sheet_view {
  padding: 0;
  margin: 8px 16px;
  display: flex;
  justify-content: end;
  .bottom_sheet_close {
    height: 32px;
  }
}
.comment_user_list {
  margin-top: 8px;
  padding: 0 20px;
  .container_user_item {
    margin-bottom: 24px;
    .comment_user_item {
      display: flex;
      align-items: center;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
