#picture_mission {
  .desc_wrap {
    .image_container {
      .image {
        
        width: 100%;
      }
    }
  }
  .mission_desc_html {
    img {
      width: 100%;
      height: auto !important;
    }
  }
  .contents_wrap {
    padding-bottom: 32px;
    .contents_item {
      margin-top: 24px;
      .label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #222;
        margin: 0 0 6px;
      }
      .contents {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.04em;
      }
      .list_contents {
        margin-bottom: 4px;
        .contents-txt-wrap {
          display: flex;
          align-items: start;
          p.contents {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.04em;
          }
        }
      }
      .modify_btn {
        margin-top: 12px;
        padding: 8px;
        border: 1px solid #ccc;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.27px;
        letter-spacing: -0.02em;
        border-radius: 6px;
        width: fit-content;
      }
    }
  }
  .picture_mission_footer {
    .challenger_list {
      .challenger_title_text1 {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 26.06px;
        letter-spacing: -0.04em;
        .challenger_title_text2 {
          margin-left: 4px;
          color: #BE69C3;
        }
      }
    }
  }
  .list_box_wrap {
    margin-top: 6px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list_box {
      padding: 0 4px;
      width: 50%;
      .list_img_wrap {
        .list_img {
          width: 100%;
          height: 201px;
          object-fit: cover;
        }
      }
      .des_txt {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.27px;
        letter-spacing: -0.04em;
        color: #222;
      }
      .des_id {
        font-size: 12px;
        font-weight: 400;
        line-height: 17.38px;
        letter-spacing: -0.04em;
        color: #999;
      }
    }
  }
  .footer_btn {
    margin-top: 32px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26.06px;
    letter-spacing: -0.04em;
    background-color: #222;
    color: #fff;
    width: 100%;
    height: 56px;
  }
}