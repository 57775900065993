#contents-notification-modal {
  $--border-radius: 12px;
  .modal-header {
    width: 100%;
    background-color: #fff;
    display: flex;
    border-top-left-radius: $--border-radius;
    border-top-right-radius: $--border-radius;
    position: relative;
    padding: 16px 0 16px;

    .header-text {
      flex: 1;
      margin: 0;
      text-align: center;
      padding: 0 10px;
    }

    .icons {
      position: absolute;
      right: 8px;
      top: 20px;
      transform: translateY(-50%);
      display: flex;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
  }

  .modal-footer-two {
    display: flex;
    flex-direction: row;
    height: 48px;
  }

  .button-left {
    border-bottom-left-radius: 12px;
    border-color: #666666;
    border-right-width: 0.5px;
  }

  .button-right {
    border-bottom-right-radius: 12px;
  }

  .button-two {
    background-color: #6c2773;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
  }

  .modal-footer {
    background-color: #222;
    border-bottom-left-radius: $--border-radius;
    border-bottom-right-radius: $--border-radius;
    height: 56px;

    .button {
      width: 100%;
      height: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .black {
    background-color: #222;
  }

  .gray {
    background-color: #eeeeee;
  }

  .text-0 {
    font-size: 16px;
    letter-spacing: -0.65px;
    margin-bottom: 24px;
    color: #222;
    text-align: center;
  }

  .text-1 {
    width: 170px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;
    text-align: center;
    letter-spacing: -0.56px;
    color: #222
  }
  .footer-button-text {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: bold;
    color: #fff
  }
  .footer-button-gray-text {
    color: #666666,
  }
}