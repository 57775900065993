$--size-image: 78px;
$--margin: 20px;
.profile {
  padding: 4px 16px 24px 16px;

  p {
    margin: 0;
  }

  button {
    font-family: inherit;
  }
}

.profile_info_wrap {
  display: flex;
  flex-direction: row;
}

.profile_image_container {
  display: flex;
  position: relative;
  height: fit-content;
}

.corp_badge {
  display: flex;
  position: relative;

  &_img {
    width: $--size-image;
    height: $--size-image;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
}

.edit_icon_wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_image {
  width: $--size-image;
  height: $--size-image;
  border-radius: 39px;
  object-fit: cover;
}

.badge-item {
  display: flex;
  justify-content: center;
  align-items: center;

  &-inner {
    display: flex;
  }
}

.has_introduce_or_sns {
  margin-top: 20px;
}

.profile_info {
  flex: 1;
  margin-left: $--margin;
  width: calc(100% - #{$--size-image} - #{$--margin});

  .profile_info_top {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    white-space: nowrap;
    overflow-x: hidden;

    .profile_btn {
      margin-left: 3px;
      border-radius: 100px;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      align-self: center;
    }
  }

  .profile_info_bottom {
    display: flex;
    flex-direction: row;
    column-gap: 28px;

    .info_bottom_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 2px;
    }
  }
}

.profile_text_1_wrapper {
  white-space:nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_text_1 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.64px;
  text-align: left;
  color: #fff;

  white-space:nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_text_2 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: #fff;
}

.profile_text_3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.04em;
  color: #fff;
}

.profile_text_4 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.64px;
  text-align: left;
  white-space: pre-line;
  color: #fff;
}

.profile_text_5 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.64px;
  text-align: left;
  text-decoration: underline;
  opacity: 0.6;
  color: #fff;
}

.tag-list-wrapper {
  margin-top: 20px;
  overflow-x: auto;
  white-space: nowrap;

  .tag_item_wrap {
    color: #fff;

    & + .tag_item_wrap {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }

  .tag_text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.64px;
    text-align: left;
  }
}

.bx_add_volt {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .txt_add_volt {
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
    color: #fff;
  }
}

.follow_btn1 {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.follow_btn_text1 {
  color: #682C78;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.64px;
}

.follow_btn2 {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.follow_btn_text2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #fff;
}

.bx_follow_list {
  display: flex;
  margin-top: 20px;
  
  .bx_profile_img_3_people {
    width: 56px;
  }
  .bx_profile_img_2_people {
    width: 40px;
  }
  .bx_profile_img_1_people {
    width: 24px;
  }
  .bx_profile_img {
    position: relative;
  }
  .img_profile {
    position: absolute;
    left: 0;
    z-index: 3;
  }
  .img_profile.img_profile_1 {
    left: 16px;
    z-index: 2;
  }
  .img_profile.img_profile_2 {
    left: 32px;
    z-index: 1;
  }
  // .img_profile {
  //   display: inline-block;
  //   & + .img_profile {
  //     transform: translateX(-10px);
  //   }
  // }

  .in_img_profile {
    width: 24px;
    height: 24px;border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
  }
  .txt_follow_info {
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.64px;
    text-align: left;
    flex: 1;

    &_bold {
      font-weight: bold;
      display: inline;
    }
  }
}