$color-222: #222;
$color-6c2773: #6c2773;
$color-999: #999;

.join_email {
  background-color: #fff;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: fixed;
  top: 0;
  max-width: 450px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 54px;
}

.contents_box {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.step_list {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.space_bottom {
  height: 56px;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  max-width: 450px;
  width: 100%;
}

.btn_1 {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
}

.txt_active {
  color: #fff;
}

.txt_in_active {
  color: #999;
}

.active {
  background-color: #222;
}

.in_active {
  background-color: #eeeeee;
  border-color: #d9d9d9;
}

.step_item {
  margin-bottom: 16;
}

.step_header {
  margin-top: 20px;
  padding: 0 20px;
}

.term_agree_header {
  margin-top: 48px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
}

.step_header_btn {
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step_header_active_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.64;
}

.step_header_in_active_text {
  font-size: 14;
  color: #999;
  letter-spacing: -0.56;
}

.step_body {
  margin-top: 16px;
  padding-bottom: 36px;
}

.step_body_term {
  padding: 0 20px 36px 20px;
}

.email_wrap {
  flex-direction: row;
  padding-bottom: 7;
  margin-right: 20;
  margin-left: 20;
  align-items: center;
  border-bottom-width: 3;
}

.email_wrap_active {
  border-color: #6c2773;
}

.email_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22;
  letter-spacing: -0.88;
}

.auth_btn {
  border-radius: 10px;
  border-color: #999;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  padding: 0px 4px;
}

.auth_btn_active {
  background-color: $color-6c2773;
  border-color: $color-6c2773;
}

.auth_btn_text {
  font-size: 14px;
  letter-spacing: -0.56;
  color: $color-999;
}

.auth_btn_text_active {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #fff;
}

.cancel_btn {
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.auto_complete_item {
  justify-content: center;
  border-color: #eee;
  height: 34;
  border-bottom-width: 1;
}

.auto_complete_last_item {
  border-bottom-width: 0;
}

.auto_complete_text {
  font-size: 13;
  letter-spacing: -0.52;
  color: #222;
}

.alert_wrap {
  margin-top: 6px;
  padding-right: 20px;
  padding-left: 20px;
}

.alert {
  display: flex;
  flex-direction: row;
}


.toast {
  border-radius: 10;
  padding-right: 20;
  padding-left: 20;
  padding-top: 10;
  padding-bottom: 10;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: black;
}

.toast_text {
  font-size: 16;
  color: #fff;
  letter-spacing: -0.64;
}

.pw_input_wrap {
  flex-direction: row;
  align-items: center;
  margin-right: 20;
  margin-left: 20;
  padding-bottom: 7;
  border-bottom-width: 3;
}

.pw2_input_wrap {
  margin-top: 18;
}

.pw_input_wrap_focus {
  border-color: #6c2773;
}

.pw_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22;
  letter-spacing: -0.88;
  margin-right: 10;
}

.toggle_btn {
  height: 6%;
  width: 8%;
  align-items: center;
  justify-content: center;
}

.pw_not_valid {
  font-size: 12;
  color: #c00055;
  letter-spacing: -0.48;
  font-weight: 500;
  margin-left: 3;
}

.pw_secure_header_text {
  flex: 1;
  min-width: 0;
  min-height: 0;
  height: 40;
}

.phone_input_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 7px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.phone_input_wrap_focus {
  border-color: $color-6c2773;
}

.phone_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 22px;
  letter-spacing: -0.88px;
  margin-right: 10px;
}

.phone_alert_text {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.bold_text {
  font-weight: bold;
}

.phone_not_valid {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.phone_success_valid {
  font-size: 12px;
  color: #66aa00;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}
