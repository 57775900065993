.basic_info {
  flex: 1;
  min-width: 0;
  min-height: 0;
  background-color: #fff;
  overflow-y: auto;
}

.select_items_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  row-gap: 24px;
}

.item_wrap {
  width: calc(50% - 15px);
}

.select_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  position: relative;
  background-color: #f6f4fa;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  & > img {
    width: 72px;
    height: 72px;
    object-fit: contain;
  }
}

.select_item_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
  color: #000;
  margin-top: 12px;

}

.select_item_text_2 {
  margin-top: 8px;
}

.desc_text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.48px;
  color: #666666;
  margin-top: 4px;
}

.selected_inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #BE69C372;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.margin_bottom_40 {
  margin-bottom: 40px;
}
