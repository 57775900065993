.tag-empty-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .tag-empty-inner {
    position: relative;
    width: 100%;
    .empty-image {
      width: 100%;
      height: auto;
    }

    .empty-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 120px;

      .empty-text {
        font-size: 16px;
        color: #666666;
        letter-spacing: -0.64px;
        font-weight: 500;
        white-space: pre-line;
      }
    }

    .empty-btn {
      margin-top: 14px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #be69c3;
      height: 44px;
      width: 157px;
    }
  }
}