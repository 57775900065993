#SearchResult {
  .slick-track {
    margin-left: unset;
  }
  .scroll_wrap {
    padding-bottom: 140px;
  }

  .post_list_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;

    .data_item_6 {
      grid-column: 2/4;
      grid-row: 2/4;
    }

    .data_item_5.group_full {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    .data_item_wrap {
      position: relative;

      .image_wrap_inner {
        z-index: 0;
        position: relative;
        width: 100%;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image_icon {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }

  .add_detail_btn_wrap {
    padding: 0 16px;
    margin-top: 16px;

    .add_detail_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      border-radius: 4px;
      padding: 15px 0;
      border: 1px solid #eee;

      .add_detail_btn_text {
        font-size: 14px;
        letter-spacing: -0.56px;
        color: #222;
        font-weight: 500;
      }
    }
  }

  .product_list_wrap {
    .relation_product_item {
      display: flex;
      flex-direction: column;
      padding: 0 4px;
    }

    .relation_product_first_item {
      padding-left: 16px;
    }

    .relation_product_image_inner {
      position: relative;
      width: 100%;
      padding-top: 100%;

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #0000001a;
        border-radius: 6px;
      }
    }

    .relation_product_item_text1 {
      margin-top: 8px;
      padding: 0 4px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.64px;
      text-align: left;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .relation_product_info {
      display: flex;
      align-items: center;
      margin-top: 4px;
      padding: 0 4px;

      .relation_product_item_text2 {
        font-size: 12px;
        letter-spacing: -0.48px;
        font-weight: 500;
        color: #999;

        white-space: nowrap;
        max-width: calc(50% - 10px);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .bar {
        margin: unset;
        display: flex;
        align-items: center;
        height: 100%;
        background-color: unset;

        & > div {
          background-color: #d9d9d9;
          width: 1px;
          height: 10px;
          margin: 0 10px;
        }
      }
    }
  }

  .qna_list_wrap {
    padding: 0 20px;

    .qna_item_wrap {
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: unset;
      }
      .qna_item {
        display: flex;
        width: 100%;

        & > svg {
          flex-basis: 24px;
        }
      }

      .qna_txt_wrap {
        padding-left: 6px;
        padding-right: 14px;
        flex: 1;
        text-align: left;
        width: calc(100% - 72px - 24px);
      }

      .qna_text1 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font-size: 16px;
        letter-spacing: -0.64px;
        color: #222;
        margin-bottom: 10px;
        line-height: 23px;
        font-weight: 500;
      }

      .qna_text2 {
        font-size: 14px;
        letter-spacing: -0.56px;
        color: #666666;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100%);
      }

      .qna_img {
        width: 72px;
        height: 72px;
        border-radius: 4px;
        object-fit: cover;
      }

      .qna_info {
        display: flex;
        padding-left: 30px;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
      }

      .qna_info_first {
        display: flex;
      }

      .qna_info_second {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .poster_list_wrap {
    .recommend_user_item {
      padding: 0 8px;
      width: calc(64px + 16px);
    }

    .first_recommend_user_item {
      padding-left: 20px;
      width: calc(64px + 20px + 8px);
    }

    .recommend_user_image {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      object-fit: cover;
    }

    .recommend_user_text2 {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: #222;
      letter-spacing: -0.48px;
      font-weight: 500;
      text-align: center;
      margin-top: 4px;
    }
  }
}
