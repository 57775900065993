#alarm-setting-screen {
  .react-switch-handle {
    width: 20px !important;
    height: 20px !important;
    top: 2px !important;
    transform: translateX(18px) !important;

    &:has(+ input[aria-checked="false"]) {
      width: 20px !important;
      height: 20px !important;
      top: 2px !important;
      transform: translateX(3px) !important;
    }
  }
}
