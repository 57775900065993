#bookmark_ab_list {
  padding: 0 16px;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  .ab_item_wrap {
    width: 100%;
    .ab_item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      width: 100%;
      .ab_item_left {
        display: flex;
        align-items: center;
        text-align: left;
        margin-right: 10px;
        flex: 1;
        min-width: 0;
        .ab_check_btn {
          margin-right: 14px;
        }
        .ab_info_wrap {
          flex: 1;
          min-width: 0;
          .ab_expire, .ab_in_progress {
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.4px;
            width: fit-content;
            margin-bottom: 6px;
          }
          .ab_expire {
            background-color: #c4c4c4;
            color: #FFF;
          }
          .ab_in_progress {
            background-color: #F7E237;
            color: #222;
          }
          .ab_title_text {
            color: #222;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.64px;
            margin-bottom: 4px;
            white-space:nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ab_info {
            display: flex;
            align-items: center;
            color: #999;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.48px;
            .ab_info_bar {
              margin: 0 6px;
            }
          }
        }
      }
      .ab_item_right {
        width: 74px;
        min-width: 74px;
        height: 74px;
        position: relative;
        .ab_image_text {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.72px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .a_image_wrap, .a_image {
          width: 42px;
          height: 42px;
          position: absolute;
          top: 0;
          left: 0;
          .a_image_back {
            height: 100%;
            width: 100%;
            background-color: #000;
            display: flex;
            padding: 7px;
            text-align: center;
            border-radius: 6px;
          }
        }
        .b_image_wrap, .b_image {
          width: 42px;
          height: 42px;
          position: absolute;
          box-sizing: content-box;
          bottom: 0;
          right: 0;
          border: 2px solid #fff;
          border-radius: 8px;
          .b_image_back_purple, .b_image_back_light_purple {
            height: 100%;
            width: 100%;
            display: flex;
            padding: 5px;
            text-align: center;
            border-radius: 6px;
          }
          .b_image_back_purple {
            background-color: #672C78;
          }
          .b_image_back_light_purple {
            background-color: #BE69C3;
          }
        }
        .ab_image_wrap, .b_image_wrap_is_path {
          width: 42px;
          height: 42px;
          object-fit: cover;
          border-radius: 8px;
          filter: brightness(0.5);
          object-fit: cover;
        }
      }
    }
  }
}
