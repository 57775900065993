.item {
  margin-bottom: 12px;
}
.item_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contents_text {
  font-size: 14px;
  color: #222;
  letter-spacing: -0.56px;
  font-weight: bold;
}
