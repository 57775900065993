.auth_code_wrap {
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;

  :global {
    img {
      width: unset;
      height: unset;
    }
  }
}

.auth_code {
  background-color: #fff;
  border-radius: 10px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  margin-bottom: 6px;
  border: 1px solid #222;
}

.auth_code_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
}

.auth_code_input {
  flex: 1;
  min-width: 0;
  min-height: 0;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.72px;
  color: #222;
}

.auth_code_bottom {
  padding-bottom: 16px;
  text-align: left;
}

.auth_code_bottom_text {
  font-size: 12px;
  color: #c00055;
  letter-spacing: -0.48px;
  font-weight: 500;
}

.auth_code_alert {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth_code_alert_text {
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  margin-left: 3px;
}

.timer_text {
  font-size: 16px;
  color: #c00055;
  letter-spacing: -0.64px;
  font-weight: 500;
  margin-right: 12px;
}

.auth_code_btn {
  background-color: #222;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 36px;
}

.auth_code_btn_text {
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.64px;
}

.resend {
  margin-left: 3px;
  border-bottom-width: 1px;
}

.resend_text {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.48px;
  text-decoration: underline;
}
