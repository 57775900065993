#root {
  overflow-x: hidden;
}

.overlapped-join-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

#JoinComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 450px;

  .list_wrap {
    width: 100%;
    bottom: 0;
    justify-content: flex-end;
    // background-color: #fff;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0.7) 90%,
      rgba(255, 255, 255, 0)
    );
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding-top: 80px;
  }
  .warning_text_wrap {
    padding-top: 20px;
    margin-bottom: 6px;
  }
  .warning_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: 500;
  }
  .join_text_wrap {
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    // height:responsiveHeight(5);
  }
  .join_text {
    display: flex;
    font-size: 28px;
    letter-spacing: -2.24px;
    color: #222;
    font-weight: 500;
  }
  .kakao_btn_wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
  .kakao {
    display: flex;
    border-radius: 56px;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 16px;
    background-color: #fce74e;
    border-color: #fce74e;
    border: 1px solid;
    border-color: #ffc700;
  }
  .apple {
    display: flex;
    border-radius: 56px;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 16px;
    background-color: #222;
    border: 1px solid;
    border-color: #000;
  }
  .text_btn {
    display: flex;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: bold;
    color: #222;
  }
  .text_btn_white {
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: bold;
    color: #fff;
  }
  .middle_bar {
    display: flex;
    padding-right: 40px;
    padding-left: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 19px;
  }
  .middle_line {
    display: flex;
    flex: 1;
    background-color: #d9d9d9;
    height: 1px;
  }
  .middle_text {
    display: flex;
    width: 44px;
    background-color: #fff;
    justify-content: center;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }
  .sns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
  }
  .sns_img + .sns_img {
    margin-left: 10px;
  }

  .bottom_area {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    padding-top: 40px;
    justify-content: center;
  }
  .bottom_btn {
    display: flex;
    flex: 1;
    padding-left: 12px;
  }
  .bottom_btn.email_btn {
    padding-left: unset;
  }
  .email_btn {
    display: flex;
    justify-content: end;
    padding-right: 12px;
    border-right: 1px solid #c4c4c4;
  }
  .bottom_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }
  .bottom_area_line {
    width: 1px;
    background-color: #c4c4c4;
    margin-left: 13px;
    margin-right: 13px;
  }
  .join_popover {
    display: flex;
    right: 0px;
    left: 0px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .join_popover_inner {
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 12px;
    border: 1px solid;
    border-color: #be69c3;
    width: 136px;
    height: 30px;
    position: relative;
  }
  // .triangle {
  //   display: flex;
  //   position: absolute;
  //   background-color: #fff;
  //   transform: rotate(45deg);
  //   left: 58px;
  //   bottom: 25px;
  //   width: 20px;
  //   height: 20px;
  //   border-style: solid;
  //   border-left-width: 1px;
  //   border-top-width: 1px;
  //   border-color: #be69c3;
  // }

  .triangle-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(50px, 2px);
  }

  .triangle {
    background-color: #fff;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #be69c3;
    position: relative;
    transform: rotate(90deg);
  }

  .triangle:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #fff;
    position: absolute;
    top: -9px;
    left: 1px;
  }

  .join_popover_text1 {
    display: flex;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500;
    line-height: 16px;
  }
  .join_popover_text2 {
    display: flex;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.48px;
    font-weight: 500;
    font-weight: bold;
    line-height: 16px;
    margin-left: 2px;
    color: #d073d5;
  }
}
