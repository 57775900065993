#SearchPoster {
  .poster_list {
    padding: 0 16px 40px 16px;
    .poster_item {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      & > div {
        display: flex;
      }
    }
    .poster_profile_image {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      margin-right: 10px;
    }
    .poster_profile_text {
      font-size: 16px;
      letter-spacing: -0.64px;
      color: #222;
      font-weight: bold;
      font-weight: 500;
    }
  }
}