#Search {
  .title {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .title_recommend {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .ranking_keyword_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
  }
  .ranking {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #222;
    font-weight: bold;
  }
  .ranking_date {
    font-size: 14px;
    letter-spacing: -0.56px;

    color: #999;
    font-weight: 500;
  }
  .ranking_box {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
  }
  .first_ranking {
    flex: 1;
  }
  .second_ranking {
    flex: 1;
    margin-left: 20px;
  }
  .rank {
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;
    align-items: flex-start;
    gap: 10px;
    // gap responsiveWidth(2)
  }
  .rank_keyword_wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
  .rank_number {
    width: 10px;
    font-size: 14px;
    letter-spacing: -0.56px;
    font-weight: bold;
  }
  .rank_txt {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
    height: 20px;
    width: 70%;
    // width responsiveWidth(26.25)
  }
  .rank_code {
    display: flex;
    width: 20px;
    // width responsiveWidth(10)
    height: 20px;
    align-items: center;
    justify-content: center;
  }
  .rank_code_size {
    display: flex;
    width: 20px;
    // width responsiveWidth(5)
    align-items: center;
    justify-content: center;
  }
  .rank_code_up_down {
    display: flex;
    height: 8px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }
  .rank_code_stay {
    display: flex;
    width: 10px;
    // width responsiveWidth(2)
    height: 2px;
    flex-shrink: 0;
    border-radius: 1.4px;
    background-color: #999;
    align-items: center;
    justify-content: center;
  }
  .rank_code_new {
    display: flex;
    color: #be69c3;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
  }
  .latest_search {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 8px;
    margin-top: 32px;
  }
  .latest_search_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .delete_all_btn_text {
    font-size: 12px;
    letter-spacing: -0.28px;
    font-weight: 500;
    color: #666666;
  }
  .latest_search_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .latest_search_item {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 50%;
    padding-bottom: 10px;
    // width responsiveScreenWidth(44)
  }
  .delete_icon {
    padding-top: 6px;
  }
  .latest_search_text {
    font-size: 14px;
    color: #222;
    letter-spacing: -0.56px;
    // ...Platform.select({
    //   ios: { ...font_weight_500 },
    //   android: { ...font_weight_400 },
    // }),
    padding-right: 12px;
    padding-left: 5px;
  }
  .key_word_wrap {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 32px;
  }
  .recommend_nickname_wrap {
    font-size: 13px;
    color: #999;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .recommend_tag_wrap {
    margin-bottom: 40px;
  }
  .recommend_tag_list_wrap {
    .slick-slide {
      & > div {
        padding: 0 3px;
      }
    }
  }
  .recommend_tag_item {
    display: flex;
    background-color: #f7f4fa;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 300px;
    margin-bottom: 8px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .recommend_tag_item_text {
    font-size: 14px;
    letter-spacing: -0.42px;
    color: #a760b1;
  }
  .similar_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    padding-top: 30px;
  }
  .similar_tag_wrap {
    padding-bottom: 40px;
  }
  .similar_tag_list_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    padding: 0 16px;
    & + .similar_tag_list_wrap {
      .similar_border_item {
        margin-bottom: unset;
      }
    }
  }
  .similar_border_item {
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    width: calc(50% - 8px);
    height: 50px;
    border: 1px solid #d9d9d9;
  }
  .similar_border_inner_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
  }
  .similar_tag_item_text_wrap {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 20px;
  }
  .similar_tag_item_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    font-weight: 500;
  }
}
