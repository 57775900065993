#QnaListScreen {
  .header {
    padding: 0px 16px 0;
  }
}
.qna_home {
  background-color: white;
  flex: 1;
  flex-direction: column;

  .empty {
    position: relative;
    .no_data {
      svg {
        width: 100%;
        margin-top: 20px;
      }
    }
    .empty_wrap {
      text-align: center;
      position: absolute;
      margin-top: 118px;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .empty_text {
        color: #666;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.64px;
        margin: 0 0 14px;
      }
      .write_qna_btn {
        padding: 12px 20px;
        border: 1px solid #be69c3;
        border-radius: 50px;
        max-width: fit-content;
        .write_qna_btn_text {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.56px;
          color: #be69c3;
          margin: 0;
        }
      }
    }
  }
}
.go_question {
  height: 53px;
  border-radius: 6px;
  margin: 20px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;
  border-color: #be69c3;
  border-style: solid;
  padding: 10px 20px;
  width: calc(100% - 32px);

  .go_question_txt1 {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #be69c3;
    font-weight: 500;
  }
}

.go_question_txt_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .go_question_txt2 {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.48px;
    color: #be69c3;
    font-weight: 500;
  }
}
.sheet {
  padding-top: 32px;
  .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    height: 32px;
  }

  .sheet_title_wrap {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .sheet_title {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.72px;
      color: #222;
    }
  }

  .sort_list {
    align-items: center;
    justify-content: center;

    .sort_item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 11px 0;
      font-size: 16px;
      font-weight: 500;
      .sort_active_text {
        color: #be69c3;
      }
      p {
        margin: 0;
        font-weight: 700;
      }
    }
  }
}
