.lk_cate_wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid #d9d9d9;
}
.lk_cate_title {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: -1px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  cursor: pointer;
}
.txt_cate_title {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.56px;

  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
