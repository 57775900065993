.action_wrap {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 450px;
  background-color: #f5f5f5;
  padding: 20px;
  z-index: 99;
}
.action_wrap_padding {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.footer_title {
  background-color: #f5f5f5;
  color: #222;
}
.footer_text {
  background-color: #f5f5f5;
  white-space: pre-wrap;
}
.footer_title_bold {
  background-color: #f5f5f5;
  color: #222;
  font-weight: bold;
}
.action_footer {
  display: flex;
  justify-content: space-between;
}
.icon_wrap {
  display: flex;
}
