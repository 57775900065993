#MainSectionItem {
  .popular_post {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .popular_post_list {
    margin-top: 14px;
  }
  .popular_post_item {
    margin-right: 8px;
  }
  .popular_post_last_item {
    margin-right: 24px;
  }

  .badge_img {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .popular_tag {
    margin-top: 32px;
    margin-bottom: 12px;
  }
  .popular_tag_list {
    margin-top: 14px;
  }
  .popular_tag_first_item {
    margin-left: 16px;
  }
  .popular_tag_item {
    margin-right: 8px;
  }
  .popular_tag_last_item {
    margin-right: 24px;
  }
  .popular_tag_img {
    width: 160px;
    height: 240px;
    border-radius: 6%;
  }

  .post_count_text {
    margin-right: 10px;
  }
  .popular_qna {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .popular_qna_last_item {
    margin-right: 24px;
  }
}
