#qna_write_tag_section {
  .tag_input_wrap {
    padding: 0 16px;
  }

  .tag_input_inner {
    background-color: #fafafa;
    padding: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;

    .tag_title_text_2 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      color: #222;

    }

    .tag_input {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
      flex: 1;
    }
  }

  .auto_complete_list {
    overflow-y: auto ;
    margin: 10px auto 0;
    height: 300px;
    background-color: #fff;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 14px;
    padding-left: 14px;
    width: calc(100% - 32px);
    z-index: 1000;
    border: 1px solid #eee;
    box-shadow: 4px 4px 8px 0px #00000026;
    .auto_complete_item {
      display: flex;
      padding: 8px 0;
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .tag_list_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 6px;
    padding: 16px 16px 0;
    .tag_item {
      display: flex;
      align-items: center;
      column-gap: 6px;
      padding: 10px 20px;
      border-radius: 30px;
      font-size: 14px;
      letter-spacing: -0.04em;
      border: 1px solid #BE69C3;
      color: #BE69C3;
      cursor: pointer;
    }
  }

  .recommend_tag_list_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 6px;
    padding: 16px 16px 0;
    .recommend_tag_item {
      background-color: #F7F4FA;
      padding: 10px 20px;
      border-radius: 30px;
      font-size: 14px;
      letter-spacing: -0.04em;
      color: #BE69C3;
      cursor: pointer;
    }
  }
}