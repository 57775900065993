#PopularPost {
  margin: 10px 0;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;

  .title_wrap {
    margin-left: 16px;
    flex-wrap: wrap;
  }

  .title_content {
    position: relative;
    width: fit-content;

    &::before {
      content: "";
      position: absolute;
      background-color: #f7e237;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8px;
    }
  }

  .popular_post_title_text {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: #222;
  }

  .popular_post_list {
    margin-top: 14px;
  }
}
