#PopularQnaItem {
  .title_wrap {
    display: flex;
    margin-left: 16px;
    flex-wrap: wrap;
    position: relative;
    width: fit-content;

    &::before {
      content: "";
      background-color: #f7e237;
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .popular_qna_title_text {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: 500;
    position: relative;
  }

  .popular_qna_list {
    margin-top: 16px;
    margin-bottom: 12px;
    padding-left: 16px;
  }
  .popular_qna_first_item {
    margin-left: 20px;
    margin-right: 8px;
  }

  .popular_qna_item {
    margin-right: 8px;
  }

  .popular_qna_image {
    width: 220px;
    height: 220px;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .popular_qna_inner {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .qna_card {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .popular_qna_text1 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #fff;
  }

  .popular_qna_info_wrap {
    flex-direction: row;
    justify-content: space-between;
  }

  .qna_user {
    flex-direction: row;
    align-items: center;
  }

  .qna_user_img {
    border-radius: 100px;
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }

  .popular_qna_text2 {
    font-size: 12px;
    letter-spacing: -0.8px;
    color: #222;
  }

  .comment {
    flex-direction: row;
    align-items: center;
  }

  .comment_text1 {
    font-size: 12px;
    letter-spacing: 0.48px;
    font-weight: 500;
    color: #fff;
    margin-right: 2px;
  }

  .comment_text2 {
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.56px;
    font-weight: 500;
  }

  .qna_purple_wrap1 {
    background-color: #854788;
  }

  .qna_purple_wrap2 {
    background-color: #ab5eaf;
  }

  .qna_purple_wrap3 {
    background-color: #be69c3;
  }
  .popular_qna_contents_wrap {
    padding: 16px 16px;
    p {
      margin: 0;
    }
    .id_text {
      font-weight: normal !important;
    }
  }
}
