.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 100000;
}

.modal-content {
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 14px;
}

.dropdown-item {
  width: 100%;
  height: 42px;
  cursor: pointer;
  white-space: nowrap; /* Prevent text from wrapping */
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
