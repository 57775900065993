$space-height: 30px;

.space {
  height: $space-height;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contents_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: 56px;
}

.title_text {
  font-size: 28px;
  letter-spacing: -2.24px;
  color: #222;
  font-weight: 500;
  letter-spacing: -0.08em;
}

.intro {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
}

.profile_image {
  width: 180px;
  height: 180px;
  border-radius: 100px;
  margin-top: 30px;
}

.profile_select_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 10px;
  background-color: #be69c3;
  padding-left: 38px;
  padding-right: 38px;
  height: 40px;
}

.profile_select_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #fff;
  margin-left: 4px;
}

.skip_btn {
  margin-top: 18px;
  margin-bottom: 20px;
}

.skip_txt {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  font-weight: 400;
  text-decoration-line: underline;
  letter-spacing: -0.04em;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 450px;
}

.btn_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  background-color: #222;
}

.btn_1_txt {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.72px;
  color: #fff;
}
