.mall-popular-product-wrapper {
  .bx_title {
    margin-top: 24px;
    padding: 0 16px;
    position: relative;
    width: fit-content;
  }

  .bg_title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7e237;
    height: 8px;
    z-index: -1;
  }

  .txt_title {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #222;
  }

  .bx_item_wrap {
    position: relative;
    display: flex !important;
    margin-right: 16px;
    &:nth-last-child() {
      margin-right: 0;
    }
  }

  .btn_bookmark {
    position: absolute;
    right: 16px;
    z-index: 99;
  }

  .slider-wrapper {
    margin-top: 16px;

    .slick-list {
      padding-left: 16px;
    }
  }

  .bx_review_photo {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .photo_num {
    background-color: #f5f5f5;
    flex: 1;
    padding-left: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    white-space: pre-line;
    column-gap: 2px;

    .txt_photo_num {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;
      color: #666;
    }
    .icon_photo_num {
      margin-bottom: -2px;
    }
  }

  .bx_info {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    color: #222;
    text-align: left;

    .info_pd_manufacturer {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: -0.04em;
    }

    .info_pd_name {
      display: inline-block;
      margin-top: 2px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.04em;

      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .bx_info_price {
      display: flex;
      column-gap: 4px;
      align-items: baseline;
      margin-top: 6px;
      .txt_info_percent {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.04em;
        color: #ea174a;
      }
      .txt_info_price {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.04em;
        color: #222;
      }
      .txt_info_before_price {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.04em;
        color: #999;
        text-decoration: line-through;
      }
    }
  }

  .bx_review {
    padding-left: 8px;
  }
}

//     txt_title: {
//       ...Texts.font_size_18,
//       ...Texts.font_weight_500,
//       ...Colors.font_222,
//     },
//     bg_title: {
//       position: "absolute",
//       left: -2,
//       right: -2,
//       bottom: 0,
//       backgroundColor: "#F7E237",
//       height: 8,
//     },
//     flat_box: {
//       marginTop: 16,
//       marginBottom: 20,
//     },
//     bx_item_wrap: {
//       position: "relative",
//       flexDirection: "row",
//       marginRight: 16,
//     },
//     btn_bookmark: {
//       position: "absolute",
//       top: 260,
//       right: 0,
//       zIndex: 99,
//     },
//     bx_img: {
//       width: 256,
//       height: 256,
//       backgroundColor: "#f1f1f1",
//       borderWidth: 1,
//       borderColor: "rgba(34,34,34,0.1)",
//       borderRadius: 4,
//       overflow: "hidden",
//     },
//     bx_info: {
//       width: 260,
//       paddingVertical: 8,
//     },
//     info_pd_manufacturer: {
//       fontSize: 11,
//       color: "#222",
//       fontWeight: "700",
//       marginBottom: 2,
//     },
//     info_pd_name: {
//       fontSize: 14,
//       color: "#424242",
//       fontWeight: "400",
//     },
//     bx_info_price: {
//       flexDirection: "row",
//       alignItems: "flex-end",
//       flexWrap: "wrap",
//       marginTop: 4,
//     },
//     txt_info_percent: {
//       fontSize: 20,
//       color: "#EA174A",
//       fontWeight: "600",
//       marginRight: 4,
//       letterSpacing: -0.4,
//     },
//     txt_info_price: {
//       fontSize: 20,
//       color: "#222",
//       fontWeight: "700",
//       letterSpacing: -0.4,
//     },
//     txt_price_unit: {
//       fontSize: 16,
//       fontWeight: "500",
//     },
//     txt_info_before_price: {
//       fontSize: 14,
//       fontWeight: "400",
//       color: "#999",
//       marginLeft: 4,
//       letterSpacing: -0.4,
//       textDecorationLine: "line-through",
//     },
//     bx_review: {
//       paddingLeft: 8,
//     },
//     bx_review_photo: {
//       flexWrap: "wrap",
//     },
//     photo_wrap: {
//       width: 58,
//       height: 58,
//       borderWidth: 1,
//       borderColor: "rgba(34,34,34,0.1)",
//       marginBottom: 8,
//       borderRadius: 4,
//       backgroundColor: "#f1f1f1",
//       overflow: "hidden",
//     },
//     photo_num: {
//       flexDirection: "row",
//       alignItems: "flex-end",
//       justifyContent: "flex-start",
//       backgroundColor: "#f5f5f5",
//       paddingLeft: 8,
//       paddingBottom: 8,
//       borderWidth: 0,
//     },
//     txt_photo_num: {
//       color: "#666",
//       fontSize: 12,
//       lineHeight: 17.38,
//       letterSpacing: -0.4,
//     },
//     icon_photo_num: {
//       marginBottom: -2,
//     },
//   });