::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
  font-family: "Noto Sans KR";
}

body {
  height: 100%;
}

.common-header-system,
div:has(> .common-header-system) {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}

#root {
  height: 100%;
  background-size: cover;
  background: white;
}

#root:has(#JoinModal) {
  overflow: auto;
}

.App {
  text-align: center;
  padding-top: env(safe-area-inset-top);
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.is-dragging {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bottomSheetAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes bottomSheetAnimation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@-webkit-keyframes bottomSheetAnimationClose {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes bottomSheetAnimationClose {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@-webkit-keyframes overlayAnimationClose {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes overlayAnimationClose {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes right-nav-open-animation {
  from {
    transform: translate3D(100%, 0, 0);
  }
  to {
    transform: translate3D(0, 0, 0);
  }
}

@keyframes right-nav-open-animation {
  from {
    transform: translate3D(100%, 0, 0);
  }
  to {
    transform: translate3D(0, 0, 0);
  }
}
@-webkit-keyframes right-nav-close-animation {
  from {
    transform: translate3D(0, 0, 0);
  }
  to {
    transform: translate3D(100%, 0, 0);
  }
}
@keyframes right-nav-close-animation {
  from {
    transform: translate3D(0, 0, 0);
  }
  to {
    transform: translate3D(100%, 0, 0);
  }
}

.modal-open {
  overflow: hidden;
}
.join-modal-open {
  overflow: hidden;
}
.bottom-modal-open {
  overflow: hidden;
}

.hide {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}
