#MissionMain {
  .mission {
    padding: 0 20px;
    .mission_header {
      margin: 20px 0 24px;
      height: 60px; /* 원하는 높이 설정 */
      .mission_header_text1 {
        margin: 0 0 4px;
        font-size: 32px;
        font-weight: 700;
        line-height: 46.34px;
        letter-spacing: -0.04em;
        color: #222;
      }
      .mission_header_text2 {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.27px;
        letter-spacing: -0.04em;
        color: #666;
      }
    }
  }
  .tab-container {
    position: sticky;
    top: 60px;
    padding: 8px 20px;
    width: 100%;
    max-width: 450px;
    z-index: 10;
    .tabsContainerStyle {
      padding: 0;
      .tabWrapperStyle {
        padding: 0;
        .tabTextActiveStyle {
          color: #222;
        }
        .tabTextStyle {
          color: #aaa;
        }
        .tabTextActiveStyle, .tabTextStyle {
          padding: 0;
          margin-right: 18px;
          font-size: 20px;
          font-weight: 700;
          line-height: 28.96px;
          letter-spacing: -0.04em;
        }
      }
    }
  }
  .mission-list {
    margin: 8px 20px 16px;
  }
  .empty-icon {
    padding: 150px 0;
    text-align: center;
    .empty_text_wrap {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.17px;
      letter-spacing: -0.04em;
      color: #666;
      p {
        margin: 0;
      }
    }
    .event_view_btn {
      margin-top: 14px;
      border: 1px solid #BE69C3;
      border-radius: 50px;
      padding: 12px 20px;
      color: #BE69C3;
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.27px;
        letter-spacing: -0.04em;
      }
    }
  }
}