#VoltModal {
  .centered_view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .modal_view {
    display: flex;
    flex-direction: column;
  }

  .btn_close {
    margin-left: auto;
    display: flex;
  }
}