.qa_list {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
}
.header_wrap {
  background-color: #fff;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
.tab_box {
  height: 56px;
  border-bottom: 1px solid #eee;
  display: flex;
  background-color: #fff;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: calc(48px + 64px);
}
.tab {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.tab_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #999;
}
.active_tab {
  border-bottom: 1px solid #222;
  flex: 1;
}
.active_tab_txt {
  color: #222;
  font-weight: bold;
}
.list_wrap {
  // padding: 0px 16px;
  margin: 0 16px;
}
.list {
  padding: 24px 0px;
}
.list_border {
  border-bottom: 1px solid #eee;
}
.qna_item_wrap {
  width: 100%;
}
.qna_type {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.48px;
  padding: 6px 10px;
  border: 1px solid rgba(34, 34, 34, 0.1);
  border-radius: 4px;
  font-weight: 500;
}
.extra {
  display: flex;
  flex: 1;
}
.qna_type_wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.qna_contents {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 12px;
  text-align: left;
  white-space: pre-line;

  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.qna_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.qna_info_date {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
}
.qna_info_status {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #999;
  font-weight: bold;
}
.qna_info_status_finish {
  color: #be69c3;
}
.more {
  position: absolute;
  right: 5px;
  top: 17px;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: bold;
}
.more_text {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: bold;
}
.nothing_search_img {
  position: relative;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty_wrap {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.empty_text {
  font-size: 16px;
  color: #666;
  letter-spacing: -0.64px;
  font-weight: 500;
}

// 1:1
.bx_mall_link {
  padding: 0px 16px;
  background-color: #fff;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
}
.bx_in_mall {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fafafa;
  padding: 16px;
  width: 100%;
}
.bx_link_text {
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-shrink: 1;
  flex-grow: 1;
}
.bx_label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.txt_label {
  font-size: 16px;
  color: #222;
  font-weight: 500;
  letter-spacing: -0.4px;
}
.txt_label_sm {
  font-size: 12px;
  color: #be69c2;
  font-weight: 500;
  margin-right: 4px;
  letter-spacing: -0.4px;
}
