.mission-list {
  &.end {
    .mission-image-container {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 6px;
      }
    }
  }
  .mission-item {
    // margin-bottom: 35px;

    .mission-title {
      margin: 0 0 9.5px;
      font-size: 18px;
      font-weight: 500;
      line-height: 26.06px;
      letter-spacing: -0.04em;
      color: #222;
    }

    .mission-image-container {
      height: 220px;
      margin-bottom: 12px;
      .mission-image {
        border-radius: 6px;
        height: 220px;
        width: 100%;
        object-fit: cover;
      }
    }

    .mission-volt-info {
      margin-bottom: 4px;

      .mission-volt-text {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        line-height: 17.38px;
        letter-spacing: -0.04em;

        p {
          margin: 0;
        }
      }
    }

    .mission-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date-wrap {
        display: flex;
        align-items: center;
        color: #222;

        .date-label {
          font-size: 12px;
          font-weight: 700;
          line-height: 17.38px;
          letter-spacing: -0.04em;
        }

        .date {
          margin-left: 4px;
          font-size: 12px;
          font-weight: 400;
          line-height: 17.38px;
          letter-spacing: -0.04em;
        }

        .d-day {
          font-weight: 700;
          font-size: 12px;
          line-height: 17.38px;
          letter-spacing: -0.04em;
          margin-left: 6px;
          color: #BE69C3;
        }
      }

      .mission-type {
        display: flex;
        align-items: center;

        .mission-type-text {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          line-height: 17.38px;
          letter-spacing: -0.04em;
          color: #BE69C3;
          margin-left: 3px;
        }
      }
    }

    .winner_btn {
      width: 100%;
      padding: 15px;
      border: 1px solid #222;
      border-radius: 6px;
      margin-top: 15px;
      text-align: center;

      .winner_btn_text {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.17px;
        letter-spacing: -0.04em;
        color: #222;
        margin: 0;
      }
    }
  }
}