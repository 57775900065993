.tab {
  position: sticky;
  position: -webkit-sticky;
  top: 54px;
  z-index: 1;
}

.tab_list {
  white-space: nowrap;
  overflow-x: auto;
  height: 56px;
  width: 100%;
  background-color: #fff;
}

.tab_item {
  padding: 0 30px;
  display: inline-block;
  align-items: center;
  height: 100%;
}

.tab_active {
  border-bottom: 1px solid #222;
}

.tab_inactive {
  border-bottom: 1px solid #eee;
}

.tab_text {
  font-size: 16px;
  letter-spacing: -0.64px;
}

.tab_active_text {
  font-weight: 500;
  color: #222;
}

.tab_inactive_text {
  color: #999;
  font-weight: 400;
}

.accordion_header {
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  border-bottom: 1px solid #eee;
}

.accordion_header_left {
  display: flex;
  column-gap: 6px;
  flex: 1;
}

.accordion_header_text {
  font-size: 16px;
  letter-spacing: -0.04em;
  display: inline-block;
  text-align: left;
  line-height: 24px;
}

.accordion_header_inactive_text {
  font-weight: 500;
  color: #222;
}

.accordion_header_active_text {
  font-weight: bold;
  color: #be69c3;
}

.accordion_contents {
  background-color: #fcfafc;
  display: flex;
  column-gap: 6px;

  max-height: 0;
  padding: 0 16px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &_active {
    padding: 24px 16px;
    max-height: 100dvh;
    overflow: auto;
  }
}

.text_icon {
  flex-shrink: 0;
}

.accordion_contents_wrap {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.04em;
  p {
      line-height: 26px;
  
      &:first-child {
        margin: 0;
      }
    }
}
