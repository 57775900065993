.post-tag-wrapper {
  padding: 0 16px 140px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .tag_item {
    padding: 8px 12px;
    border-radius: 32px;
    background-color: #f7f4fa;
  }

  .tag_text {
    font-size: 12px;
    letter-spacing: -0.36px;
    font-weight: 400;
    color: #BE69C3;
  }
}
