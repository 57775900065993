.noting_comments_wrapper {
  position: relative;
}

.noting_comments {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.noting_comments_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noting_comments_txt {
  font-size: 16px;
  color: #666666;
  letter-spacing: -0.64px;
  font-weight: 500;
}
