.nickname-search-wrapper {
  margin-top: 20px;
  // padding: 0 16px;
  margin-bottom: 20px;
  position: relative;

  .nickname-search-input {
    height: 64px;
    border-radius: 6px;
    padding: 0 46px 0 20px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.64px;
    text-align: left;
    background-color: #fafafa;
    width: 100%;
    &::placeholder {
      color: #999;
    }
  }
  .search-icon {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
}
