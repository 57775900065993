.modal_wrap {
  width: 260px;
  display: flex;
  flex-direction: column;
}

.modal_header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 7px;
}

.modal_content {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  padding: 18px;
  padding-top: 46px;
  background-color: white;
  align-items: center;
}

.button {
  display: flex;
  width: 100%;
  height: 42px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  background-color: #be69ce;
  border-radius: 865px;
}
