.ranking_poster_feed_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  border-radius: 6px;
}

.click_ranking_poster_feed_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(190, 105, 195, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
