.account-post-list-wrapper {
}

.data_list_row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.data_item_right {
  // width: calc(100vw / 3 - 2px);
  // height: calc(100vw / 3 - 2px);
  margin-bottom: 3px;
  margin-right: 3px;
}

.data_item {
  // width: calc(100vw / 3 - 2px);
  // height: calc(100vw / 3 - 2px);
  margin-bottom: 3px;
}

.data_reverse_wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.data_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.data_margin_right {
  margin-right: 3px;
}

// .data_big_item {
// width: calc(100vw / 3 * 2 - 1px);
// height: calc(100vw / 3 * 2 - 1px);
// }

.data_big_item_right {
  // width: calc(100vw / 3 * 2 - 1px);
  // height: calc(100vw / 3 * 2 - 1px);
  margin-right: 3px;
}

.image_wrapper {
  position: relative;

  > div {
    display: flex;
  }

  img {
    object-fit: cover;
  }
}

.image_icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
