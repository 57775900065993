.post-content-wrapper {
  .slick-slide {
    & > div {
      display: flex;
    }
  }

  .media-carousel {
    position: relative;
  }

  .media-dots-pagination {
    display: flex !important;
    position: absolute;
    bottom: 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    & > li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0;
      padding: 0;
    }

    .carousel_pagination_active {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.92);
    }

    .carousel_pagination_inactive {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.92);
      opacity: 0.4;
    }
  }

  .home_app_list {
    margin-top: 14px;

    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;

    .slick-track {
      .slick-slide {
        padding: unset !important;
      }
    }
  }

  .home_app_item_wrapper {
    margin-right: 4px;
    width: 80%;
  }

  .home_app_item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .home_app_img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.1);
  }

  .home_app_rep_img {
    border-color: #be69c3;
  }

  .home_app_text_wrap {
    width: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .home_app_text1 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #222;
    margin-bottom: 3px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .home_app_text2 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.48px;
    color: #999;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .on_sale_tag_wrap {
    display: inline-block;
    background-color: #f0dff2;
    border-radius: 4px;
    width: fit-content;
    height: 17px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mall_tag {
    padding-left: 6px;
    padding-bottom: 3px;
  }

  .on_sale_tag_text {
    color: #be69c3;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 14px;
    padding: 1px 6px;
    text-align: right;
  }

  .pros_and_cons_wrap {
    margin: 14px 20px 0 20px;
  }

  .pros_and_cons_inner_wrap {
    background-color: #f8f8f8;
    border-radius: 6px;
    padding: 18px;
  }

  .rating_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .pros_and_cons_title {
    background-color: #fff;
    border-radius: 70px;
    padding: 0 12px;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    height: 25px;
    display: flex;
  }

  .pros_and_cons_title_text {
    font-size: 12px;
    color: #a760b1;
    letter-spacing: -0.48px;
    font-weight: 500;
  }

  .rating_text {
    font-size: 18px;
    color: #a760b1;
    letter-spacing: -0.36px;
    font-weight: 500;
  }

  .pros {
    display: flex;
    margin-bottom: 11px;
  }

  .pros_and_cons {
    display: flex;
  }

  .pros_and_cons_title {
    background-color: #fff;
    border-radius: 70px;
    padding: 0 12px;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    height: 25px;
    display: flex;
  }

  .pros_and_cons_text {
    flex: 1;
    min-width: 0;
    min-height: 0;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #222;
  }

  .rating-item {
    > div {
      display: flex;
    }
  }

  .contents_wrap {
    margin: 20px 20px 20px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.04em;
    text-align: left;
  }

  // RATIO IMAGE
  .video-player {
    width: 100%;
    position: relative;
    display: flex !important;
    height: unset !important;
    video {
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .image-wrapper {
    .image-item {
      width: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
