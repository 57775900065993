#SearchPost {
  .product_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-top: 16px;
    padding: 0 16px;
    padding-bottom: 10px;

    .row {
      width: calc(50% - 4px);
      margin-bottom: 30px;

      .relation_product_image_wrapper {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .relation_product_image {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
          border: 1px solid #0000001a;
          border-radius: 6px;
        }
      }

      .relation_product_item_text1 {
        margin-top: 8px;
        padding: 0 4px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.64px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      .relation_product_info {
        display: flex;
        align-items: center;
        margin-top: 4px;
        padding: 0 4px;

        .relation_product_item_text2 {
          font-size: 12px;
          letter-spacing: -0.48px;
          font-weight: 500;
          color: #999;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
