$primary-color: #be69c3;

body #root {
  overflow-x: unset;
}

.post-main-container {
  padding: 0 16px;
}

.post-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 100;
  background-color: #fff;
  padding: 12px 0 12px;
  width: 100%;
  max-width: 450px;
}

.tab-list {
  display: flex;
  -moz-column-gap: 18px;
  column-gap: 18px;
  padding: 0 16px;

  .tab-text {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #aaa;

    &-active {
      color: #222;
    }
  }
}

.category-group-scroll-wrapper {
  display: none;
}

.category-group-scroll-wrapper-with-group {
  display: flex;
  margin-top: 12px;

  .category-group-wrap {
    width: 100%;
  }

  .category-list-wrap {
    display: flex;
    -moz-column-gap: 6px;
    column-gap: 6px;
    align-items: center;
    overflow-x: auto;
    padding-right: 6px;
  }

  .category-group-items-icon {
    display: flex;
    padding-left: 16px;
  }

  .category-group-items {
    display: flex;
    align-items: center;
    padding: 0px 6px 0 12px;
    height: 32px;
    background-color: #f4e9f6;
    border-radius: 300px;
  }

  .category-group-items-on {
    background-color: $primary-color;
    .category-item-text {
      color: #fff;
    }
  }

  .category-item-text {
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
    white-space: nowrap;
  }

  .category-item-text-off {
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
    white-space: nowrap;
  }
}

.category-upper-wrapper-active,
.category-upper-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  .category-upper-wrapper-container {
    overflow-x: auto;
    flex-grow: 1;
  }
}

.category-filter-wrapper {
  margin-top: 12px;
}

.category-item-list-wrap {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
  align-items: center;
}

.category-items {
  display: flex;
  border: 1px solid #aaaaaa80;
  padding-left: 8px;
  border-radius: 4px;
  &:first-child {
    margin-left: 16px;
  }
}

.category-item-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;
  color: $primary-color;
  margin-right: 2px;
  white-space: nowrap;
}

.category-reset {
  display: flex;
  align-items: center;
}

.category-reset-text {
  padding-left: 7px;
  font-size: 14px;
  letter-spacing: -0.42px;
  color: #999999;
  font-weight: 500;
  white-space: nowrap;
  border-left: 1px solid #999999;
}

.category-notice-popover {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 90px;
  right: 35px;
  z-index: 10;
}

.category-notice-popover-inner {
  display: flex;
  flex-direction: row;
  background-color: #222222;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 12;
  width: 140px;
  height: 56px;
  padding: 0 24px;
}

.category-notice-popover-text1 {
  text-align: center;
  font-size: 12px;
  color: #222;
  letter-spacing: -0.48px;
  font-weight: 500;
  color: #fff;
  line-height: 16px;
}

.triangle {
  top: -14px;
  right: 55px;
  position: absolute;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  border-radius: 3px;
  background-color: #222222;
}

.category-close-btn {
  position: absolute;
  top: 0px;
  right: 4px;
}
