#CommentScreen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  bottom: 0px;
  z-index: 10000;
  overflow-y: scroll;
  max-width: 450px;

  .comment_list {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    // background-color: rgba(0, 0, 0, 0.3);
  }
}
