body.app-install-side-banner {
  #CommonTopButton {
    right: clamp(12px, calc(50% - 225px - 185px + 12px), 100%);
  }
}

#CommonTopButton {
  position: fixed;
  right: clamp(12px, calc(50% - 225px + 12px), 100%);
  display: flex;
  z-index: 10;
}
