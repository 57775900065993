.info_item {
  margin-bottom: 24px;
}
.alert_wrap {
  margin-top: 6px;
  padding-left: 4px;
}
.alert_text {
  font-size: 12px;
  letter-spacing: -0.24px;
  font-weight: 500;
  color: #ea174a;
}
.alert_text1 {
  color: #be69c3;
}
.recommender_label {
  height: 23px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
  text-align: left;
  color: #222;
}
.recommender_input_wrap {
  height: 60px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 14px;
  padding-left: 20px;
  border-radius: 6px;
  background-color: #fafafa;
  margin-top: 16px;
  // margin-bottom: 50px;
}
.recommender_input {
  height: 44px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.56px;
  text-align: left;
  color: #222;
}
.cancel_btn {
  width: 30px;
  align-self: stretch;
  flex-grow: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 10px;
  padding-left: 2px;
}
.auth_btn {
  width: 44px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #fff;
}
.auth_btn_text {
  color: #be69c3;
  font-size: 14px;
  letter-spacing: -0.56px;
}
.auth_btn_active {
  width: 42px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #eee;
  background-color: #a760b1;
}
.auth_btn_text_active {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #fff;
}
