.account_magazine_wrapper {
  // container
  .magazine_list_container {
    padding: 0 16px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // justify-content: space-between;
  }

  // item box
  .account_magazine_item_wrapper {
    position: relative;
    width: calc(50% - 4px);
    height: calc(50% - 4px);
    margin-bottom: 4px;
    &:nth-child(odd) {
      margin-right: 8px;
    }
  }

  // item image
  .account_magazine_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .mission_list {
    display: flex;
  }

  .magazine_item {
    position: relative;
    width: calc(50% - 4px);
    margin-bottom: 4px;
  }

  .content_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4px;
    padding: 24px 16px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 6px;
  }
  .content_title_wrapper {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
  }
}
