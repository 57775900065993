.qna_detail {
  background-color: white;
}
.detail_box {
  padding-top: 20px;
}
.detail_contents_box {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 40px;
}
.detail_type {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  margin-bottom: 6px;
  font-weight: bold;
}
.detail_date {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
  margin-bottom: 20px;
}
.detail_type_wrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 6px;
}
.detail_contents {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  margin-bottom: 32px;
  line-height: 26px;
  white-space: pre-line;
}
.img_wrap {
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img_box {
  display: flex;
  flex-direction: row;
}
.extra {
  display: flex;
  flex: 1;
}
.img_txt {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #666;
  padding-left: 7px;
}
.detail_response_box {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  position: relative;
}
.response_title {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: bold;
  color: #999;
  padding-left: 20px;
  margin-top: 32px;
  margin-bottom: 26px;
}
.response_des {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
}
.des_txt {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #666;
  font-weight: 500;
}
.response_title_finish {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #b269c3;
  font-weight: bold;
  padding-left: 20px;
  margin-top: 32px;
  margin-bottom: 26px;
}
.response_finish {
  font-size: 16px;
  letter-spacing: -0.64px;
  color: #222;
  background-color: #fafafa;
  line-height: 23px;
  margin: 0px 20px 24px;
  display: flex;
  flex: 1;
}
.response_date {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
  margin-bottom: 70px;
  padding-left: 20px;
}

.modal {
  margin: 0px;
}
.alert_modal {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #222;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_header {
  z-index: 10;
}
.modal_body {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.header {
  text-align: center;
  padding-top: 16px;
}
.modal_body {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 26px;
}
.confirm_modal_header {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.confirm_modal_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 80%;
}
.qna_image {
  width: 100%;
  height: auto;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.qna_image_small {
  width: 24px;
  height: 24px;
}
.modal_icons {
  width: 32px;
  height: 32px;
}
.report_modal {
  // width: responsiveWidth(82);
  overflow: hidden;
  width: 82%;
  max-width: 260px;
  position: relative;
  background-color: white;
  border-radius: 12px;
  border-color: transparent;
  margin: auto;
  ::shadow {
    color: black;
    offset {
      width: 0px;
      height: 2px;
    }
    opacity: 0.25;
    // radius: 4px; // ?
    // elevation 5px; // ?
  }
}
.header_wrap {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 10;
}
.header_text {
  font-size: 18px;
  color: #222;
  font-weight: bold;
  letter-spacing: -0.72px;
}
.icons {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 6px;
  right: 10px;
}
.contents_text {
  font-size: 14px;
  letter-spacing: -0.56px;
  color: #222;
  line-height: 24px;
}
.modal_footer {
  display: flex;
  flex-direction: row;
  height: 48px;
}
.button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.footer_button_text {
  font-size: 16px;
  letter-spacing: -0.64px;
  font-weight: 500;
  color: white;
}
.left_footer_txt {
  color: #666;
}
.left_btn {
  background-color: #eee;
  border-bottom-left-radius: 12px;
}
.right_btn {
  background-color: #222;
  border-bottom-right-radius: 12px;
}
.separate {
  border: 0.5px solid #666;
  width: 1px;
}
