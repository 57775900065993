#qna_request_user {
  .reply_input_wrap {
    padding: 0 16px;
  }

  .reply_input_inner {
    background-color: #fafafa;
    padding: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;

    .reply_title_text_2 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.04em;
      color: #222;

    }

    .reply_input {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.04em;
      text-align: left;
      color: #222;
      flex: 1;
    }
  }

  .recommended_user_item {
    width: fit-content;
  }

  .recommended_user,
  .selected_user {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    column-gap: 12px;
    padding: 0 16px;
    margin-top: 20px;
  }

  .image_wrap {
    position: relative;
    width: 68px;
  }

  .icon_img {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
  }

  .auto_complete_img {
    width: 68px;
    height: 68px;
    object-fit: cover;
    border-radius: 68px;
  }

  .nickname {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 12;
    letter-spacing: -0.64px;
    color: #222;
  }

  .request_bar {
    height: 1px;
    width: calc(100% - 32px);
    margin: auto;
    margin-top: 20px;
    background-color: #eee;
  }

  .auto_complete_list {
    overflow-y: auto ;
    margin: 10px auto 0;
    height: 300px;
    background-color: #fff;
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 14px;
    padding-left: 14px;
    width: calc(100% - 32px);
    z-index: 1000;
    border: 1px solid #eee;
    box-shadow: 4px 4px 8px 0px #00000026;
    .auto_complete_item {
      display: flex;
      margin-bottom: 16px;
    }
    .auto_complete_img {
      width: 32px;
      height: 32px;
    }
    .nickname-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 6px;
    }
  }
}