.line-drag-list-view {
  border-left: 2px dashed #BE69C3 !important;
}
.bar {
  background-color: rgb(245, 245, 245);
  height: 3px;
}
#post_write_content {
  &:not(:first-child)  {
    background: linear-gradient(180deg, rgba(248, 248, 248, 0.8) 0%, rgba(248, 248, 248, 0) 50%);
  }
  .content_wrap_image {
    .content_wrap {
      padding: 20px 16px 0;
      .delete_button_wrap {
        display: flex;
        justify-content: flex-end;
        .delete_button {
          border: 1px solid #eee;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20.27px;
          letter-spacing: -0.02em;
          color: #BE69C3;
          padding: 8px;
          display: flex;
          align-items: center;
          .delete_button_text {
            margin-left: 4px;
          }
        }
      }
    }
    .image_list_wrap {
      .draggable-container {
        margin: 20px 0;
        &.empty {
          & > div {
            display: flex;
            justify-content: center;
          }
        }
        & > div[role="presentation"] {
          padding: 0 8px 0 0;
          display: flex;
          overflow-x: auto;
          white-space: nowrap;
        }
        .draggable-item-image-wrap {
          margin: 0 8px;
          .drag-area {
            position: absolute;
            cursor: grab;
            right: 14px;
            bottom: 14px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            &-icon {
              width: 100%;
              height: 100%;
              pointer-events: none;
            }
          }
          .draggable-item-image {
            display: flex;
            position: relative;
            .draggable-image {
              pointer-events: none;
              width: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
            .image_info_wrap {
              position: absolute;
              top: 14px;
              left: 14px;
              .represent_image, .represent_image_off {
                font-size: 12px;
                font-weight: 500;
                line-height: 17.38px;
                padding: 6px 9px;
                border-radius: 4px;
                letter-spacing: -0.04em;
              }
              .represent_image {
                background-color: #F7E237;
                border: 1px solid #F7E237;
                color: #222;
              }
              .represent_image_off {
                border: 1px solid #BE69C3;
                background-color: #fff;
                color: #BE69C3
              }
            }
            .image_delete_wrap {
              position: absolute;
              top: 14px;
              right: 14px;
              background: #848a8f;
              border-radius: 50%;
              height: 32px;
            }
            .image_filter_wrap {
              position: absolute;
              bottom: 14px;
              left: 14px; 
              background-color: #fff;
              border-radius: 50%;
              height: 32px;
            }
          }
        }
        .draggable-slick {
          display: flex;
          justify-content: center;
          .draggable-item {
            margin: 0 8px;
            .image_add_wrap {
              border-radius: 6px;
              width: 100%;
              aspect-ratio: 1 / 1;
              padding: 0;
              background-color: #FAFAFA;
              border: 1px solid #EEEEEE;
              .photo_video_wrap {
                margin-top: 10px;
                margin-bottom: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  margin: 0 5px;
                }
              }
              .empty_text {
                font-size: 16px;
                font-weight: 500;
                line-height: 23.17px;
                letter-spacing: -0.04em;
                color: #C4C4C4;
              }
            }
          }
        }
      }
    }
    .contents {
      .contents_input_text {
        margin: 0 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.04em;
        width: calc(100% - 40px);
        color: #000;
        margin-bottom: 20px;
        resize: none;
        &::placeholder {
          color: #999;
        }
      }
    }
  }
}