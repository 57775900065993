#post_rating {
  .bar {
    background-color: rgb(245, 245, 245);
    height: 6px;
  }
  .satisfaction_wrap {
    padding: 40px 16px;
    .satisfaction_title {
      padding-right: 4px;
      padding-left: 4px;
      margin-bottom: 10px;
      .satisfaction_title_text_2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 23.17px;
        letter-spacing: -0.04em;
        color: #222;
      }
    }
    .star_count_wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
    .pros_and_cons_wrap {
      margin-top: 6px;
      .pros_and_cons_input_wrap {
        width: 100%;
        background-color: #FAFAFA;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        .pros_and_cons_input_inner {
          .pros_and_cons_text {
            white-space: nowrap;
            font-size: 16px;
            letter-spacing: -0.64px;
            color: rgb(34, 34, 34);
            font-weight: 500;
          }
        }
        .pros_and_cons_input {
          background-color: #FAFAFA;
          width: 100%;
          margin-left: 10px;
          resize: none;
          color: #222;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.04em;
            color: #999;
          }
        }
      }
    }
  }
}