.line_row {
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #6C2773;
}

.line_row_10 {
  height: 10px;
  background-color: #f5f5f5;
}

.mall_category_form_footer {
}

.bx_pd_none_wrap {
  margin-top: 100px;
  margin-bottom: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.txt_pd_none {
  text-align: center;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.64px;
  line-height: 22px;
  white-space: pre-line;
}

.bx_btn_more {
  padding: 12px 20px;
  border: 1px solid #be69c3;
  margin-top: 14px;
  border-radius: 300px;
}

.txt_btn_more {
  font-size: 14px;
  color: #BE69C3;
  font-weight: 500;
  letter-spacing: -0.56px;
}
