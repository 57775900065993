.wrapper {
  padding-bottom: 40px;
}

.bar {
  display: flex;
  flex: 1;
  background-color: #f5f5f5;
  margin: 12px 0px 8px 0px;
  height: 6px;
}

.benefit_alarm {
  margin-top: 24px;
  padding: 0 16px;
}
.action_alarm {
  margin-top: 24px;
  padding: 0 16px;
}
.alarm_setting_text1 {
  font-size: 18px;
  font-weight: 500;
  color: #222;
  letter-spacing: -0.72px;
}
.alarm_setting_text2 {
  font-size: 14px;
  color: #666666;
  letter-spacing: -0.56px;
  font-weight: 400;
}
.alarm_setting_text3 {
  font-size: 12px;
  letter-spacing: -0.48px;
  color: #999;
}
.alarm_setting_text4 {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.48px;
  text-decoration: underline;
  font-weight: 500;
}
.title {
  margin-bottom: 10px;
}
.info {
  margin-bottom: 6px;
}
.info_alert {
  margin-bottom: 12px;
}
