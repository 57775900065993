#MainLayout {
  .main {
    flex: 1;
    background-color: #fff;
  }

  .body {
    flex: 1;
  }
}
