.reply_container {
  width: 100%;
  padding: 24px 20px;
  .header {
    background-color: unset;
    .left_wrap {
      display: flex;
      .left {
        width: auto;
        .button_image {
          width: 34px;
          margin-left: 6px;
          height: 34px;
        }
      }
      .reply_icon {
        width: 9px;
        height: 9px;
      }
    }
  }
  .reply_comment_body_wrap {
    margin-left: 58px;
    .body {
      .comment_wrap {
        text-align: left;
      }
    }
  }
  .comment_footer {
    .inner_left {
      display: flex;
      align-items: center;
  
      .info_text {
        color: #666;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.48px;
      }
  
      .count_wrap {
        .reply_wrap {
          margin: 0 10px;
          color: #666;
          letter-spacing: -0.48px;
          font-size: 12px;
          display: flex;
          align-items: center;
  
          .reply_text {
            font-weight: 400;
            margin-right: 2px;
          }
  
          .reply_count {
            font-weight: 700;
          }
        }
  
        .count_text {
          color: #666;
          margin-left: 2px;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.48px;
        }
      }
    }
  }
  .reply_footer {
    display: flex;
    align-items: center;
  }
}