#MainMagazine {
  $padding: 16px;
  flex: 1;

  .magazine_header {
    margin-bottom: 12px;
    padding: 0 $padding;
  }
  .magazine_header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .user_img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .info_wrap {
    margin-left: 10px;
    padding-right: 10px;
  }
  .info_text1 {
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
    margin-bottom: 2px;
  }
  .info_text2 {
    font-size: 12px;
    color: #666666;
    letter-spacing: -0.64px;
    width: fit-content;
  }
  .image_wrap {
    padding: 0 $padding;
  }
  .image_wrap_inner {
    position: relative;
    padding-top: 100%;
  }
  .magazine_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    position: absolute;
    top: 0;
  }
  .magazine_btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .magazine_btn_text {
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #fff;
  }
  .action_wrap {
    flex-direction: row;
    justify-content: space-between;
    // margin-top: 24px;
    // padding-left: 20px;
    // padding-right: 20px;

    display: flex;
    align-items: center;
    margin-top: 24px;
    padding: 0 $padding;
  }
  .action_left {
    display: flex;
    flex-direction: row;
  }
  .action_right {
    display: flex;
    flex-direction: row;
  }
  .action_icon_btn {
    display: flex;
    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }
  .action_text {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.56px;
    font-weight: 500;
  }
  .contents_text {
    font-size: 14px;
    color: #222;
    line-height: 22px;
    letter-spacing: -0.64px;
  }
  .number_text {
    font-weight: 500;
    font-size: 14px;
    margin-left: 4px;
  }
  .like_text {
    margin-right: 10px;
  }
  .title_text_magazine {
    margin-top: 14px;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #222;
    font-weight: bold;
  }
  .contents_wrap_magazine {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .contents_wrap {
    margin-top: 7px;
    padding: 0 20px;
    position: relative;
  }
  .on_layout_width {
    padding-left: 16px;
    padding-right: 16px;
  }
  .magazine_detail_btn_wrap {
    padding: 0 $padding;
  }
  .magazine_detail_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #eee;
    height: 53px;
  }
  .magazine_detail_btn_text {
    font-size: 16px;
    letter-spacing: -0.64px;
    font-weight: 500;
    color: #222;
  }
  .more_text {
    display: flex;
    justify-content: end;
    font-size: 12px;
    color: #222;
    letter-spacing: -0.64px;
    font-weight: bold;
    text-decoration-line: underline;
    padding-left: 4px;
  }
}
