body.app-install-side-banner {
  display: flex;
  justify-content: center;
  #root {
    margin: unset;
  }
  .ReactModal__Overlay {
    inset: 0px clamp(0px, calc(50% - 225px - 185px), 50vw) 0px clamp(0px, calc(50% - 225px + 185px), 50vw) !important;
    .ReactModal__Content {
      position: absolute !important;
    }
  }
  .mobile-max-width {
    transform: translateX(185px);
  }
  #toast_alert_system {
    inset: auto clamp(0px, calc(50% - 225px - 185px), 50vw) 40px clamp(0px, calc(50% - 225px + 185px), 50vw) !important;
    max-width: 450px;
  }
}

#AppInstallSideBanner {
  order: 1;
  margin-right: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100dvh;

  .content {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 350px;
  }

  .image_wrap {
    display: flex;

    & + .image_wrap {
      margin-top: 20px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}