.post-detail-screen {
  .post-detail-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .title-text {
    padding: 0 20px;
    margin-bottom: 16px;
    color: #222;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: left;
  }

  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  min-width: 0px;
  background-color: rgb(255, 255, 255);

  .action_wrap {
    box-shadow: 0px -4px 30px 0px #0000000f;
    position: fixed;
    width: 100%;
    max-width: 450px;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    height: 64px;
    display: flex;
    align-items: center;
  }

  .action_wrap_padding {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .action_left {
    display: flex;

    .action_text,
    .like_text {
      display: flex;
      align-items: center;

      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.04em;
      color: #222;

    }

    .action_text {
      margin-left: 10px;
    }

    .number_text {
      margin-left: 2px;
      font-size: 14px;
    }
  }

  .action_right {
    display: flex;

    .action_icon_btn {
      margin-right: 10px;
      display: flex;
    }
  }

  .nothing_search_img {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: unset;
  }
  .empty_wrap {
    position: absolute;;
    top: 50%;
    transform: translateY(-50%);
  }
  .empty_txt {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.04em;
    color: #666;
    margin-top: 8px;
  }

  .footer_btn {
    background-color: #682c78;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0;
    height: 56px;
  }
  .footer_btn_text {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #fff;
  }
}